import React, { useState, useEffect } from "react";

const Countdown = ({ onComplete }) => {
  const [secondsLeft, setSecondsLeft] = useState(5 * 60); // 5 minutes in seconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsLeft((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (secondsLeft <= 0) {
      onComplete();
    }
  }, [secondsLeft, onComplete]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return <p className="timer">Time left: {formatTime(secondsLeft)}</p>;
};

export default Countdown;
