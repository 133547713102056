export const bucketA = [
  "Commercial Law",
  "Corporate Law",
  "Competition Law",
  "Startup Law",
  "Banking Law",
  "Investment Law",
  "Insolvency & Bankruptcy Law",
];

export const bucketB = ["Contract Law", "Legal Ethics", "Legal Managment"];

export const bucketC = [
  "International Law",
  "International Trade Law",
  "Maritime Law",
];

export const bucketD = ["Technology Law", "Intellectual Property Law"];

export const bucketE = [
  "Administrative/Service Law",
  "Environmental Law",
  "Constitutional Law",
  "Law & Public Policy",
  "Public Infrastructure Law",
  "Human Rights Law",
  "Civil Procedure Law",
  "Public Health Law",
];

export const bucketF = [
  "Arbitration",
  "Mediation",
  "Alternative Dispute Resolution",
];

export const bucketG = ["Securities Law", "Tax and Finance Law"];

export const bucketH = ["Criminal Law"];
