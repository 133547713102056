import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Box, Typography } from '@mui/material';
import Clogo from "../../../Assets/Images/vligtaApp.png";
import { downloadPDF } from "../../../components/PDFdownloader";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { CircularProgressbar } from "react-circular-progressbar";

import Footer from '../../../components/Footer';

export default function Tool4Certificate({ crtUserName }) {
  const navigate = useNavigate();

  const { User } = useSelector((state) => state.user);
  const Tool4Marks = User?.userData?.UserTestScore[0]?.Tool4[0]?.marks[0]


  useEffect(() => {
    if (!crtUserName) {
      navigate("/user/profile")
    }
  })
  return (
    <>
      <Box id="certificatePageT2" className="CrtContainer T3cMainContainer">
        <Box onClick={() => downloadPDF("certificatePageT2")} className="PrintBtn">
          <SimCardDownloadIcon sx={{ color: "#fff" }} />
          <Typography>as PDF</Typography>
        </Box>
        <Box className="t3cInnerBox">
          <img onClick={() => navigate("/user/profile")} className='Clogo' src={Clogo} />
          <Box className="t2CcontaintBox">
            <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
              <Box className="toolHeaderbox toolHeaderboxT2">
                <Typography className='toolHeaderText' variant='h5' mb={2}>
                  <span className='Vl'>VLiGTA </span>
                  <span className='Rl'>®</span>
                  <span className='R_l'>Test Results</span>
                  <span className='Rm_T'>Exercise</span>
                </Typography>
              </Box>
            </Box>
            <Box mb={5} sx={{ width: "100%" }}>
              <Typography className='crtUserName' sx={{ textAlign: "start" }}>Test completed by - {crtUserName}</Typography>
            </Box>
            <Box className="percentageBox">
              <Box className="qBDetailsTextBox">
                <Typography className='blueText'>Efficiency Score:
                  <span className='sLabelValue'>{(Tool4Marks?.TestScore / (Tool4Marks?.Tool4QuestionLength * 4) * 100 || 0).toFixed(0)}%</span>
                </Typography>
              </Box>
              <Box className="tool3PieBox PersentageCircul">
                <CircularProgressbar
                  value={(Tool4Marks?.TestScore / (Tool4Marks?.Tool4QuestionLength * 4) * 100 || 0).toFixed(0)}
                  text={`${(Tool4Marks?.TestScore / (Tool4Marks?.Tool4QuestionLength * 4) * 100 || 0).toFixed(0)}%`} />
              </Box>
            </Box>
            <Box className="efficiencyBox">
              <Typography className='Avenir_Black'> Efficiency Levels</Typography>

              <Box className="efficInnerBox t3cTabel">
                <Box className="efficInnerItemBox">
                  <Box>
                    <Typography className='efficScoreLabel'>Developing Efficiency approx. 25%</Typography>
                    <Typography className='efficScore'>
                      {(Tool4Marks?.questionIndexMarks?.marks1 / (Tool4Marks?.Tool4QuestionLength * 1) * 100).toFixed(0)}%
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className='efficScoreLabel'>Standard Efficiency approx. 50%</Typography>
                    <Typography className='efficScore'>
                      {(Tool4Marks?.questionIndexMarks?.marks2 / (Tool4Marks?.Tool4QuestionLength * 2) * 100).toFixed(0)}%
                    </Typography>
                  </Box>
                </Box>
                <Box className="efficInnerItemBox">
                  <Box>
                    <Typography className='efficScoreLabel'>Advanced Efficiency approx. 75%</Typography>
                    <Typography className='efficScore'>
                      {(Tool4Marks?.questionIndexMarks?.marks3 / (Tool4Marks?.Tool4QuestionLength * 3) * 100).toFixed(0)} %
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className='efficScoreLabel'>Peak Efficiency approx. 100%</Typography>
                    <Typography className='efficScore'>
                      {(Tool4Marks?.questionIndexMarks?.marks4 / (Tool4Marks?.Tool4QuestionLength * 4) * 100).toFixed(0)} %
                    </Typography>

                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  )
}
