import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ val, questionLength }) => {

  const newLabel = [];
  const newVal = [];

  val?.map(el => {
    newLabel?.push(el?.label)
  })

  val?.map(el => {
    newVal?.push((el?.value / questionLength) * 100)
  })

  const [chartState, setChartState] = useState({
    series: newVal,

    options: {
      chart: {
        height: 390,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          }
        }
      },
      colors: ['#1ab7ea', '#FF67AE', '#59F861', '#FFC75E', '#BF5FFF'],
      labels: newLabel,
      legend: {
        show: true,
        floating: true,
        fontSize: '20px',
        position: 'left',
        offsetX: 160,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 3
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }]
    },
  });

  return (
    <>
      <div id="chart">
        <ReactApexChart options={chartState.options} series={chartState.series} type="radialBar" height={390} />
      </div>
      <div id="html-dist"></div>
    </>
  );
};

export default ApexChart;
