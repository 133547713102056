import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { Box, Typography, Tab, Skeleton, Select, MenuItem } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CircularProgressbar } from "react-circular-progressbar";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser, postUser, fetchEventSummery, fetchCareerSummery, getUserId } from "../../store/UserSlice";
import { fetchTool1 } from "../../store/Tool1Slice";
import { fetchTool2 } from "../../store/Tool2Slice";
import { fetchTool3 } from "../../store/Tool3Slice";
import { fetchTool4 } from "../../store/Tool4Slice";
import Loader from "../../components/Loader"
import "./style.css"

//images
import Avatar from "../../Assets/Images/avatar.jpg";

//Component
import NavBar from "../../components/ClientNavBar";
import Footer from '../../components/Footer';
import { RedBtn, BlueBtn } from "../../components/Buttons"
import { Notification } from '../../App';
import ApexChart from "../../components/PieChart";
import Tool3Chart from "../../components/PieChart/Tool3Chart";

export let newLegalFieldArray = [];
export let newSkillArray = [];


export default function UserProfile({
  setLegalFieldPopUpVal,
  legalFieldPopUpVal,
  setCrtUserName,
  setCrtCorseName,
  setCrtCorseId,
  setCrtCorseMentor,
  selectedQuestionBankT3,
  currentTool3Marks,
  setSelectedQuestionBankT3,
  setCurrentTool3Marks,
  setKeyskills
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLog = localStorage.getItem("userString");

  if (!userLog) {
    navigate("/user/signUp");
  }


  const [value, setValue] = useState('3');
  const { EventSummery, CareerSummery, User, status, UserId } = useSelector((state) => state.user);
  const Tool1Marks = User?.userData?.UserTestScore[0]?.Tool1[0]
  const Tool3Marks = User?.userData?.UserTestScore[0]?.Tool3[0]
  const Tool4Marks = User?.userData?.UserTestScore[0]?.Tool4[0]?.marks[0]
  const T4 = true;
  const Tool1Data = useSelector((state) => state.tool1.data);
  const Tool1QuestionBank = Tool1Data?.data ? Tool1Data.data[0]?.QuestionsBanks : [];

  const Tool2Data = useSelector((state) => state.tool2.data);
  const questionBank = Tool2Data?.data ? Tool2Data.data[0]?.QuestionsBanks : [];
  let questions = questionBank.length > 0 ? questionBank.find((obj) => obj._id === "64e9c82afb567981c38374ad") : {};

  const Tool2LegalField = User?.userData?.UserTestScore[0]?.Tool2[0]?.legal_fields;
  const Tool2Skills = User?.userData?.UserTestScore[0]?.Tool2[0]?.skills
  const Tool3Data = useSelector((state) => state.tool3.data);
  const Tool3QuestionBank = Tool3Data?.data ? Tool3Data.data[0]?.QuestionsBanks : [];
  const Tool4Data = useSelector((state) => state.tool4.data);


  const [selectedQuestionBankT1, setSelectedQuestionBankT1] = useState()
  const [currentTool1Marks, setCurrentTool1Marks] = useState(1);


  const [legalFieldPopUp, setLegalFieldPopUp] = useState(false)
  const [instructionPop, setIntrcutionPop] = useState(false)



  if (Tool2LegalField || Tool2Skills) {
    const newLegalFieldData = Tool2LegalField.slice().sort((a, b) => b.count - a.count);
    newLegalFieldArray = newLegalFieldData.slice(0, 5).map((item, index) => ({
      id: index,
      value: item.count,
      label: item.name
    }));

    const newSkillData = Tool2Skills.slice().sort((a, b) => b.count - a.count);
    newSkillArray = newSkillData.slice(0, 5).map((item, index) => ({
      id: index,
      value: item.count,
      label: item.name
    }));
  }

  let currentSkillValue = "";
  const getEventSummary = () => {
    const label = newSkillArray[0]?.label;
    let content = null;

    switch (label) {
      case "Problem Solver":
        currentSkillValue = "ProblemSolver";
        content = EventSummery?.find((el) => el?.name === "ProblemSolver")?.values;
        break;
      case "Investigator":
        currentSkillValue = "Investigator";
        content = EventSummery?.find((el) => el?.name === "Investigator")?.values;
        break;
      case "Academic":
        currentSkillValue = "Academic";
        content = EventSummery?.find((el) => el?.name === "Academic")?.values;
        break;
      default:
        break;
    }
    return (
      <div>
        <Typography sx={{ color: "#FFFFFF" }} mb={2} variant='h4'>{currentSkillValue}</Typography>
        <Typography className='toolInfoLabelText'>{content}</Typography>
      </div>
    )
  };

  const getCareerRecommendations = (index) => {
    const label = newLegalFieldArray[index]?.label;
    let content = null;
    content = CareerSummery?.filter(el => el?.name === label)
    const FilterCareerSummeryVal = content[0]?.roles[newSkillArray[0]?.label]
    return (
      <Box mb={3} className='label'>
        <Typography className='toolInfoLabelHeader' sx={{ color: "#FFFFFF" }} mb={2}>{label}</Typography>
        <Typography className='toolInfoLabelText' mb={2} >{FilterCareerSummeryVal?.text1}</Typography>
        <Typography className='toolInfoLabelText' mb={2}>{FilterCareerSummeryVal?.text2}</Typography>
        <Typography className='toolInfoLabelText' mb={2}>{FilterCareerSummeryVal?.text3}</Typography>
      </Box>
    )
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handelTool4 = () => {
    setLegalFieldPopUp(true)
  }
  const handelPopSubmitT4 = () => {
    navigate(`/user/test/Tool-4`)
  }

  const instructionPopup = (url, summery, T4) => {
    return (
      <>
        <div style={{ display: instructionPop ? "block" : "none" }} className="overlay"></div>
        <Box sx={{ display: instructionPop ? "flex" : "none" }} className="instructionPopup">
          <Typography className='Avenir_Black instHeader' mb={2}>Instructions</Typography>
          <Typography>{summery?.data ? summery?.data[0]?.Instruction : []}</Typography>
          <Box className="InsPOpBtnBox">
            <RedBtn buttonText="Not Now" onClick={() => setIntrcutionPop(false)} />
            {T4 ?
              <BlueBtn buttonText="Next" onClick={() => {
                handelTool4()
                setIntrcutionPop(false)
              }} /> :
              <BlueBtn buttonText="Next" onClick={() => navigate(url)} />
            }
          </Box>
        </Box>
      </>
    )
  }
  const handelGoCertificatePage = (e) => {
    if (e.target.id === "Tool1Crt") {
      setKeyskills([])
      if (!selectedQuestionBankT1) {
        Notification("warning", "Please select a Question Bank!")
        return;
      } else {
        if (currentTool1Marks === 0) {
          Notification("warning", "You have not attempted this test yet!")
          return;
        } else {
          if (currentTool1Marks?.TestScore / selectedQuestionBankT1?.QuestionsList?.length * 100 >= selectedQuestionBankT1?.CutOffMark) {
            setCrtUserName(User?.userData?.name)
            setCrtCorseName(selectedQuestionBankT1?.CourseName)
            setCrtCorseId(selectedQuestionBankT1?.CourseId)
            setCrtCorseMentor(selectedQuestionBankT1?.CourseMentors)
            setKeyskills(selectedQuestionBankT1?.KeySkills)
            navigate("/user/test/Tool-1/certificate")
            Notification("success", "Your certificates are ready to print")
          } else {
            Notification("warning", "Your marks are not high enough to print the certificate for this course!")
          }
        }
      }
    }

    if (e.target.id === "Tool2Crt") {
      if (newLegalFieldArray.length === 0 || newSkillArray.length === 0) {
        Notification("warning", "You have not attempted this test yet!")
        return;
      } else {
        setCrtUserName(User?.userData?.name)
        navigate("/user/test/Tool-2/certificate")
        Notification("success", "Your certificates are ready to print")

      }
    }


    if (e.target.id === "Tool3Crt") {
      setKeyskills([])
      if (!selectedQuestionBankT3) {
        Notification("warning", "Please select a Question Bank!")
        return;
      } else {
        setCrtUserName(User?.userData?.name)
        setKeyskills(selectedQuestionBankT3?.KeySkills)
        navigate("/user/test/Tool-3/certificate")
        Notification("success", "Your certificates are ready to print")

      }
    }
    if (e.target.id === "Tool4Crt") {
      setCrtUserName(User?.userData?.name)
      navigate("/user/test/Tool-3/certificate")
      Notification("success", "Your certificates are ready to print")

    }
  }
  let ClculetExpireTime;

  const handelAccessCheck = (selectedId) => {
    if (User?.userData?.AccessTools.some(el => el.ToolId === selectedId?._id)) {
      if (ClculetExpireTime <= 0) {
        return Notification("warning", "Your course has been expired !")
      } else {
        setIntrcutionPop(true)
      }
    } else {
      Notification("warning", "You don't have access for this test !")
      return
    }
  }
  const calculetToolExpire = (selectedId) => {
    const AccessDate = User?.userData?.AccessTools?.filter(el => el.ToolId === selectedId?._id)[0];
    const TotalDays = (30 * AccessDate?.Months || 0) + (7 * AccessDate?.Weeks || 0) + (1 * AccessDate?.Days || 0);
    const currentDate = new Date().toLocaleDateString();
    const toolAccessDate = AccessDate?.DateOfAccess;
    const access_Date = new Date(toolAccessDate);
    const current_Date = new Date(currentDate);
    const timeDifference = current_Date - access_Date;
    const finalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    ClculetExpireTime = TotalDays - finalDays
    return (ClculetExpireTime || 0)
  }


  useEffect(() => {
    if (!EventSummery.length) {
      dispatch(fetchEventSummery())
    }
    if (!CareerSummery.length) {
      dispatch(fetchCareerSummery())
    }
    if (!Tool1Data?.data) {
      dispatch(fetchTool1())
    }
    if (!Tool3Data?.data) {
      dispatch(fetchTool3())
    }
    if (!Tool4Data?.data) {
      dispatch(fetchTool4())
    }
    if (!questionBank.length) {
      dispatch(fetchTool2())
    }
    if (!UserId) {
      dispatch(getUserId());
    }
    if (!User.length && UserId) {
      dispatch(fetchUser({ id: UserId }));
    }
  }, [dispatch, UserId, User.length]);

  if (status === "loading") {
    return (
      <>
        <NavBar />
        <Box className="UserProfileContainer">
          <Loader isLoading="true" />
        </Box>
      </>
    )
  }
  if (status === "error") {
    return (
      <>
        <NavBar />
        <Box className="UserProfileContainer ProfiledataPlaceHolder">
          <Typography variant='h6'>Something wrong happen! Please try later.</Typography>
        </Box>
      </>
    )
  }
  if (status === "idle") {
    return (
      <>
        <NavBar />
        <Box className="UserProfileContainer">
          <Box className="userAbatarBox">
            <Box className="avatarImage">
              {
                // User?.userData || avatar ? <img onLoad={() => setAvatar(true)} onError={() => setAvatar(false)} src={User?.userData?.picture} /> : <img src={Avatar} />
                <img src={User?.userData?.picture || Avatar} />
              }
            </Box>
            {
              User?.userData ? <Typography className='userText'>{User?.userData?.name}</Typography> :
                <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: "80%" }} />
            }
            {
              User?.userData ? <Typography className='userText'>{User?.userData?.email}</Typography> :
                <Skeleton variant="text" sx={{ fontSize: '2rem', width: "80%" }} />
            }
          </Box>
          <Box className="userProfileInnerBox">
            <TabContext value={value}>
              <Box className="userPNavBox" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab sx={{ color: "#fff" }} label="Qualifying Tests" value="1" />
                  <Tab sx={{ color: "#fff" }} label="VASE | Skill Evaluator" value="2" />
                  <Tab sx={{ color: "#fff" }} label="Exercises" value="3" />
                  <Tab sx={{ color: "#fff" }} label="Law Persona" value="4" />
                  <Tab sx={{ color: "#fff" }} label="VASE | Career Recommendations" value="5" />
                  <Tab sx={{ color: "#fff" }} label="VASE | Skill Archetypes" value="6" />
                </TabList>
              </Box>
              {/* Tool 1 */}

              <TabPanel value="1">
                <Box className="displayMarksInfoBox">
                  <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
                    <Box className="toolHeaderbox">
                      <Typography className='toolHeaderText' variant='h5' mb={2}>
                        <span className='Vl'>VLiGTA </span>
                        <span className='Rl'>®</span>
                        <span className='R_l'>Results</span>
                        <span className='Rm_T'>App Qualifying Tests</span>
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="QuestionBankSelectorBox">
                    <select
                      className='QuestionBankSelector'
                      onChange={(e) => {
                        setSelectedQuestionBankT1(Tool1QuestionBank?.find(el => el._id === e.target.value))
                        setCurrentTool1Marks(Tool1Marks?.marks.find((el) => el.questionBankId === e.target.value) || 0);
                      }}
                    >
                      <option value="" disabled selected>Select an QuestionBank</option>
                      {
                        Tool1QuestionBank &&
                        Tool1QuestionBank?.map((el) => (
                          <option key={el?._id} value={el?._id}>{el?.CourseName}</option>
                        ))
                      }
                    </select>
                  </Box>

                  <Box className="percentageBox">
                    <Box className="qBDetailsTextBox">
                      <Typography className='blueText'>Programme Name: <span className='sLabelValue'>{selectedQuestionBankT1?.CourseName}</span></Typography>
                      <Typography className='blueText'>Programme ID: <span className='sLabelValue'>{selectedQuestionBankT1?.CourseId}</span></Typography>
                      <Typography className='blueText'>Date of access: <span className='sLabelValue'>
                        {User?.userData?.AccessTools.filter(el => el.ToolId === selectedQuestionBankT1?._id)[0]?.DateOfAccess || ""}
                      </span></Typography>
                      <Typography className='blueText'>Access expires in: <span className='sLabelValue'>{calculetToolExpire(selectedQuestionBankT1)} days</span></Typography>
                      <Typography className='blueText'>Percentage obtained: <span className='sLabelValue'>{currentTool1Marks?.TestScore / selectedQuestionBankT1?.QuestionsList?.length * 100 || 0}%</span></Typography>
                      <Typography className='blueText'>Final Result: <span style={{ fontSize: "20px" }} className='sLabelValue'>{
                        currentTool1Marks?.TestScore / selectedQuestionBankT1?.QuestionsList?.length * 100 >= selectedQuestionBankT1?.CutOffMark ?
                          "PASSED" : "NOT PASSED. Please try again."
                      }</span></Typography>

                    </Box>
                    {
                      currentTool1Marks === 1 ?
                        <Typography className='persentagePlacHSelect Avenir_Black'>Please select and attempt a Test.</Typography> :
                        (currentTool1Marks === 0 ?
                          <Typography className='persentagePlacH'>You may attempt this test to get results.</Typography>
                          :
                          <Box className="PersentageCircul">
                            <CircularProgressbar
                              value={currentTool1Marks?.TestScore / selectedQuestionBankT1?.QuestionsList?.length * 100 || 0}
                              text={`${currentTool1Marks?.TestScore / selectedQuestionBankT1?.QuestionsList?.length * 100 || 0}%`} />
                          </Box>
                        )
                    }
                  </Box>
                  <Box className="resulBoxBottomTeb">
                    <Box className="PrintCertificateBtn">
                      <BlueBtn id="Tool1Crt" onClick={handelGoCertificatePage} buttonText="Print Certificate" />
                      <BlueBtn disabled={!selectedQuestionBankT1} onClick={() => window.open(selectedQuestionBankT1?.Notes)} buttonText="Notes" />
                      <BlueBtn disabled={!selectedQuestionBankT1} onClick={() => window.open(selectedQuestionBankT1?.AppointmentUrl)} buttonText="Schedule an Appointment" />

                    </Box>
                    <BlueBtn onClick={() => handelAccessCheck(selectedQuestionBankT1)} buttonText="Start" disabled={!selectedQuestionBankT1?._id} />
                  </Box>
                </Box>
                {instructionPopup(`/user/test/Tool-1?Qid=${selectedQuestionBankT1?._id}`, Tool1Data)}
              </TabPanel>


              {/* Tool 2 */}
              <TabPanel value="2">
                <Box className="displayMarksInfoBox ">
                  <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
                    <Box className="toolHeaderbox toolHeaderboxT2">
                      <Typography className='toolHeaderText' variant='h5' mb={2}>
                        <span className='Vl'>VLiGTA </span>
                        <span className='Rl'>®</span>
                        <span className='R_l'>Evaluation Results</span>
                        <span className='Rm_T'>App Skill Evaluator</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="Toll2ScoreBox">

                    <Box mr={2} className="Toll2ScoreSubBox">
                      <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h4' mb={2}>Legal Field Interests</Typography>
                      {
                        newLegalFieldArray?.map((el, index) => (
                          <Typography className='toolInfoLabelText' mb={1} key={index}>{el?.label} - {((el?.value / questions?.QuestionsList?.length) * 100).toFixed(0)}%</Typography>
                        ))
                      }
                    </Box>
                    <Box sx={{ borderLeft: "1px solid", paddingLeft: "10px" }} className="Toll2ScoreSubBox">
                      <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h4' mb={2}>Skill Archetypes</Typography>
                      {
                        newSkillArray?.map((el, index) => (
                          <Typography className='toolInfoLabelText' mb={1} key={index}>{el?.label} - {((el?.value / questions?.QuestionsList?.length) * 100).toFixed(0)}%</Typography>
                        ))
                      }
                    </Box>
                  </Box>
                  <Box className="chartBox">
                    <Box>
                      <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h6'>Legal Fields</Typography>
                      <ApexChart questionLength={questions?.QuestionsList?.length} val={newLegalFieldArray} />
                    </Box>
                    <Box>
                      <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h6'>Skills</Typography>
                      <ApexChart questionLength={questions?.QuestionsList?.length} val={newSkillArray} />
                    </Box>
                  </Box>
                  <Box className="resulBoxBottomTeb">
                    <Box className="PrintCertificateBtn">
                      <BlueBtn id="Tool2Crt" onClick={handelGoCertificatePage} buttonText="Print Results" />

                    </Box>
                    <BlueBtn buttonText="Start" onClick={() => setIntrcutionPop(true)} />
                  </Box>
                </Box>
                {instructionPopup(`/user/test/Tool-2`, Tool2Data)}
              </TabPanel>



              {/* Tool 3 */}
              <TabPanel value="3">
                <Box className="displayMarksInfoBox">
                  <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
                    <Box className="toolHeaderbox toolHeaderboxT2">
                      <Typography className='toolHeaderText' variant='h5' mb={2}>
                        <span className='Vl'>VLiGTA </span>
                        <span className='Rl'>®</span>
                        <span className='R_l'>Test Results</span>
                        <span className='Rm_T'>Exercise</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="QuestionBankSelectorBox">
                    <select
                      className='QuestionBankSelector'
                      onChange={(e) => {
                        setSelectedQuestionBankT3(Tool3QuestionBank?.find(el => el._id === e.target.value))
                        setCurrentTool3Marks(Tool3Marks?.marks.find((el) => el.questionBankId === e.target.value) || 0)
                      }}
                    >
                      <option value="" disabled selected>Select an QuestionBank</option>
                      {Tool3QuestionBank &&
                        Tool3QuestionBank.map(el => (
                          <option key={el?._id} value={el?._id}>{el?.CourseName}</option>
                        ))
                      }
                    </select>
                  </Box>
                  <Box className="percentageBox">
                    <Box className="qBDetailsTextBox">
                      <Typography className='blueText'>Programme Name: <span className='sLabelValue'>{selectedQuestionBankT3?.CourseName}</span></Typography>
                      <Typography className='blueText'>Programme ID: <span className='sLabelValue'>{selectedQuestionBankT3?.CourseId}</span></Typography>
                      <Typography className='blueText'>Efficiency Score: <span className='sLabelValue'>{currentTool3Marks?.TestScore / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}%</span></Typography>
                      <Typography className='blueText'>Date of access: <span className='sLabelValue'>
                        {User?.userData?.AccessTools.filter(el => el.ToolId === selectedQuestionBankT3?._id)[0]?.DateOfAccess || ""}
                      </span></Typography>
                      <Typography className='blueText'>Access expires in: <span className='sLabelValue'>{calculetToolExpire(selectedQuestionBankT3)} days</span></Typography>
                    </Box>
                    {
                      currentTool3Marks === 1 ?
                        <Typography className='persentagePlacHSelect Avenir_Black'>Please select and attempt a Test.</Typography> :
                        (currentTool3Marks === 0 ?
                          <Typography className='persentagePlacH'>You may attempt this test to get results.</Typography>
                          :
                          <Box className="tool3PieBox PersentageCircul">
                            <CircularProgressbar
                              value={currentTool3Marks?.TestScore / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}
                              text={`${currentTool3Marks?.TestScore / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}%`} />
                          </Box>
                        )
                    }
                  </Box>

                  <Box className="efficiencyBox">
                    <Typography className='Avenir_Black'> Efficiency Levels</Typography>

                    <Box className="efficInnerBox">
                      <Box className="efficInnerItemBox">
                        <Box>
                          <Typography className='efficScoreLabel'>Developing Efficiency (approx. 25%)</Typography>
                          <Typography className='efficScore'>
                            {currentTool3Marks?.questionIndexMarks?.marks1 / (selectedQuestionBankT3?.QuestionsList?.length * 1) * 100 || 0}%
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className='efficScoreLabel'>Standard Efficiency (approx. 50%)</Typography>
                          <Typography className='efficScore'>
                            {currentTool3Marks?.questionIndexMarks?.marks2 / (selectedQuestionBankT3?.QuestionsList?.length * 2) * 100 || 0}%
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="efficInnerItemBox">
                        <Box>
                          <Typography className='efficScoreLabel'>Advanced Efficiency (approx. 75%)</Typography>
                          <Typography className='efficScore'>
                            {currentTool3Marks?.questionIndexMarks?.marks3 / (selectedQuestionBankT3?.QuestionsList?.length * 3) * 100 || 0}%
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className='efficScoreLabel'>Peak Efficiency (approx. 100%)</Typography>
                          <Typography className='efficScore'>
                            {currentTool3Marks?.questionIndexMarks?.marks4 / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}%
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="resulBoxBottomTeb">
                    <Box className="PrintCertificateBtn">
                      <BlueBtn id="Tool3Crt" onClick={handelGoCertificatePage} buttonText="Print Results" />
                      <BlueBtn disabled={!selectedQuestionBankT3} onClick={() => window.open(selectedQuestionBankT3?.Notes)} buttonText="Notes" />
                      <BlueBtn disabled={!selectedQuestionBankT3} onClick={() => window.open(selectedQuestionBankT3?.AppointmentUrl)} buttonText="Schedule an Appointment" />
                    </Box>
                    <BlueBtn onClick={() => handelAccessCheck(selectedQuestionBankT3)} buttonText="Start" />
                  </Box>
                </Box>
                {instructionPopup(`/user/test/Tool-3?Qid=${selectedQuestionBankT3?._id || Tool3QuestionBank[0]?._id}`, Tool3Data)}
              </TabPanel>

              {/* Tool 4 */}
              <TabPanel value="4">
                <Box className="displayMarksInfoBox">
                  <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
                    <Box className="toolHeaderbox">
                      <Typography className='toolHeaderText' variant='h5' mb={2}>
                        <span className='Vl'>VLiGTA </span>
                        <span className='Rl'>®</span>
                        <span className='R_l'>Evaluation Results</span>
                        <span className='Rm_T'>App Law Persona</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="percentageBox">
                    <Box className="qBDetailsTextBox">
                      <Typography className='blueText'>Efficiency Score: <span className='sLabelValue'>{(Tool4Marks?.TestScore / (Tool4Marks?.Tool4QuestionLength * 4) * 100 || 0).toFixed(0)}%</span></Typography>
                    </Box>
                    <Box className="tool3PieBox PersentageCircul">
                      <CircularProgressbar
                        value={(Tool4Marks?.TestScore / (Tool4Marks?.Tool4QuestionLength * 4) * 100 || 0).toFixed(0)}
                        text={`${(Tool4Marks?.TestScore / (Tool4Marks?.Tool4QuestionLength * 4) * 100 || 0).toFixed(0)}%`} />
                    </Box>


                  </Box>

                  <Box className="efficiencyBox">
                    <Typography className='Avenir_Black'> Efficiency Levels</Typography>

                    <Box className="efficInnerBox">
                      <Box className="efficInnerItemBox">
                        <Box>
                          <Typography className='efficScoreLabel'>Developing Efficiency (approx. 25%)</Typography>
                          <Typography className='efficScore'>
                            {(Tool4Marks?.questionIndexMarks?.marks1 / (Tool4Marks?.Tool4QuestionLength * 1) * 100).toFixed(0) || 0}%
                          </Typography>

                        </Box>
                        <Box>
                          <Typography className='efficScoreLabel'>Standard Efficiency (approx. 50%)</Typography>
                          <Typography className='efficScore'>
                            {(Tool4Marks?.questionIndexMarks?.marks2 / (Tool4Marks?.Tool4QuestionLength * 2) * 100).toFixed(0) || 0}%
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="efficInnerItemBox">
                        <Box>
                          <Typography className='efficScoreLabel'>Advanced Efficiency (approx. 75%)</Typography>
                          <Typography className='efficScore'>
                            {(Tool4Marks?.questionIndexMarks?.marks3 / (Tool4Marks?.Tool4QuestionLength * 3) * 100).toFixed(0) || 0}%
                          </Typography>

                        </Box>
                        <Box>
                          <Typography className='efficScoreLabel'>Peak Efficiency (approx. 100%)</Typography>
                          <Typography className='efficScore'>
                            {(Tool4Marks?.questionIndexMarks?.marks4 / (Tool4Marks?.Tool4QuestionLength * 4) * 100).toFixed(0) || 0}%
                          </Typography>

                        </Box>
                      </Box>
                    </Box>
                  </Box>



                  <Box className="resulBoxBottomTeb">
                    <Box className="PrintCertificateBtn">
                      <BlueBtn id="Tool4Crt" onClick={handelGoCertificatePage} buttonText="Print Results" />
                    </Box>
                    <BlueBtn onClick={() => setIntrcutionPop(true)} buttonText="Start" />
                  </Box>
                </Box>
                {instructionPopup(`/user/test/Tool-3?Qid=${selectedQuestionBankT3?._id || Tool3QuestionBank[0]?._id}`, Tool4Data, T4)}
              </TabPanel>



              {/* Career Recommendations */}
              <TabPanel value='5'>
                <Box className="displayMarksInfoBox">

                  <Box mb={8} className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
                    <Box className="toolHeaderbox">
                      <Typography className='toolHeaderText' variant='h5' mb={2}>
                        <span className='Vl'>VLiGTA </span>
                        <span className='Rl'>®</span>
                        <span className='R_l'>Career Recommendations</span>
                        <span className='Rm_T'>App Skill Evaluator</span>
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    {getCareerRecommendations(0)}
                    {getCareerRecommendations(1)}
                    {getCareerRecommendations(2)}
                    {getCareerRecommendations(3)}
                    {getCareerRecommendations(4)}
                  </Box>
                </Box>
              </TabPanel>



              {/* Summary of every */}
              <TabPanel value='6'>
                <Box className="displayMarksInfoBox">
                  <Box mb={8} className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
                    <Box className="toolHeaderbox">
                      <Typography className='toolHeaderText' variant='h5' mb={2}>
                        <span className='Vl'>VLiGTA </span>
                        <span className='Rl'>®</span>
                        <span className='R_l'>Skill Archetypes</span>
                        <span className='Rm_T'>App Skill Evaluator</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box>{getEventSummary()}</Box>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>

          {/* legal field popup */}
          <Box sx={{ display: legalFieldPopUp ? "flex" : "none" }} className="t4_legalPopup">
            <Box className="closeBtnBox">
              <Box onClick={() => setLegalFieldPopUp(false)} className="t4_legalP_C_btn"><Typography>Close</Typography></Box>
            </Box>

            <Box className="toolHeaderUpbox"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: 'center'

              }}>
              <Box className="toolHeaderbox">
                <Typography className='toolHeaderText' variant='h5' mb={2}>
                  <span style={{ fontSize: "34px" }} className='Vl'>VLiGTA </span>
                  <span style={{ left: "115px" }} className='Rl'>®</span>
                  {/* <span className='R_l'>Evaluation Results</span> */}
                  <span style={{ fontSize: "34px" }} className='Rm_T'>App Law Persona</span>
                </Typography>
              </Box>
            </Box>



            <Typography mt={4} className='t4_legalPopup_p'>Welcome to Law Persona.</Typography>
            <Typography className='t4_legalPopup_p'>You'll have 4 possible solutions for each problem.
              Rank the solutions from best to worst (1 being the best, 4 being the worst).
              Do this for every problem in the test.</Typography>
            <Typography className='t4_legalPopup_p'>Start by choosing the field you're interested in to address the issues.</Typography>
            <Typography className='t4_legalPopup_p Avenir_Black'>Time to complete the test: 60 mins</Typography>
            <Box className="legalFieldSelecter">
              <Select
                sx={{ color: "#fff" }}
                className='legalFieldSelector'
                defaultValue=""
                id="grouped-select"
                value={legalFieldPopUpVal}
                onChange={(e) => setLegalFieldPopUpVal(e.target.value)}>
                {CareerSummery.map((el) => (
                  <MenuItem key={el._id} value={el.name}>{el.name}</MenuItem>
                ))}</Select>
              <BlueBtn onClick={handelPopSubmitT4} buttonText="Start the test" />
            </Box>
          </Box>



        </Box>
        <Footer />
      </>
    )
  }
}
