import React, { useState, useEffect } from 'react'
import "./style.css"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material'
import { fetchTool1, UpdateT1Instruction } from "../../store/Tool1Slice";
import { fetchTool2, UpdateT2Instruction } from "../../store/Tool2Slice";
import { fetchTool3, UpdateT3Instruction } from "../../store/Tool3Slice";
import { fetchTool4, UpdateT4Instruction } from "../../store/Tool4Slice";


//components
import { RedBtn, BlueBtn } from '../../components/Buttons'
import { Notification } from '../../App';

export default function Instruction() {
  const dis = true;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AdminLog = localStorage.getItem("login session");
  if (!AdminLog) navigate("/administrator/login");
  const { tool1, tool2, tool3, tool4 } = useSelector((state) => state);



  const Tool1Data = tool1?.data ? tool1?.data?.data : [];
  const Tool2Data = tool2?.data ? tool2?.data?.data : [];
  const Tool3Data = tool3?.data ? tool3?.data?.data : [];
  const Tool4Data = tool4?.data ? tool4?.data?.data : [];


  const [T1Val, setT1Val] = useState({ val: "" });
  const [T1, setT1] = useState(false)

  const [T2Val, setT2Val] = useState({ val: '' });
  const [T2, setT2] = useState(false)

  const [T3Val, setT3Val] = useState({ val: '' });
  const [T3, setT3] = useState(false)

  const [T4Val, setT4Val] = useState({ val: '' });
  const [T4, setT4] = useState(false)



  const handleT1SumUpdate = (val) => {
    setT1Val((previousVal) => ({ ...previousVal, val }));
  };
  const handleT2SumUpdate = (val) => {
    setT2Val((previousVal) => ({ ...previousVal, val }));
  };
  const handleT3SumUpdate = (val) => {
    setT3Val((previousVal) => ({ ...previousVal, val }));
  };
  const handleT4SumUpdate = (val) => {
    setT4Val((previousVal) => ({ ...previousVal, val }));
  };

  const handelUpdate = (val, id) => {
    if (val == "T1" && T1) {
      console.log("post1");
      dispatch(UpdateT1Instruction({ data: T1Val.val, id }))
      Notification("success", "Tool-1 Instructions updated successfully")
    }
    if (val == "T2" && T2) {
      console.log("post2");
      dispatch(UpdateT2Instruction({ data: T2Val.val, id }))
      Notification("success", "Tool-2 Instructions updated successfully")
    }
    if (val == "T3" && T3) {
      console.log("post3");
      dispatch(UpdateT3Instruction({ data: T3Val.val, id }))
      Notification("success", "Tool-3 Instructions updated successfully")
    }

    if (val == "T4" && T4) {
      console.log("post4");
      dispatch(UpdateT4Instruction({ data: T4Val.val, id }))
      Notification("success", "Tool-4 Instructions updated successfully")
    }

  }

  useEffect(() => {
    dispatch(fetchTool1());
    dispatch(fetchTool2());
    dispatch(fetchTool3());
    dispatch(fetchTool4());
  }, [])

  return (
    <>
      <Box className="InstructionContainer">
        <Typography variant='h4'>Modify Test Instruction</Typography>

        {tool4?.status === "loading" ? (
          <Box mt={2}>
            <Typography sx={{ color: "#fff" }}>Loading...</Typography>
          </Box>
        ) : tool4?.status === "error" ? (
          <Box>
            <Typography sx={{ color: "#fff" }}>Error occurred: Internal server error. / Please try again.</Typography>
          </Box>
        ) : tool1?.status === "idle" &&
          tool2?.status === "idle" &&
          tool3?.status === "idle" &&
          tool4?.status === "idle" ? (
          <>
            <Box className="toolInsBox">
              <Typography className='ToolLavel'>Tool-1</Typography>
              <textarea
                disabled={T1 ? "" : dis}
                value={T1Val.val ? T1Val.val : Tool1Data[0]?.Instruction}
                onChange={(e) => handleT1SumUpdate(e.target.value)}
              />
              <Box className="ToolInsBtnBox">
                <RedBtn buttonText="Cancel" onClick={() => setT1(false)} />
                <BlueBtn buttonText={T1 ? "Save" : "Edit"} onClick={() => {
                  setT1(!T1)
                  handelUpdate("T1", Tool1Data[0]?._id)
                }} />
              </Box>
            </Box>


            <Box className="toolInsBox">
              <Typography className='ToolLavel'>Tool-2</Typography>
              <textarea
                disabled={T2 ? "" : dis}
                value={T2Val?.val ? T2Val?.val : Tool2Data[0]?.Instruction}
                onChange={(e) => handleT2SumUpdate(e.target.value)}
              />
              <Box className="ToolInsBtnBox">
                <RedBtn buttonText="Cancel" onClick={() => setT2(false)} />
                <BlueBtn buttonText={T2 ? "Save" : "Edit"} onClick={() => {
                  setT2(!T2)
                  handelUpdate("T2", Tool2Data[0]?._id)
                }} />
              </Box>
            </Box>


            <Box className="toolInsBox">
              <Typography className='ToolLavel'>Tool-3</Typography>
              <textarea
                disabled={T3 ? "" : dis}
                value={T3Val.val ? T3Val.val : Tool3Data[0]?.Instruction}
                onChange={(e) => handleT3SumUpdate(e.target.value)}
              />
              <Box className="ToolInsBtnBox">
                <RedBtn buttonText="Cancel" onClick={() => setT3(false)} />
                <BlueBtn buttonText={T3 ? "Save" : "Edit"} onClick={() => {
                  setT3(!T3)
                  handelUpdate("T3", Tool3Data[0]?._id)
                }} />
              </Box>
            </Box>



            <Box className="toolInsBox">
              <Typography className='ToolLavel'>Tool-4</Typography>
              <textarea
                disabled={T4 ? "" : dis}
                value={T4Val.val ? T4Val.val : Tool4Data[0]?.Instruction}
                onChange={(e) => handleT4SumUpdate(e.target.value)}
              />
              <Box className="ToolInsBtnBox">
                <RedBtn buttonText="Cancel" onClick={() => setT4(false)} />
                <BlueBtn buttonText={T4 ? "Save" : "Edit"} onClick={() => {
                  setT4(!T4)
                  handelUpdate("T4", Tool4Data[0]?._id)
                }} />
              </Box>
            </Box>
          </>
        ) : <Typography variant='h5' sx={{ color: "#fff" }}>Somthing wrong happened...!</Typography>}

      </Box>

    </>
  )
}
