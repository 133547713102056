import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./style.css"
import { Box, Select, Typography, FormControl, MenuItem, InputLabel, TextField } from '@mui/material';
import { BlueBtn } from '../../../components/Buttons';
import { createAccessTool } from "../../../store/UserSlice"
import { Notification } from '../../../App';


export default function AccessTool1({ selectedUser }) {
  const dispatch = useDispatch()
  const { data, status } = useSelector((state) => state.tool1);
  const Tool1 = data?.data ? data.data[0]?.QuestionsBanks : [];
  const [selectedQuestionBank, setSelectedQuestionBank] = useState();
  const [Months, setMonths] = useState()
  const [Weeks, setWeeks] = useState()
  const [Days, setDays] = useState()
  const currentDate = new Date();


  console.log(selectedUser?.AccessTools);
  const handleDate = (e) => {
    const { name, value } = e.target;
    if (name === "Months") {
      setMonths(Math.max(0, parseInt(value, 10)) || 0)
    }
    if (name === "Weeks") {
      setWeeks(Math.max(0, parseInt(value, 10)) || 0)
    }
    if (name === "Days") {
      setDays(Math.max(0, parseInt(value, 10)) || 0)
    }
  }

  const handleAccessToolPost = () => {
    if (!selectedQuestionBank?._id || !Months && !Weeks && !Days) {
      Notification("warning", "Please fill all the values!")
      return;
    } else {
      if (selectedUser?.AccessTools.some(el => el.ToolId === selectedQuestionBank._id)) {
        Notification("warning", "Already have access to this programme !")
        return;
      } else {
        dispatch(createAccessTool({
          data: {
            userId: selectedUser._id,
            AccessTools: [{
              ToolId: selectedQuestionBank._id,
              DateOfAccess: currentDate.toLocaleDateString(),
              Months,
              Weeks,
              Days
            }]
          }
        }))
        Notification("success", "Successfully updated")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }

  return (
    <>
      <Box className="AccessToolContainer">
        <Box className="ATLBox">
          <Typography sx={{ textAlign: "left", color: "#fff" }} variant='h5'>Tool 1</Typography>
          <Box className="selectQuestionBankBox">
            <Typography sx={{ textAlign: "left", color: "#fff" }} variant='h5'>Select Programme </Typography>
            <FormControl sx={{ ml: 2, minWidth: 220 }}>
              <InputLabel htmlFor="grouped-select">Select Programme</InputLabel>
              <Select
                sx={{ color: "#fff" }}
                disabled={!selectedUser}
                defaultValue=""
                id="grouped-select"
                label="Select Programme"
                onChange={(e) => setSelectedQuestionBank(e.target.value)}
              >
                {Tool1?.map((el) => (
                  <MenuItem key={el._id} value={el}>{el.CourseName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="selectedValBox">
            <Box className="selectQuestionBankBox">
              <Typography variant='h6'>Programme Name </Typography>
              <Typography className='accessTitle' >{selectedQuestionBank?.CourseName || ""}</Typography>
            </Box>
            <Box my={3} className="selectQuestionBankBox">
              <Box className="selectDateBox">
                <Box className="selectProgramme">
                  <TextField type='Number' name='Months' onChange={handleDate} value={Months} />
                  <Typography>Months</Typography>
                </Box>
                <Box mx={5} className="selectProgramme">
                  <TextField type='Number' name='Weeks' onChange={handleDate} value={Weeks} />
                  <Typography>Weeks</Typography>
                </Box>
                <Box className="selectProgramme">
                  <TextField type='Number' name='Days' onChange={handleDate} value={Days} />
                  <Typography>Days</Typography>
                </Box>
              </Box>
            </Box>
            <BlueBtn buttonText="Give Access" onClick={handleAccessToolPost} />
          </Box>
        </Box>
        <Box className="ATRBox">
          <Typography mb={2} variant='h5' sx={{ color: "#fff" }}>Tools Access</Typography>
          <Box className="AccessToolsListBox">
            {
              selectedUser &&
              selectedUser?.AccessTools?.map((el, index) => (
                <Box key={index} className="AccessToolsList" sx={{ display: Tool1.filter(val => val._id === el.ToolId)[0]?.CourseName === undefined ? "none" : "block" }}>
                  <Typography className='accessTitle'>Programme: <span> {Tool1.filter(val => val._id === el.ToolId)[0]?.CourseName}</span></Typography>
                  <Typography className='accessTitle'>Access Time : Month- <span> {el.Months}</span> Weeks- <span> {el.Weeks}</span> Days- <span> {el.Days}</span></Typography>
                </Box>
              ))
            }
          </Box>

        </Box>

      </Box>

    </>
  )
}
