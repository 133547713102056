import React, { useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { getUserId, fetchUser } from "../../store/UserSlice";
import { useDispatch, useSelector } from 'react-redux';

import "./style.css"

import Avatar from "../../Assets/Images/User-Profile-PNG-Image.png";
import Clogo from "../../Assets/Images/vligtaPro2.png";

//Component
import NavBar from "../../components/ClientNavBar";
import Footer from '../../components/Footer';

export default function UserHomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { User, status, UserId } = useSelector((state) => state.user);
  const userLog = localStorage.getItem("userString");

  useEffect(() => {
    if (!UserId) {
      dispatch(getUserId());
    }
    if (!User.length && UserId) {
      dispatch(fetchUser({ id: UserId }));
    }
  }, [UserId, User.length])

  return (
    <>
      <NavBar />
      <Box className="userHomePage">

        <Box className="homeHeaderBox">
          <Typography className='hther'>Hi there.</Typography>
          <Typography className='wlTo'>Welcome to</Typography>
          <img className='CproLogo' src={Clogo} />
        </Box>

        <Box className="homeContaintBox">
          <Box className="HomeTextBox">
            <Typography className='HTextHeader'>Refine Your Skills with VLiGTA® Pro</Typography>
            <Typography className='HTextSub'>At VLiGTA® Pro, we understand the importance of
              effective skill development. That's why we provide
              specialized skill-shaping and testing tools tailored to
              complement the law & policy training programmes
              you've chosen on the VLiGTA® App.
            </Typography>
            <Typography className='HTextHeader'>Unlock Your Potential in Law and Policy</Typography>
            <Typography className='HTextSub'>In addition to our premium offerings, we're pleased to
              provide free skill testing tools specifically in the fields of law and policy. Whether you're a professional seeking to sharpen your expertise or a learner eager to
              explore new areas, VLiGTA® Pro is here to support
              your growth journey.
            </Typography>
          </Box>
          <Box className="HomeTextBox">
            <Typography className='HTextHeader'>Get Started Today</Typography>
            {
              userLog ?
                <Typography className='HTextSub'>Check out the list of legal / policy skill tools available in the Dashboard and take the next step in your professional development!</Typography> :
                <Typography className='HTextSub'>Log In Now to access our range of tools and take the next step in your professional development! </Typography>
            }
            {
              userLog ?
                <Box onClick={() => navigate("/user/profile")} className="HomeButton"><Typography>Welcome {User?.userData?.name} </Typography></Box> :
                <Box className="HomeButton" onClick={() => navigate("/user/signUp")}><Typography>Log in / Register</Typography></Box>
            }

            <Typography className='HTextHeader'>Know more about our tools</Typography>
            <Typography className='HTextSub'>Check out our tools offered at VLiGTA.Pro on VLiGTA App.</Typography>
            <a className='homeVlink' href="https://vligta.app/pro/"> <Box className="HomeButton"><Typography>Our tools at VLiGTA.Pro</Typography></Box></a>
          </Box>

        </Box>
        <Box className="hR_Box">
          <Typography className='HTextSub'>We are excited to announce that the tools and training programmes offered by VLiGTA ® Pro is certified by the Indian Society of Artificial Intelligence and Law (ISAIL). This certification further validates the quality and relevance of our offerings in the rapidly evolving field of artificial intelligence. </Typography>
          <Typography className='HTextSub'>ISAIL, a non-profit industry body for the analytics & AI industry in India promotes responsible development of artificial intelligence and its standardisation in India.</Typography>
          <Typography className='HTextSub'>Know more about the certification at <a href="https://isail.in/certify" target='black'>isail.in/certify</a></Typography>
        </Box>
      </Box>
      <Footer />
    </>
  )
}