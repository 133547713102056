import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import UserSlice from "./UserSlice";
import tool1Reducer from "./Tool1Slice";
import tool2Reducer from "./Tool2Slice";
import tool3Reducer from "./Tool3Slice";
import tool4Reducer from "./Tool4Slice";

const persistConfig = {
  key: "root",
  storage,
};

const reducer = combineReducers({
  tool1: tool1Reducer,
  tool2: tool2Reducer,
  tool3: tool3Reducer,
  tool4: tool4Reducer,
  user: UserSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;
