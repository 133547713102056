import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Components
import Home from "./Pages/Home";
import AdminLogin from "./Pages/Login";
import ClientHomePage from "./Pages/User/UserHomePage";
import UserProfile from "./Pages/User/UserProfile";
import UserLogin from "./Pages/UserAuth/UserSignUP";
import OTPVerify from "./Pages/UserAuth/OTPVerify";
import Tool1QuestionSet from "./Pages/ViewQuestions/Tool1QuestionSet";
import Tool2QuestionSet from "./Pages/ViewQuestions/Tool2QuestionsSet";
import Tool3QuestionSet from "./Pages/ViewQuestions/Tool3QuestionSet";
import Tool4QuestionSet from "./Pages/ViewQuestions/Tool4QuestionSet";
import Tool1Certificate from "./Pages/Certificate/Tool1";
import Tool2Certificate from "./Pages/Certificate/Tool2";
import Tool3Certificate from "./Pages/Certificate/Tool3";
import Tool4Certificate from "./Pages/Certificate/Tool4";

export const Notification = (type, message) => {
  if (type === "success") toast.success(message);
  if (type === "error") toast.error(message);
  if (type === "warning") toast.warn(message);
};

export default function App() {
  const [marks, setMarks] = useState(0);
  const [resOTP, setResOTP] = useState();
  const [newUserId, setNewUserId] = useState();
  const [newUser, setNewUser] = useState();
  const [legalFieldPopUpVal, setLegalFieldPopUpVal] = useState("");
  const [crtUserName, setCrtUserName] = useState("");
  const [crtCorseName, setCrtCorseName] = useState("");
  const [crtCorseId, setCrtCorseId] = useState("");
  const [crtCorseMentor, setCrtCorseMentor] = useState("");
  const [keySkills, setKeyskills] = useState([]);
  const [selectedQuestionBankT3, setSelectedQuestionBankT3] = useState();
  const [currentTool3Marks, setCurrentTool3Marks] = useState(1);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<ClientHomePage newUserId={newUserId} />} />
        <Route
          path="/administrator"
          element={
            <Home
              selectedQuestionBankT3={selectedQuestionBankT3}
              currentTool3Marks={currentTool3Marks}
              setSelectedQuestionBankT3={setSelectedQuestionBankT3}
              setCurrentTool3Marks={setCurrentTool3Marks}
            />
          }
        />
        <Route path="/administrator/logIn" element={<AdminLogin />} />
        <Route
          path="/user/test/Tool-3"
          element={<Tool3QuestionSet setMarks={setMarks} marks={marks} />}
        />
        <Route
          path="/user/test/Tool-4"
          element={
            <Tool4QuestionSet
              setMarks={setMarks}
              marks={marks}
              setLegalFieldPopUpVal={setLegalFieldPopUpVal}
              legalFieldPopUpVal={legalFieldPopUpVal}
            />
          }
        />
        <Route path="/user/test/Tool-1" element={<Tool1QuestionSet />} />
        <Route path="/user/test/Tool-2" element={<Tool2QuestionSet />} />
        <Route
          path="/user/profile"
          element={
            <UserProfile
              marks={marks}
              setLegalFieldPopUpVal={setLegalFieldPopUpVal}
              legalFieldPopUpVal={legalFieldPopUpVal}
              setCrtUserName={setCrtUserName}
              setCrtCorseName={setCrtCorseName}
              setCrtCorseId={setCrtCorseId}
              setCrtCorseMentor={setCrtCorseMentor}
              selectedQuestionBankT3={selectedQuestionBankT3}
              currentTool3Marks={currentTool3Marks}
              setSelectedQuestionBankT3={setSelectedQuestionBankT3}
              setCurrentTool3Marks={setCurrentTool3Marks}
              setKeyskills={setKeyskills}
            />
          }
        />
        <Route
          path="/user/test/Tool-1/certificate"
          element={
            <Tool1Certificate
              crtUserName={crtUserName}
              crtCorseName={crtCorseName}
              crtCorseId={crtCorseId}
              crtCorseMentor={crtCorseMentor}
              keySkills={keySkills}
            />
          }
        />
        <Route
          path="/user/test/Tool-2/certificate"
          element={<Tool2Certificate crtUserName={crtUserName} />}
        />
        <Route
          path="/user/test/Tool-3/certificate"
          element={
            <Tool3Certificate
              crtUserName={crtUserName}
              selectedQuestionBankT3={selectedQuestionBankT3}
              currentTool3Marks={currentTool3Marks}
              keySkills={keySkills}
            />
          }
        />
        <Route
          path="/user/test/Tool-4/certificate"
          element={
            <Tool4Certificate
              crtUserName={crtUserName}
              currentTool3Marks={currentTool3Marks}
            />
          }
        />
        <Route
          path="/user/signUp"
          element={
            <UserLogin
              setResOTP={setResOTP}
              setNewUserId={setNewUserId}
              setNewUser={setNewUser}
            />
          }
        />
        <Route
          path="/user/signUp/otp-verify"
          element={
            <OTPVerify
              resOTP={resOTP}
              newUserId={newUserId}
              setResOTP={setResOTP}
              newUser={newUser}
            />
          }
        />
      </Routes>
    </>
  );
}
