import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { Box, Typography, Button, TextField } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from '@mui/styles';
import Axios from "axios"
import { Notification } from '../../App';
import "./login.css"
import Clogo from "../../Assets/Images/vligtaPro2.png";


import Footer from '../../components/Footer';

export default function Login({ setValue }) {
  const data = localStorage.getItem('login session');
  const user = JSON.parse(data);
  const [showLoginPage, setLogInPage] = useState(false);
  const [loginBtn, setLoginBtn] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [userName, SetUserName] = useState("");
  const [userEmail, SetUserEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [confirmPassword, SetConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handelLogInPage = () => {
    setLogInPage(!showLoginPage)
  }

  const useStyles = makeStyles({
    input: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
      },
    },
  });
  const classes = useStyles();

  //Login page
  const handelPasswordVisibility = () => {
    setPasswordShow(!passwordShow)
  }
  const handelLogInBtn = (e) => {
    if (e.target.value) {
      setLoginBtn(false);
    } else {
      setLoginBtn(true);
    }
  }


  const handelLogin = () => {
    if (!password.length && !userName.length) {
      Notification("warning", "Please fill all the fields!")
      return
    }
    Axios.post(process.env.REACT_APP_BASE_URL + "/admin/login",
      {
        email: userEmail,
        password
      }
    ).then(res => {
      localStorage.setItem("login session", JSON.stringify(res?.data?.data));
      navigate("/administrator")
    }).catch(err => {
      console.log(err);
      Notification("error", err?.response?.data?.message || err.message)
    })
  }

  //Create Admin
  const handelChange = (e) => {
    if (e?.target?.name === "confirmPassword") {
      if (password !== e?.target?.value) {
        setPasswordErr(true)
      } else {
        setPasswordErr(false)
      }
    }
  }

  const handelSignUp = () => {
    if (!password.length || !confirmPassword || !userName || !userEmail) {
      Notification("warning", "Please fill all the fields!")
      return
    }
    if (password !== confirmPassword) {
      Notification("warning", "Password and Confirm Password does not match!")
      return
    }
    if (user?.role !== "Super Admin") {
      Notification("warning", "Not Authorized to Create Admin. Please contact your Admin.")
      return;
    }

    Axios.post(process.env.REACT_APP_BASE_URL + "/admin/Create",
      {
        name: userName,
        email: userEmail,
        password,
        role: "Admin"
      }
    ).then(res => {
      Notification("success", res?.data?.message)
      Notification("success", `Admin creted successfully !`)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      navigate("/administrator")

    }).catch(err => {
      Notification("error", err?.response?.data?.message)
    })
  }

  const handleEnterKey = () => {
    if (loginBtn) {
      return;
    }
    if (showLoginPage) {
      handelLogin()
      return;
    } else {
      handelSignUp()
      return;
    }
  }
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") handleEnterKey();
      if (event.code === "Delete");
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [loginBtn, password, confirmPassword]);


  return (
    <>
      <Box className='loginContainer'>
        <Box className="logBackBtn" onClick={() => navigate("/administrator")}>
          <ArrowBackIosIcon />
          <Typography>BACK</Typography>
        </Box>

        <Box className="logInInnerContainer">
          <Box sx={{ display: showLoginPage ? "flex" : "none" }} className="homeHeaderBox">
            <Typography className='wlTo'>Welcome to</Typography>
            <img className='CproLogo' src={Clogo} />
          </Box>

          <Typography className='adminLogHeader'>{showLoginPage ? "Admin Log-In" : "Create a new Admin"}</Typography>
          <Box className="loginBox">
            {showLoginPage ? (
              <div>
                <Box mb={3} className="inputBox">
                  <TextField
                    sx={{ width: "300px" }}
                    className="LoginInputField"
                    name="email"
                    placeholder="email"
                    onChange={(e) => {
                      SetUserEmail(e?.target?.value);
                      handelLogInBtn(e);
                    }}
                    value={userEmail}
                  />
                </Box>

                <Box mb={3} className="passwordInputBox">
                  <TextField
                    sx={{ width: "302px" }}
                    onChange={(e) => {
                      SetPassword(e?.target?.value);
                    }}
                    value={password}
                    className="LoginInputField"
                    type={passwordShow ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                  />
                  <Typography
                    onClick={handelPasswordVisibility}
                    sx={{ cursor: "pointer", userSelect: "none", color: "#fff" }}
                    pl={1}
                  >
                    {passwordShow ? "Hide" : "Show"}
                  </Typography>
                </Box>

                <Box className="loginBtnBox">
                  <Button
                    disabled={loginBtn}
                    onClick={handelLogin}
                    sx={{ width: "100px" }}
                    variant="contained"
                  >
                    Login
                  </Button>
                </Box>
              </div>
            ) : (
              <>
                <Box mb={3} className="inputBox">
                  <TextField
                    sx={{ width: "300px" }}
                    className="LoginInputField"
                    name="userName"
                    placeholder="User name"
                    onChange={(e) => {
                      SetUserName(e?.target?.value);
                      handelLogInBtn(e);
                    }}
                    value={userName}
                  />
                </Box>

                <Box mb={3} className="inputBox">
                  <TextField
                    sx={{ width: "300px" }}
                    className="LoginInputField"
                    name="email"
                    placeholder="email"
                    onChange={(e) => {
                      SetUserEmail(e?.target?.value);
                      handelLogInBtn(e);
                    }}
                    value={userEmail}
                  />
                </Box>

                <Box mb={3} className="passwordInputBox">
                  <TextField
                    sx={{ width: "302px" }}
                    onChange={(e) => {
                      SetPassword(e?.target?.value);
                    }}
                    value={password}
                    className="LoginInputField"
                    type={passwordShow ? "text" : "password"}
                    name="password"
                    placeholder="Password (4-8)"
                  />
                  <Typography
                    onClick={handelPasswordVisibility}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    pl={1}
                  >
                    {passwordShow ? "Hide" : "Show"}
                  </Typography>
                </Box>

                <Box mb={3} className="inputBox cPwdBox">
                  <TextField
                    sx={{ width: "300px" }}
                    type={passwordShow ? "text" : "password"}
                    name='confirmPassword'
                    placeholder='Confirm Password'
                    className={passwordErr ? classes.input : ""}
                    onChange={e => {
                      handelChange(e)
                      SetConfirmPassword(e?.target?.value)
                    }
                    }
                    value={confirmPassword}
                  />
                  <Typography mt={1} className={passwordErr ? "" : "displayHide"} ml={1} variant='caption' sx={{ color: "#fa5c5c", fontWeight: 600 }}>Password does not match.</Typography>
                </Box>

                <Box mt={3} className="loginBtnBox">
                  <Button
                    disabled={loginBtn}
                    onClick={handelSignUp}
                    sx={{ width: "100px" }}
                    variant="contained">
                    sign Up
                  </Button>
                </Box>

              </>
            )}
          </Box>

          <Typography onClick={handelLogInPage} variant='caption' mt={4} sx={{ color: "#3498db", cursor: "pointer" }}>
            {showLoginPage ? "Create a new Admin." : "I already have a account."}
          </Typography>
        </Box>
      </Box >
      <Footer />
    </>
  )
}




