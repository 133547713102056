import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTool3 } from "../../../store/Tool3Slice";
import { updateToolMarks, getUserId, fetchUser } from "../../../store/UserSlice"
import { Box, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import "./style.css"

import "../Tool3QuestionSet/viewQuestions.css";

//Data
import { bucketA, bucketB, bucketC, bucketD, bucketE, bucketF, bucketG, bucketH } from "../../../Assets/Data/bucketList"

//Component
import NavBar from "../../../components/ClientNavBar"
import Footer from '../../../components/Footer';
import { TestNoteBox } from "../Tool3QuestionSet"

export default function Tool4QuestionSet({ setMarks, marks, legalFieldPopUpVal }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const paramsQuestionBankId = searchParams.get("Qid");
  const { data, status } = useSelector((state) => state.tool3);
  const { User, UserId } = useSelector((state) => state.user);
  const userLog = localStorage.getItem("userString");
  if (!userLog) navigate("/")
  const [allQuestions, setAllQuestions] = useState([]);
  const questionBank = data?.data ? data.data[0]?.QuestionsBanks : [];



  const findBucket = (selectorValue) => {
    if (bucketA.includes(selectorValue)) {
      return bucketA;
    } else if (bucketB.includes(selectorValue)) {
      return bucketB;
    } else if (bucketC.includes(selectorValue)) {
      return bucketC;
    } else if (bucketD.includes(selectorValue)) {
      return bucketD;
    } else if (bucketE.includes(selectorValue)) {
      return bucketE;
    } else if (bucketF.includes(selectorValue)) {
      return bucketF;
    } else if (bucketG.includes(selectorValue)) {
      return bucketG;
    } else if (bucketH.includes(selectorValue)) {
      return bucketH;
    } else {
      return null;
    }
  }

  const matchedBucket = findBucket(legalFieldPopUpVal);
  const filteredQuestionBank = questionBank.filter(QB =>
    QB.FieldCategory.some(category => matchedBucket?.includes(category))
  );
  const BeforeshuffledArray = filteredQuestionBank?.flatMap((bank) => bank.QuestionsList.map((question) => question));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [clickedOptions, setClickedOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [checkBox, setCheckBox] = useState(false)
  const [marksIndex, setMarksIndex] = useState({
    marks1: 0,
    marks2: 0,
    marks3: 0,
    marks4: 0
  });

  console.log(selectedOptionIndex);
  console.log(clickedOptions);



  function calculateMarks(rightPattern, inputPattern) {
    let marks = 0;
    for (let i = 0; i < rightPattern.length; i++) {
      const rightOption = rightPattern[i];
      const inputOption = inputPattern[i];

      if (rightOption === inputOption) {
        marks++;
      }
    }
    setClickedOptions([]);
    return marks;
  }

  const handelMarks = () => {
    const rightPattern = allQuestions[currentQuestionIndex].Pattern.split('');
    return calculateMarks(rightPattern, clickedOptions);
  }
  let TotalMarks = []
  const HandleSubmitQuestion = () => {
    setCheckBox(false)
    if (currentQuestionIndex < allQuestions?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setClickedOptions([]);
      setSelectedOptionIndex(null);
    }
    const marksData = handelMarks();
    setMarks((provMarks) => provMarks * 1 + marksData * 1)
    TotalMarks.push(marks + marksData)

    let newMarksIndex = { ...marksIndex };

    if (marksData === 1) {
      newMarksIndex.marks1 = (marksIndex?.marks1 || 0) + marksData;
    } else if (marksData === 2) {
      newMarksIndex.marks2 = (marksIndex?.marks2 || 0) + marksData;
    } else if (marksData === 3) {
      newMarksIndex.marks3 = (marksIndex?.marks3 || 0) + marksData;
    } else if (marksData === 4) {
      newMarksIndex.marks4 = (marksIndex?.marks4 || 0) + marksData;
    }
    setMarksIndex(newMarksIndex);


    if (currentQuestionIndex === allQuestions?.length - 1) {

      if (userLog) {
        dispatch(updateToolMarks({
          email: User?.userData?.email,
          TestName: "Tool4",
          TestScore: TotalMarks[0],
          questionIndexMarks: newMarksIndex,
          QuestionBankId: "123",
          Tool4QuestionLength: allQuestions?.length
        }))
      }
      navigate("/user/profile")
      setTimeout(() => {
        TotalMarks = []
        setMarksIndex({
          marks1: 0,
          marks2: 0,
          marks3: 0,
          marks4: 0
        });
      }, 500);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setClickedOptions([]);
      setSelectedOptionIndex(null);
    }
  };

  const handleOptionClick = (optionIndex) => {
    const alphabet = 'ABCD';
    const selectedOption = alphabet[optionIndex];
    setSelectedOptionIndex(optionIndex);
    setClickedOptions((prevClickedOptions) => [...prevClickedOptions, selectedOption]);
    return optionIndex
  };

  const isOptionSelected = (optionIndex) => {
    return selectedOptionIndex !== null && selectedOptionIndex === optionIndex;
  };

  useEffect(() => {
    const allQuestionsArray = [...BeforeshuffledArray].sort(() => Math.random() - 0.5);
    setAllQuestions(allQuestionsArray);
  }, [data])



  useEffect(() => {
    if (data.length === 0) dispatch(fetchTool3());
    if (!UserId) {
      dispatch(getUserId());
    }
    if (!User.length && UserId) {
      dispatch(fetchUser({ id: UserId }));
    }
  }, [UserId, User.length, marks, marksIndex])

  return (
    <>
      <NavBar />
      <Box className="viewQuestionContainer">
        <Box className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
          <Box className="toolHeaderbox" sx={{ width: "460px", display: "flex", justifyContent: "center" }}>
            <Typography sx={{ textAlign: "center" }} className='toolHeaderText' variant='h5' mb={2}>
              <span className='Vl'>VLiGTA </span>
              <span style={{ left: "151px" }} className='Rl'>®</span>
              <span style={{ bottom: -64, textAlign: "center" }} className='R_l'>Rank the solutions from best to worst (1 being the best, 4 being the worst)</span>
              <span className='Rm_T'>App Law Persona</span>
            </Typography>
          </Box>
        </Box>

        <Box className="questionBox">
          {allQuestions && (
            <Box className="questionItem">
              <Typography className='Tool4Question' ><span className='questionIndexLabel Avenir_Black'> Question {currentQuestionIndex + 1}</span> <br /> {allQuestions[currentQuestionIndex]?.Question}</Typography>
              <Box className="options">
                {allQuestions[currentQuestionIndex]?.Options.map((option, optionIndex) => (
                  <Box mb={1.5} key={optionIndex}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: "10px",
                      border: "2px solid",
                      borderColor: isOptionSelected(optionIndex) ? "#F7B827" : "#ffff"

                    }}>
                    <Typography>
                      {
                        optionIndex === 0 ? "A" : optionIndex === 1 ? "B" : optionIndex === 2 ? "C" : optionIndex === 3 ? "D" : null
                      }
                    </Typography>
                    <input
                      className='pointer'
                      type="checkbox"
                      name={`option-${currentQuestionIndex}`}
                      checked={checkBox ? checkBox[optionIndex] : false}
                      onChange={() => {
                        const val = handleOptionClick(optionIndex)
                        setCheckBox(val === optionIndex);
                      }}
                    />
                    <Typography
                      pl={3}
                      sx={{ cursor: "pointer" }}
                      className={isOptionSelected(optionIndex) ? 'selectedOption' : ''}
                    >
                      {option}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* <Typography sx={{ color: "#fff" }}>label: {clickedOptions}</Typography> */}
          <Button
            sx={{ display: "none" }}
            className='previousBtn'
            variant="contained"
            disabled={currentQuestionIndex === 0}
            onClick={handlePreviousQuestion}
          >
            Previous
          </Button>
          <Button
            className='nextBtn'
            variant="contained"
            // disabled={currentQuestionIndex === questions?.QuestionsList?.length - 1}
            onClick={HandleSubmitQuestion}
          >Submit
          </Button>
        </Box>
        {TestNoteBox()}
      </Box>
      <Footer />
    </>
  );
}
