import React from 'react';
import "./style.css";
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import Clogo from "../../Assets/Images/vligtaPro2.png"




export default function Footer() {
  return (
    <>
      <Box className="footer">
        <Box className="footerLogoBox">
          <Box className="companyLogo">
            <Link to={"/"}>
              <img src={Clogo} />
            </Link>
          </Box>
        </Box>
        <Box className="CriteBox">
          <Typography className='footerText'>© Indic Pacific Legal Research LLP. All Rights Reserved.</Typography>
        </Box>
        <Box className="fPolicyBox">
          <Typography className='footerText'>
            <span><a href="https://vligta.app/terms-conditions/" target="_blank"> Terms & Conditions</a></span> |
            <span><a href="https://vligta.app/privacy-policy/" target="_blank"> Privacy Policy </a></span> |
            <span><a href="https://vligta.app/privacy-policy/" target="_blank"> Conact Us</a></span>
          </Typography>
        </Box>
      </Box>
    </>
  )
}
