import Axios from "axios";
import { Notification } from "../App";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const { STATUSES } = require("./Tool3Slice");

const Tool1Slice = createSlice({
  name: "Tool1",
  initialState: {
    data: [],
    status: STATUSES.LOADING,
  },
  reducers: {
    get(state, action) {
      return state.data;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTool1.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchTool1.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchTool1.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { get } = Tool1Slice.actions;
export default Tool1Slice.reducer;

export const fetchTool1 = createAsyncThunk("get/tool1", async () => {
  const res = await fetch(process.env.REACT_APP_BASE_URL + "/tool1");
  const data = await res.json();
  return data;
});

export const UpdateT1Instruction = createAsyncThunk(
  "post/instruction",
  async ({ data, id }) => {
    await Axios.post(
      process.env.REACT_APP_BASE_URL + "/tool1/QuestionBank/update",
      {
        toolId: id,
        newInstruction: data,
      }
    );
  }
);

export const UpdateQuestionBank = createAsyncThunk(
  "update/question bank",
  async (data) => {
    try {
      await Axios.post(
        process.env.REACT_APP_BASE_URL + "/tool1/QuestionBank/updateById",
        { ...data }
      );
      Notification("success", "Question bank updated successfully!");
    } catch (error) {
      Notification("error", "Something wrong happened");
      throw error;
    }
  }
);

export const CreateQuestionBank = createAsyncThunk(
  "create/questionBank",

  async (data) => {
    try {
      await Axios.post(
        process.env.REACT_APP_BASE_URL + "/tool1/QuestionBank/create",
        {
          ...data,
        }
      );
      Notification("success", "Question bank creaded successfully!");
    } catch (error) {
      console.log(error);
      Notification("error", "Something wrong happened");
    }
  }
);
