import Axios from "axios";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const { STATUSES } = require("./Tool3Slice");

const UserSlice = createSlice({
  name: "user",
  initialState: {
    User: [],
    AllUsers: [],
    EventSummery: [],
    CareerSummery: [],
    AdminList: [],
    UserId: "",
    status: STATUSES.IDLE,
    error: null,
  },

  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.User = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.error.message;
      })
      .addCase(postUser.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(postUser.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.User = action.payload;
      })
      .addCase(postUser.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.error.message;
      })

      .addCase(fetchAllUser.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllUser.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.AllUsers = action.payload;
      })
      .addCase(fetchAllUser.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.error.message;
      })

      .addCase(fetchEventSummery.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchEventSummery.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.EventSummery = action.payload;
      })
      .addCase(fetchEventSummery.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.error.message;
      })

      .addCase(fetchCareerSummery.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchCareerSummery.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.CareerSummery = action.payload;
      })
      .addCase(fetchCareerSummery.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.error.message;
      })

      .addCase(getUserId.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getUserId.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.UserId = action.payload;
      })
      .addCase(getUserId.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.error.message;
      })

      .addCase(getAdminList.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getAdminList.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.AdminList = action.payload;
      })
      .addCase(getAdminList.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.error.message;
      });
  },
});

export const { setUser } = UserSlice.actions;
export default UserSlice.reducer;

export const fetchAllUser = createAsyncThunk("fetchAll/users", async () => {
  const response = await Axios.get(
    process.env.REACT_APP_BASE_URL + "/user/all"
  );
  return response.data;
});

export const fetchUser = createAsyncThunk("user/fetchUser", async ({ id }) => {
  console.log("fetch user");
  const response = await Axios.post(process.env.REACT_APP_BASE_URL + "/user", {
    id,
  });
  return response.data;
});

export const postUser = createAsyncThunk("user/postUser", async (userData) => {
  const response = await Axios.post("/api/users", userData);
  return response.data;
});

export const updateToolMarks = createAsyncThunk(
  "tool2/marks",
  async ({
    email,
    TestName,
    LegalFieldArr,
    skillsArr,
    TestScore,
    TestAverage,
    QuestionBankId,
    Tool4QuestionLength,
    questionIndexMarks,
  }) => {
    await Axios.post(
      process.env.REACT_APP_BASE_URL + "/user/update/testScore",
      {
        email,
        TestName,
        LegalFieldArr,
        skillsArr,
        TestScore,
        TestAverage,
        QuestionBankId,
        Tool4QuestionLength,
        questionIndexMarks,
      }
    );
  }
);

export const fetchEventSummery = createAsyncThunk(
  "fetch/eventSummery",
  async () => {
    const response = await Axios.get(
      process.env.REACT_APP_BASE_URL + "/EventSummery/GetAll"
    );
    return response.data;
  }
);

export const createEventSummery = createAsyncThunk(
  "create/eventSummery",
  async ({ name, values }) => {
    await Axios.post(process.env.REACT_APP_BASE_URL + "/EventSummery/Create", {
      name,
      values,
    });
  }
);
export const updateCareerSummery = createAsyncThunk(
  "careerSummery/update",
  async ({ roleId, skillName, careerVal }) => {
    await Axios.post(process.env.REACT_APP_BASE_URL + "/careerSummery/update", {
      roleId,
      skillName,
      careerVal,
    });
  }
);

export const updateEventSummery = createAsyncThunk(
  "update/eventSummery",
  async ({ id, values }) => {
    const response = await Axios.post(
      process.env.REACT_APP_BASE_URL + "/EventSummery/update",
      { id, values }
    );
    return response.data;
  }
);

export const deleteEventSummery = createAsyncThunk(
  "delete/eventSummery",
  async ({ id }) => {
    await Axios.post(process.env.REACT_APP_BASE_URL + "/EventSummery/delete", {
      id,
    });
  }
);

export const createCareerSummery = createAsyncThunk(
  "careerSummery/Create",
  async ({ name, roles }) => {
    await Axios.post(process.env.REACT_APP_BASE_URL + "/careerSummery/Create", {
      name,
      roles,
    });
  }
);

export const fetchCareerSummery = createAsyncThunk(
  "careerSummery/GetAll",
  async () => {
    const response = await Axios.get(
      process.env.REACT_APP_BASE_URL + "/careerSummery/GetAll"
    );
    return response.data;
  }
);

export const createAccessTool = createAsyncThunk(
  "create/accessTools",
  async ({ data }) => {
    await Axios.post(process.env.REACT_APP_BASE_URL + "/user/accessTools", {
      data,
    });
  }
);

export const signUpUser = createAsyncThunk("signUp/user", async (data) => {
  try {
    const response = await Axios.post(
      process.env.REACT_APP_BASE_URL + "/user/Create",
      {
        ...data,
      }
    );
    return response.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
});

export const signInUser = createAsyncThunk("signIn/user", async (data) => {
  try {
    const response = await Axios.post(
      process.env.REACT_APP_BASE_URL + "/user/login",
      {
        ...data,
      }
    );
    return response.data;
  } catch (error) {
    return error?.response?.data?.message;
  }
});

export const verifyOtp = createAsyncThunk("verify/otp", async (data) => {
  await Axios.post(process.env.REACT_APP_BASE_URL + "/user/verify-otp", {
    ...data,
  });
});

export const deleteUser = createAsyncThunk("delete/user", async (data) => {
  await Axios.post(process.env.REACT_APP_BASE_URL + "/user/delete", {
    ...data,
  });
});

export const getUserId = createAsyncThunk("get/userId", async () => {
  try {
    const userId = await localStorage.getItem("userId");
    return userId;
  } catch (error) {
    throw error;
  }
});

export const getAdminList = createAsyncThunk("get/adminList", async () => {
  try {
    const adminList = await Axios.get(
      process.env.REACT_APP_BASE_URL + "/admin/list"
    );
    return adminList.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const deleteAdmin = createAsyncThunk("delete/admin", async (id) => {
  console.log(id);
  try {
    await Axios.post(process.env.REACT_APP_BASE_URL + "/admin/delete", {
      adminId: id,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
});
