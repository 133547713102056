import React, { useState, useEffect } from 'react';
import { Box, Typography, Tab } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useNavigate } from "react-router-dom"
import "./style.css"
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllUser, fetchEventSummery, fetchCareerSummery } from "../../store/UserSlice";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { fetchTool1 } from "../../store/Tool1Slice";
import { fetchTool2 } from "../../store/Tool2Slice";
import { fetchTool3 } from "../../store/Tool3Slice";
import { fetchTool4 } from "../../store/Tool4Slice";


import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

//icons
import UpArrow from "../../Assets/Images/UpArrow.svg"
import DownArrow from "../../Assets/Images/DownArrow.svg"

//Components
import { BlueBtn } from '../../components/Buttons';
import ApexChart from "../../components/PieChart"
import AccessTool1 from '../AccessTools/Tool1';
import AccessTool3 from '../AccessTools/Tool3';


export default function UserList({ selectedQuestionBankT3, currentTool3Marks, setSelectedQuestionBankT3, setCurrentTool3Marks }) {
  const [tabel, setTabel] = useState('1');
  const [AccessToolTabel, setAccessToolTabel] = useState('1');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const AdminLog = localStorage.getItem("login session");
  if (!AdminLog) navigate("/administrator/login");
  const { AllUsers, EventSummery, CareerSummery, status, error } = useSelector((state) => state.user);
  const [selectedUser, setSelectedUser] = useState("");
  const { data } = useSelector((state) => state.tool2);
  const questionBank = data?.data ? data.data[0]?.QuestionsBanks : [];
  let questions = questionBank.length > 0 ? questionBank.find((obj) => obj._id === "64e9c82afb567981c38374ad") : {};
  const [pieChartKey, setPieChartKey] = useState(0)

  const Tool1Marks = selectedUser ? selectedUser?.UserTestScore[0]?.Tool1[0] : []
  const Tool3Marks = selectedUser ? selectedUser?.UserTestScore[0]?.Tool3[0] : []
  const Tool4Marks = selectedUser ? selectedUser?.UserTestScore[0]?.Tool4[0]?.marks[0] : []

  //Tool1
  const Tool1Data = useSelector((state) => state.tool1.data);
  const Tool1QuestionBank = Tool1Data?.data ? Tool1Data.data[0]?.QuestionsBanks : [];
  const [selectedQuestionBankT1, setSelectedQuestionBankT1] = useState()
  const [currentTool1Marks, setCurrentTool1Marks] = useState(1);

  //Tool 2 
  const Tool2LegalField = selectedUser ? selectedUser?.UserTestScore[0]?.Tool2[0]?.legal_fields : [];
  const Tool2Skills = selectedUser ? selectedUser?.UserTestScore[0]?.Tool2[0]?.skills : [];

  let newLegalFieldArray = [];
  let newSkillArray = [];

  //Tool3 
  const Tool3Data = useSelector((state) => state.tool3.data);
  const Tool3QuestionBank = Tool3Data?.data ? Tool3Data.data[0]?.QuestionsBanks : [];




  if (Tool2LegalField || Tool2Skills) {
    const newLegalFieldData = Tool2LegalField.slice().sort((a, b) => b.count - a.count);
    newLegalFieldArray = newLegalFieldData.slice(0, 5).map((item, index) => ({
      id: index,
      value: item.count,
      label: item.name
    }));

    const newSkillData = Tool2Skills.slice().sort((a, b) => b.count - a.count);
    newSkillArray = newSkillData.slice(0, 5).map((item, index) => ({
      id: index,
      value: item.count,
      label: item.name
    }));
  }


  let currentSkillValue = "";
  const getEventSummary = () => {
    const label = newSkillArray[0]?.label;
    let content = null;

    switch (label) {
      case "Problem Solver":
        currentSkillValue = "ProblemSolver";
        content = EventSummery.find((el) => el?.name === "ProblemSolver").values;
        break;
      case "Investigator":
        currentSkillValue = "Investigator";
        content = EventSummery.find((el) => el?.name === "Investigator").values;
        break;
      case "Academic":
        currentSkillValue = "Academic";
        content = EventSummery.find((el) => el?.name === "Academic").values;
        break;
      default:
        break;
    }

    return (
      <div>
        <Typography mb={3} sx={{ color: "#fff" }} variant='h6'>{currentSkillValue}</Typography>
        <Typography sx={{ fontSize: "13px", color: "#ffe19d" }}>{content}</Typography>
      </div>
    );
  };


  const getCareerSummery = (index) => {
    const label = newLegalFieldArray[index]?.label;
    let content = null;
    content = CareerSummery?.filter(el => el?.name === label)
    const FilterCareerSummeryVal = content[0]?.roles[currentSkillValue]
    return (
      <Box mb={3} className='label'>
        <Typography mb={0.5} sx={{ textDecoration: "underLine" }} variant='h6'>{label}</Typography>
        <Typography mb={2} sx={{ fontSize: "13px" }}>{FilterCareerSummeryVal?.text1}</Typography>
        <Typography mb={2} sx={{ fontSize: "13px" }}>{FilterCareerSummeryVal?.text2}</Typography>
        <Typography mb={2} sx={{ fontSize: "13px" }}>{FilterCareerSummeryVal?.text3}</Typography>
      </Box>
    )
  }

  const disableHoverStyles = {
    [`& .${pieArcLabelClasses.root}:hover`]: {
      fill: 'white',
      fontWeight: 'bold',
      pointerEvents: 'none',
    },
  };

  const handleChange = (event, newValue) => {
    setTabel(newValue);
  };
  const handleAccessTabelChange = (event, newValue) => {
    setAccessToolTabel(newValue);
  };
  const handelSelectedUser = (userId) => {
    if (status === "idle") {
      setSelectedUser(AllUsers?.userData.find((usr) => usr._id === userId))
    }
    setPieChartKey((prevKey) => prevKey + 1);
  }

  const getCareerRecommendations = (index) => {
    const label = newLegalFieldArray[index]?.label;
    let content = null;
    content = CareerSummery?.filter(el => el?.name === label)
    const FilterCareerSummeryVal = content[0]?.roles[newSkillArray[0]?.label]
    return (
      <Box mb={3} className='label'>
        <Typography className='toolInfoLabelHeader' sx={{ color: "#FFFFFF" }} mb={2}>{label}</Typography>
        <Typography className='toolInfoLabelText' mb={2} >{FilterCareerSummeryVal?.text1}</Typography>
        <Typography className='toolInfoLabelText' mb={2}>{FilterCareerSummeryVal?.text2}</Typography>
        <Typography className='toolInfoLabelText' mb={2}>{FilterCareerSummeryVal?.text3}</Typography>
      </Box>
    )
  }

  useEffect(() => {
    if (AllUsers?.length === 0) {
      dispatch(fetchAllUser())
      dispatch(fetchEventSummery())
      dispatch(fetchCareerSummery())
    }
    if (data.length === 0) dispatch(fetchTool2())



    if (!EventSummery.length) {
      dispatch(fetchEventSummery())
    }
    if (!CareerSummery.length) {
      dispatch(fetchCareerSummery())
    }
    if (!Tool1Data?.data) {
      dispatch(fetchTool1())
    }
    if (!Tool3Data?.data) {
      dispatch(fetchTool3())
    }
    // if (!Tool4Data?.data) {
    //   dispatch(fetchTool4())
    // }
  })


  return (
    <>
      <Box className="userListContainer">
        <Box mr={3} className="userListBox">
          {
            AllUsers?.userData?.map((user) => (
              <Box onClick={() => handelSelectedUser(user._id)} key={user?._id} className={user._id === selectedUser._id ? "userRow ActiveUser" : "userRow"}>
                <Box className="userImg">
                  <img src={user.picture || "https://ionicframework.com/docs/img/demos/avatar.svg"} />
                </Box>
                <Box className="userName">
                  <Typography>{user?.name}</Typography>
                  <Typography>{user?.email}</Typography>
                </Box>
              </Box>
            ))
          }
        </Box>
        <Box ml={3} className="userInfoBox">
          <TabContext value={tabel}>
            <Box className="AdminNavBox">
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab sx={{ color: "#fff" }} label="Qualifying Tests" value="1" />
                <Tab sx={{ color: "#fff" }} label="VASE | Skill Evaluator" value="2" />
                <Tab sx={{ color: "#fff" }} label="Exercises" value="3" />
                <Tab sx={{ color: "#fff" }} label="Law Persona" value="4" />
                <Tab sx={{ color: "#fff" }} label="Tools Access for users" value="5" />
              </TabList>
            </Box>
            <TabPanel sx={{ width: "100%" }} value="1">
              {/*Qualifying Tests / Tool1  */}

              <Box className="displayMarksInfoBox">
                <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
                  <Box className="toolHeaderbox">
                    <Typography className='toolHeaderText' variant='h5' mb={2}>
                      <span className='Vl'>VLiGTA </span>
                      <span className='Rl'>®</span>
                      <span className='R_l'>Results</span>
                      <span className='Rm_T'>App Qualifying Tests</span>
                    </Typography>
                  </Box>
                </Box>

                <Box className="QuestionBankSelectorBox">
                  <select
                    disabled={!selectedUser}
                    className='QuestionBankSelector'
                    onChange={(e) => {
                      setSelectedQuestionBankT1(Tool1QuestionBank?.find(el => el._id === e.target.value))
                      setCurrentTool1Marks(Tool1Marks?.marks.find((el) => el.questionBankId === e.target.value) || 0);
                    }}
                  >
                    <option value="" disabled selected>Select an QuestionBank</option>
                    {
                      Tool1QuestionBank &&
                      Tool1QuestionBank?.map((el) => (
                        <option key={el?._id} value={el?._id}>{el?.CourseName}</option>
                      ))
                    }
                  </select>
                </Box>

                <Box className="percentageBox">
                  <Box className="qBDetailsTextBox">
                    <Typography className='blueText'>Programme Name: <span className='sLabelValue'>{selectedQuestionBankT1?.CourseName}</span></Typography>
                    <Typography className='blueText'>Programme ID: <span className='sLabelValue'>{selectedQuestionBankT1?.CourseId}</span></Typography>
                    <Typography className='blueText'>Expiry Time: <span className='sLabelValue'></span></Typography>
                    <Typography className='blueText'>Date of access: <span className='sLabelValue'></span></Typography>
                    <Typography className='blueText'>Access expires in: <span className='sLabelValue'></span></Typography>
                    <Typography className='blueText'>Percentage obtained: <span className='sLabelValue'>{currentTool1Marks?.TestScore / selectedQuestionBankT1?.QuestionsList?.length * 100 || 0}%</span></Typography>
                    <Typography className='blueText'>Final Result: <span style={{ fontSize: "20px" }} className='sLabelValue'>{
                      currentTool1Marks?.TestScore / selectedQuestionBankT1?.QuestionsList?.length * 100 >= selectedQuestionBankT1?.CutOffMark ?
                        "PASSED" : "NOT PASSED. Please try again."
                    }</span></Typography>

                  </Box>
                  {
                    currentTool1Marks === 1 ?
                      <Typography className='persentagePlacHSelect Avenir_Black'>User not attempted this test yet !</Typography> :
                      (currentTool1Marks === 0 ?
                        <Typography className='persentagePlacH'>User not attempted this test yet !</Typography>
                        :
                        <Box className="PersentageCircul">
                          <CircularProgressbar
                            value={currentTool1Marks?.TestScore / selectedQuestionBankT1?.QuestionsList?.length * 100 || 0}
                            text={`${currentTool1Marks?.TestScore / selectedQuestionBankT1?.QuestionsList?.length * 100 || 0}%`} />
                        </Box>
                      )
                  }
                </Box>
              </Box>
            </TabPanel>
            <TabPanel sx={{ width: "100%" }} value="2">
              {/*VASE | Skill Evaluator / Tool2  */}

              <Box className="displayMarksInfoBox ">
                <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
                  <Box className="toolHeaderbox toolHeaderboxT2">
                    <Typography className='toolHeaderText' variant='h5' mb={2}>
                      <span className='Vl'>VLiGTA </span>
                      <span className='Rl'>®</span>
                      <span className='R_l'>Evaluation Results</span>
                      <span className='Rm_T'>App Skill Evaluator</span>
                    </Typography>
                  </Box>
                </Box>
                <Box className="Toll2ScoreBox">

                  <Box mr={2} className="Toll2ScoreSubBox">
                    <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h4' mb={2}>Legal Field Interests</Typography>
                    {
                      newLegalFieldArray?.map((el, index) => (
                        <Typography className='toolInfoLabelText' mb={1} key={index}>{el?.label} - {((el?.value / questions?.QuestionsList?.length) * 100).toFixed(0)}%</Typography>
                      ))
                    }
                  </Box>
                  <Box sx={{ borderLeft: "1px solid", paddingLeft: "10px" }} className="Toll2ScoreSubBox">
                    <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h4' mb={2}>Skill Archetypes</Typography>
                    {
                      newSkillArray?.map((el, index) => (
                        <Typography className='toolInfoLabelText' mb={1} key={index}>{el?.label} - {((el?.value / questions?.QuestionsList?.length) * 100).toFixed(0)}%</Typography>
                      ))
                    }
                  </Box>
                </Box>
                <Box key={pieChartKey} className="chartBox">
                  <Box>
                    <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h6'>Legal Fields</Typography>
                    <ApexChart questionLength={questions?.QuestionsList?.length} val={newLegalFieldArray} />
                  </Box>
                  <Box>
                    <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h6'>Skills</Typography>
                    <ApexChart questionLength={questions?.QuestionsList?.length} val={newSkillArray} />
                  </Box>
                </Box>

                <Box mb={2} className="displayMarksInfoBox">
                  <Box mb={8} className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
                    <Box className="toolHeaderbox">
                      <Typography className='toolHeaderText' variant='h5' mb={2}>
                        <span className='Vl'>VLiGTA </span>
                        <span className='Rl'>®</span>
                        <span className='R_l'>Skill Archetypes</span>
                        <span className='Rm_T'>App Skill Evaluator</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box>{getEventSummary()}</Box>
                </Box>

                <Box mb={3} className="displayMarksInfoBox">

                  <Box mb={8} className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
                    <Box className="toolHeaderbox">
                      <Typography className='toolHeaderText' variant='h5' mb={2}>
                        <span className='Vl'>VLiGTA </span>
                        <span className='Rl'>®</span>
                        <span className='R_l'>Career Recommendations</span>
                        <span className='Rm_T'>App Skill Evaluator</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    {getCareerRecommendations(0)}
                    {getCareerRecommendations(1)}
                    {getCareerRecommendations(2)}
                    {getCareerRecommendations(3)}
                    {getCareerRecommendations(4)}
                  </Box>
                </Box>
              </Box>
            </TabPanel>


            <TabPanel sx={{ width: "100%" }} value="3">
              {/*Exercises / Tool3  */}
              <Box className="displayMarksInfoBox">
                <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
                  <Box className="toolHeaderbox toolHeaderboxT2">
                    <Typography className='toolHeaderText' variant='h5' mb={2}>
                      <span className='Vl'>VLiGTA </span>
                      <span className='Rl'>®</span>
                      <span className='R_l'>Test Results</span>
                      <span className='Rm_T'>Exercise</span>
                    </Typography>
                  </Box>
                </Box>
                <Box className="QuestionBankSelectorBox">
                  <select
                    disabled={!selectedUser}
                    className='QuestionBankSelector'
                    onChange={(e) => {
                      setSelectedQuestionBankT3(Tool3QuestionBank?.find(el => el._id === e.target.value))
                      setCurrentTool3Marks(Tool3Marks?.marks.find((el) => el.questionBankId === e.target.value) || 0)
                    }}
                  >
                    <option value="" disabled selected>Select an QuestionBank</option>
                    {Tool3QuestionBank &&
                      Tool3QuestionBank.map(el => (
                        <option key={el?._id} value={el?._id}>{el?.CourseName}</option>
                      ))
                    }
                  </select>
                </Box>
                <Box className="percentageBox">
                  <Box className="qBDetailsTextBox">
                    <Typography className='blueText'>Programme Name: <span className='sLabelValue'>{selectedQuestionBankT3?.CourseName}</span></Typography>
                    <Typography className='blueText'>Programme ID: <span className='sLabelValue'>{selectedQuestionBankT3?.CourseId}</span></Typography>
                    <Typography className='blueText'>Efficiency Score: <span className='sLabelValue'>{currentTool3Marks?.TestScore / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}%</span></Typography>
                  </Box>
                  {
                    currentTool3Marks === 1 ?
                      <Typography className='persentagePlacHSelect Avenir_Black'>User not attempted this test yet !</Typography> :
                      (currentTool3Marks === 0 ?
                        <Typography className='persentagePlacH'>User not attempted this test yet !</Typography>
                        :
                        <Box className="tool3PieBox PersentageCircul">
                          <CircularProgressbar
                            value={currentTool3Marks?.TestScore / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}
                            text={`${currentTool3Marks?.TestScore / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}%`} />
                        </Box>
                      )
                  }
                </Box>

                <Box className="efficiencyBox">
                  <Typography className='Avenir_Black'> Efficiency Levels</Typography>

                  <Box className="efficInnerBox">
                    <Box className="efficInnerItemBox">
                      <Box>
                        <Typography className='efficScoreLabel'>Developing Efficiency approx. 25%</Typography>
                        <Typography className='efficScore'>
                          {currentTool3Marks?.questionIndexMarks?.marks1 / (selectedQuestionBankT3?.QuestionsList?.length * 1) * 100 || 0}%
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className='efficScoreLabel'>Standard Efficiency approx. 50%</Typography>
                        <Typography className='efficScore'>
                          {currentTool3Marks?.questionIndexMarks?.marks2 / (selectedQuestionBankT3?.QuestionsList?.length * 2) * 100 || 0}%
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="efficInnerItemBox">
                      <Box>
                        <Typography className='efficScoreLabel'>Advanced Efficiency approx. 75%</Typography>
                        <Typography className='efficScore'>
                          {currentTool3Marks?.questionIndexMarks?.marks3 / (selectedQuestionBankT3?.QuestionsList?.length * 3) * 100 || 0}%
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className='efficScoreLabel'>Peak Efficiency approx. 100%</Typography>
                        <Typography className='efficScore'>
                          {currentTool3Marks?.questionIndexMarks?.marks4 / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel sx={{ width: "100%" }} value="4">
              {/*Law Persona / Tool4  */}
              <Box className="displayMarksInfoBox">
                <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
                  <Box className="toolHeaderbox">
                    <Typography className='toolHeaderText' variant='h5' mb={2}>
                      <span className='Vl'>VLiGTA </span>
                      <span className='Rl'>®</span>
                      <span className='R_l'>Evaluation Results</span>
                      <span className='Rm_T'>App Law Persona</span>
                    </Typography>
                  </Box>
                </Box>
                <Box className="percentageBox">
                  <Box className="qBDetailsTextBox">
                    <Typography className='blueText'>Efficiency Score: <span className='sLabelValue'>{(Tool4Marks?.TestScore / (Tool4Marks?.Tool4QuestionLength * 4) * 100 || 0).toFixed(0)}%</span></Typography>
                  </Box>
                  <Box className="tool3PieBox PersentageCircul">
                    <CircularProgressbar
                      value={(Tool4Marks?.TestScore / (Tool4Marks?.Tool4QuestionLength * 4) * 100 || 0).toFixed(0)}
                      text={`${(Tool4Marks?.TestScore / (Tool4Marks?.Tool4QuestionLength * 4) * 100 || 0).toFixed(0)}%`} />
                  </Box>


                </Box>

                <Box className="efficiencyBox">
                  <Typography className='Avenir_Black'> Efficiency Levels</Typography>

                  <Box className="efficInnerBox">
                    <Box className="efficInnerItemBox">
                      <Box>
                        <Typography className='efficScoreLabel'>Developing Efficiency approx. 25%</Typography>
                        <Typography className='efficScore'>
                          {(Tool4Marks?.questionIndexMarks?.marks1 / (Tool4Marks?.Tool4QuestionLength * 1) * 100).toFixed(0) || 0}%
                        </Typography>

                      </Box>
                      <Box>
                        <Typography className='efficScoreLabel'>Standard Efficiency approx. 50%</Typography>
                        <Typography className='efficScore'>
                          {(Tool4Marks?.questionIndexMarks?.marks2 / (Tool4Marks?.Tool4QuestionLength * 2) * 100).toFixed(0) || 0}%
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="efficInnerItemBox">
                      <Box>
                        <Typography className='efficScoreLabel'>Advanced Efficiency approx. 75%</Typography>
                        <Typography className='efficScore'>
                          {(Tool4Marks?.questionIndexMarks?.marks3 / (Tool4Marks?.Tool4QuestionLength * 3) * 100).toFixed(0) || 0}%
                        </Typography>

                      </Box>
                      <Box>
                        <Typography className='efficScoreLabel'>Peak Efficiency approx. 100%</Typography>
                        <Typography className='efficScore'>
                          {(Tool4Marks?.questionIndexMarks?.marks4 / (Tool4Marks?.Tool4QuestionLength * 4) * 100).toFixed(0) || 0}%
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TabPanel>

            <TabPanel sx={{ width: "100%" }} value="5">
              {/* access Tools */}

              <TabContext value={AccessToolTabel}>
                <Box className="AdminNavBox">
                  <TabList onChange={handleAccessTabelChange} aria-label="lab API tabs example">
                    <Tab sx={{ color: "#fff" }} label="Qualifying Tests" value="1" />
                    <Tab sx={{ color: "#fff" }} label="Exercises" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1"><AccessTool1 selectedUser={selectedUser} /></TabPanel>
                <TabPanel value="2"><AccessTool3 selectedUser={selectedUser} /></TabPanel>
              </TabContext>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  )
}

