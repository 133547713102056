import React, { useState } from "react";
import { Box, Typography, Button, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import "./style.css";

//Components
import Tool1 from "./Tool1";
import Tool2 from "./Tool2";
import Tool3 from "./Tool3";
import Tool4 from "./Tool4";
import Footer from "../../components/Footer";

export default function QuestionHome() {
  const navigate = useNavigate();
  const [value, setValue] = useState("3");
  const AdminLog = localStorage.getItem("login session");
  if (!AdminLog) navigate("/administrator/login");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className="homeBoxContainer">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              className="questionNavBer"
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab sx={{ color: "#fff" }} label="Tool 1" value="1" />
              <Tab sx={{ color: "#fff" }} label="Tool 2" value="2" />
              <Tab sx={{ color: "#fff" }} label="Tool 3" value="3" />
              <Tab sx={{ color: "#fff" }} label="Tool 4" value="4" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Tool1 />
          </TabPanel>

          <TabPanel value="2">
            <Tool2 />
          </TabPanel>

          <TabPanel value="3">
            <Tool3 />
          </TabPanel>
          <TabPanel value="4">
            <Tool4 />
          </TabPanel>
        </TabContext>
      </Box>
      <Footer />
    </>
  );
}
