import React from 'react';
import { Box, Typography, Button, Tab } from '@mui/material'
import { useNavigate } from "react-router-dom"
import { TabContext, TabList, TabPanel } from '@mui/lab';
import "./home.css";

//Component
import AdminProfile from "../AdminProfile";
import QuestionDetails from '../QuestionDetails/Tool3';
import QuestionHome from '../QuestionDetails/QuestionHome';
import UserList from '../UserList';
import Summery from '../Summery';
import Instruction from "../Instruction"

export default function Home({ selectedQuestionBankT3, currentTool3Marks, setSelectedQuestionBankT3, setCurrentTool3Marks }) {
  const navigate = useNavigate()
  const logData = JSON.parse(localStorage.getItem("login session"))
  if (!logData) navigate("/administrator/login");
  const [value, setValue] = React.useState('2');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handelLogOut = () => {
    localStorage.removeItem("login session")
    window.location.reload()
  }

  return (
    <>
      <Box className="homeContainer">
        <TabContext value={value}>
          <Box className="AdminNavBox">
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab sx={{ color: "#fff" }} label="Tools" value="1" />
              <Tab sx={{ color: "#fff" }} label="Admin profile" value="2" />
              <Tab sx={{ color: "#fff" }} label="Users" value="3" />
              <Tab sx={{ color: "#fff" }} label="Legal Fields & Skill Archetypes" value="4" />
              <Tab sx={{ color: "#fff" }} label="Test Instruction" value="5" />
            </TabList>
            {logData ?
              <Box className="adminNameBox">
                <samp className='adminNavName'>{logData?.name.toUpperCase()}</samp>
                <p className='logBtn' onClick={handelLogOut}>LogOut</p>
              </Box>
              :
              <p className='logBtn' onClick={() => navigate("/administrator/login")}>LogIn</p>
            }
          </Box>
          <TabPanel value="1"><QuestionHome /></TabPanel>
          <TabPanel value="2"><AdminProfile /></TabPanel>
          <TabPanel value="3"><UserList
            selectedQuestionBankT3={selectedQuestionBankT3}
            currentTool3Marks={currentTool3Marks}
            setSelectedQuestionBankT3={setSelectedQuestionBankT3}
            setCurrentTool3Marks={setCurrentTool3Marks}
          /></TabPanel>
          <TabPanel value="4"><Summery /></TabPanel>
          <TabPanel value="5"><Instruction /></TabPanel>

        </TabContext>

      </Box>


    </>
  )
}
