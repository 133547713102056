import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { Box, Typography } from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { downloadPDF } from "../../../components/PDFdownloader"

import "./style.css"

//images
import Clogo from "../../../Assets/Images/vligtaApp.png"
import sign from "../../../Assets/Images/abhi sign vligta app.png"
import stImg from "../../../Assets/Images/fxhjcg.png"
import Footer from '../../../components/Footer';

export default function Tool1Certificate({ crtUserName, crtCorseName, crtCorseId, crtCorseMentor, keySkills }) {
  const navigate = useNavigate()
  const currentDate = new Date()

  useEffect(() => {
    if (!crtUserName) {
      navigate("/user/profile")
    }
  })


  return (
    <>
      <Box id="certificatePageT1" className="CrtContainer">
        <Box onClick={() => downloadPDF("certificatePageT1")} className="PrintBtn">
          <SimCardDownloadIcon sx={{ color: "#fff" }} />
          <Typography>as PDF</Typography>
        </Box>
        <Box className="crtInnerContainer">
          <img onClick={() => navigate("/user/profile")} className='Clogo' src={Clogo} />
          <Typography mt={1} className='crtHeacer Avenir_Black'>Certificate of Completion</Typography>
          <Typography mb={2} className='crtSubText coursName'>{crtCorseName || ""}</Typography>
          <Typography mb={1} className='crtNtext'>Training Programme completed by {crtUserName || ""} </Typography>
          <Typography mb={1} className='crtNtext CISText'>Certified by the Indian Society of Artificial Intelligence and Law</Typography>
          <Typography mb={1} className='crtNtext LTime'>{currentDate.toString()}</Typography>
          <Typography mb={1} className='crtNtext LTime'> Duration: 4 month </Typography>
          <Typography mb={1} className='crtNtext'>Test Completion Time: 45 mins</Typography>
          <Typography mb={1} className='crtNtext Avenir_Black'>Key Skills Acquired</Typography>

          <Typography mb={1} className='crtNtext'>
            <Box className="skillOuterBox">
              {
                keySkills?.map((el, i) => (
                  <Box key={i} className="skillBox">
                    <span >{el} </span>
                  </Box>
                ))
              }
            </Box>
          </Typography>

          <Typography mb={1} className='crtNtext Avenir_Black'>Course Mentors</Typography>
          <Typography mb={2} className='crtNtext'>{crtCorseMentor}</Typography>
          <Box className="signBox"><img src={sign} /></Box>
          <Typography mb={1} className='crtNtext'>Abhivardhan</Typography>
          <Typography className='crtNtext'>Managing Partner, Indic Pacific Legal Research LLP</Typography>
          <Typography mt={3} className="crtSNText">Certificate ID: <span className='crtSubSText'>{crtCorseId || ""}</span></Typography>
          <img className='stImg' src={stImg} />
        </Box>
      </Box>
      {/* <Footer /> */}

    </>
  )
}
