import React, { useState } from 'react'
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import Clogo from "../../Assets/Images/vligtaPro2.png"
import { useSelector } from 'react-redux';

import "./style.css"

// import Clogo from "../../Assets/Images/cLogo.png"
import PersonIcon from '@mui/icons-material/Person';
import { BlueBtn } from '../Buttons';

export default function ClientNavBar() {
  const navigate = useNavigate();
  const { User, status, UserId } = useSelector((state) => state.user);
  const userLog = localStorage.getItem("userString")
  const [logBtn, setLogBtn] = useState(userLog ? true : false)

  const handelLogOut = () => {
    setLogBtn(!logBtn)
    if (logBtn) {
      localStorage.removeItem("userString")
      localStorage.removeItem("userId")
      navigate("/user/signUp")

    } else {
      navigate("/user/signUp")
    }
  }




  return (
    <>
      <Box className="clientNavBar">
        <Box className="companyLogo">
          <Link to={"/"}>
            <img src={Clogo} />
          </Link>
        </Box>

        <Box className="NavItem Profile">
          <Link to={"/"}>
            <Typography className='NavItemText '>Home</Typography>
          </Link>
        </Box>
        <Box className="NavItem Profile">
          <Link to={"/user/profile"}>
            <Typography className='NavItemText'>Dashboard</Typography>
          </Link>
        </Box>
        <Box className="NavItem profileNameBox">
          <PersonIcon sx={{ color: "#fff" }} />
          <Typography className='NavItemText'>{User?.userData?.name}</Typography>
        </Box>

        <Box className="NavItem">
          {
            logBtn ?
              <BlueBtn buttonText="Log Out" onClick={handelLogOut} /> :
              <BlueBtn buttonText="Log In" onClick={handelLogOut} />
          }
        </Box>
      </Box>
    </>
  )
}
