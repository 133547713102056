import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { updateToolMarks, getUserId, fetchUser } from "../../../store/UserSlice"
import { fetchTool1 } from "../../../store/Tool1Slice";
import { useNavigate, useLocation } from 'react-router-dom';

import "./style.css";

//Component
import NavBar from "../../../components/ClientNavBar"
import Footer from '../../../components/Footer';
import { BlueBtn } from '../../../components/Buttons';
import { TestNoteBox } from "../Tool3QuestionSet"



export default function Tool1QuestionSet() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsQuestionBankId = searchParams.get("Qid");
  const navigate = useNavigate();
  const userLog = localStorage.getItem("userString");
  const dispatch = useDispatch();
  const { User, UserId } = useSelector((state) => state.user);
  const { data, status } = useSelector((state) => state.tool1);
  const questionBank = data?.data ? data.data[0]?.QuestionsBanks : [];
  let questions = questionBank.length > 0 ? questionBank.find((obj) => obj._id === paramsQuestionBankId) : {};
  const [marks, setMarks] = useState(0)
  const [currentChosenOption, setCurrentChosenOption] = useState("")
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [optionIndex, setOptionIndex] = useState()

  const handleSubmitQuestion = () => {
    if (!userLog) navigate("/")
    if (currentQuestionIndex < questions?.QuestionsList?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    const rightAnswer = questions?.QuestionsList[currentQuestionIndex]?.Answer;
    if (rightAnswer.trim().toUpperCase() === currentChosenOption.trim().toUpperCase()) {
      setMarks(marks + 1)
    }

    if (currentQuestionIndex === questions?.QuestionsList?.length - 1) {
      if (userLog) {
        dispatch(updateToolMarks({
          email: User?.userData?.email,
          TestName: "Tool1",
          TestScore: marks,
          TestAverage: marks / questions.QuestionsList?.length * 1 * 100,
          QuestionBankId: questions._id
        }))
      } else {
        navigate("/")
      }
      navigate("/user/profile")
    }
    setOptionIndex("")
  }


  useEffect(() => {
    if (!UserId) {
      dispatch(getUserId());
    }
    if (!User.length && UserId) {
      dispatch(fetchUser({ id: UserId }));
    }
    if (data.length === 0) dispatch(fetchTool1())

  }, [UserId, User.length])

  return (
    <>
      <NavBar />

      <Box className="Tool2QuestionSetContainer">
        <Box className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
          <Box className="toolHeaderbox">
            <Typography className='toolHeaderText' variant='h5' mb={2}>
              <span className='Vl'>VLiGTA </span>
              <span className='Rl'>®</span>
              <span className='R_l'>Choose the correct option</span>
              <span className='Rm_T'>App Qualifying Tests</span>
            </Typography>
          </Box>
        </Box>

        <Box className="ToolQuestionBox">

          {questions?.QuestionsList && (
            <>
              <Typography className='Tool2Question' ><span className='questionIndexLabel Avenir_Black'> Question {currentQuestionIndex + 1}</span> <br /> {questions.QuestionsList[currentQuestionIndex]?.Question}</Typography>

              <Box className={optionIndex === "A" ? "T1options_boxItem OptionTextActive" : "T1options_boxItem"}>
                <Typography className='TextColor_p' variant='h5'>A</Typography>
                <Typography
                  onClick={(e) => {
                    setOptionIndex('A')
                    setCurrentChosenOption(questions?.QuestionsList[currentQuestionIndex]?.Options[0])
                  }}
                  className='OptionText tool2Options' variant='h5'>{questions?.QuestionsList[currentQuestionIndex]?.Options[0]}</Typography>
              </Box>

              <Box className={optionIndex === "B" ? "T1options_boxItem OptionTextActive" : "T1options_boxItem"}>
                <Typography className='TextColor_p' variant='h5'>B</Typography>
                <Typography
                  onClick={(e) => {
                    setOptionIndex('B')
                    setCurrentChosenOption(questions?.QuestionsList[currentQuestionIndex]?.Options[1])
                  }}
                  className='OptionText tool2Options' variant='h5'>{questions?.QuestionsList[currentQuestionIndex]?.Options[1]}</Typography>
              </Box>
              <Box className={optionIndex === "C" ? "T1options_boxItem OptionTextActive" : "T1options_boxItem"}>
                <Typography className='TextColor_p' variant='h5'>C</Typography>
                <Typography
                  onClick={(e) => {
                    setOptionIndex('C')
                    setCurrentChosenOption(questions?.QuestionsList[currentQuestionIndex]?.Options[2])
                  }}
                  className='OptionText tool2Options' variant='h5'>{questions?.QuestionsList[currentQuestionIndex]?.Options[2]}</Typography>
              </Box>
              <Box className={optionIndex === "D" ? "T1options_boxItem OptionTextActive" : "T1options_boxItem"}>
                <Typography className='TextColor_p' variant='h5'>D</Typography>
                <Typography
                  onClick={(e) => {
                    setOptionIndex('D')
                    setCurrentChosenOption(questions?.QuestionsList[currentQuestionIndex]?.Options[3])
                  }}
                  className='OptionText tool2Options' variant='h5'>{questions?.QuestionsList[currentQuestionIndex]?.Options[3]}</Typography>
              </Box>
            </>
          )}
        </Box>
        <BlueBtn buttonText="Submit" onClick={handleSubmitQuestion} />
        {TestNoteBox()}
      </Box>
      <Footer />
    </>
  )
}
