import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Typography } from '@mui/material';
import { downloadPDF } from "../../../components/PDFdownloader";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useSelector, useDispatch } from 'react-redux';
import { BlueBtn } from '../../../components/Buttons';
import { fetchTool2 } from "../../../store/Tool2Slice";


import ApexChart from "../../../components/PieChart"
import "../Tool1/style.css";
import "./style.css";

//images
import Clogo from "../../../Assets/Images/vligtaApp.png"
import Footer from '../../../components/Footer';

export default function Tool2Certificate({ crtUserName }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CurrentTime = new Date();
  const TimeStamp = CurrentTime.toISOString();
  const [instructionPop, setIntrcutionPop] = useState(false);
  const { EventSummery, CareerSummery, User, status } = useSelector((state) => state.user);


  let newLegalFieldArray = [];
  let newSkillArray = [];

  const Tool2Data = useSelector((state) => state.tool2.data);
  const questionBank = Tool2Data?.data ? Tool2Data.data[0]?.QuestionsBanks : [];
  let questions = questionBank.length > 0 ? questionBank.find((obj) => obj._id === "64e9c82afb567981c38374ad") : {};
  const Tool2LegalField = User?.userData?.UserTestScore[0]?.Tool2[0]?.legal_fields;
  const Tool2Skills = User?.userData?.UserTestScore[0]?.Tool2[0]?.skills



  if (Tool2LegalField || Tool2Skills) {
    const newLegalFieldData = Tool2LegalField.slice().sort((a, b) => b.count - a.count);
    newLegalFieldArray = newLegalFieldData.slice(0, 5).map((item, index) => ({
      id: index,
      value: item.count,
      label: item.name
    }));

    const newSkillData = Tool2Skills.slice().sort((a, b) => b.count - a.count);
    newSkillArray = newSkillData.slice(0, 5).map((item, index) => ({
      id: index,
      value: item.count,
      label: item.name
    }));
  }

  let currentSkillValue = "";
  const getEventSummary = () => {
    const label = newSkillArray[0]?.label;
    let content = null;

    switch (label) {
      case "Problem Solver":
        currentSkillValue = "ProblemSolver";
        content = EventSummery.find((el) => el?.name === "ProblemSolver").values;
        break;
      case "Investigator":
        currentSkillValue = "Investigator";
        content = EventSummery.find((el) => el?.name === "Investigator").values;
        break;
      case "Academic":
        currentSkillValue = "Academic";
        content = EventSummery.find((el) => el?.name === "Academic").values;
        break;
      default:
        break;
    }
    return (
      <div>
        <Typography sx={{ color: "#FFFFFF" }} mb={2} variant='h4'>{currentSkillValue}</Typography>
        <Typography className='toolInfoLabelText'>{content}</Typography>
      </div>
    )
  };

  const getCareerRecommendations = (index) => {
    const label = newLegalFieldArray[index]?.label;
    let content = null;
    content = CareerSummery?.filter(el => el?.name === label)
    const FilterCareerSummeryVal = content[0]?.roles[newSkillArray[0]?.label]
    return (
      <Box mb={3} className='label'>
        <Typography className='toolInfoLabelHeader' sx={{ color: "#FFFFFF" }} mb={2}>{label}</Typography>
        <Typography className='toolInfoLabelText' mb={2} >{FilterCareerSummeryVal?.text1}</Typography>
        <Typography className='toolInfoLabelText' mb={2}>{FilterCareerSummeryVal?.text2}</Typography>
        <Typography className='toolInfoLabelText' mb={2}>{FilterCareerSummeryVal?.text3}</Typography>
      </Box>
    )
  }

  useEffect(() => {
    if (!questionBank.length) {
      dispatch(fetchTool2())
    }
    if (!crtUserName) {
      navigate("/user/profile")
    }
  })

  return (
    <>
      <Box id="certificatePageT2" className="CrtContainer">
        <Box onClick={() => downloadPDF("certificatePageT2")} className="PrintBtn">
          <SimCardDownloadIcon sx={{ color: "#fff" }} />
          <Typography>as PDF</Typography>
        </Box>
        <Box className="crtInnerContainer T2CC">
          <img onClick={() => navigate("/user/profile")} className='Clogo' src={Clogo} />
          <Box className="t2CcontaintBox">

            <Box mb={2} className="toolHeaderUpbox" sx={{ width: "100%" }}>
              <Box className="toolHeaderbox toolHeaderboxT2">
                <Typography className='toolHeaderText' variant='h5' mb={2}>
                  <span className='Vl'>VLiGTA </span>
                  <span className='Rl'>®</span>
                  <span className='R_l'>Evaluation Results</span>
                  <span className='Rm_T'>App Skill Evaluator</span>
                </Typography>
              </Box>
            </Box>
            <Box mb={5} sx={{ width: "100%" }}>
              <Typography className='T2cSubHeader' sx={{ textAlign: "start" }}>{crtUserName}, thanks for trying VASE. Here are the results.</Typography>
            </Box>
            <Box className="Toll2ScoreBox">

              <Box mr={2} sx={{ borderRight: "1px solid" }} className="Toll2ScoreSubBox">
                <Typography sx={{ color: "#fff" }} variant='h4' mb={2}>Legal Field Interests</Typography>
                {
                  newLegalFieldArray?.map((el, index) => (
                    <Typography className='t2cLabelText' mb={1} key={index}>{el?.label} - {((el?.value / questions?.QuestionsList?.length) * 100).toFixed(1)}%</Typography>
                  ))
                }
              </Box>
              <Box sx={{ paddingLeft: "10px" }} className="Toll2ScoreSubBox">
                <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h4' mb={2}>Skill Archetypes</Typography>
                {
                  newSkillArray?.map((el, index) => (
                    <Typography className='t2cLabelText' mb={1} key={index}>{el?.label} - {((el?.value / questions?.QuestionsList?.length) * 100).toFixed(0)}%</Typography>
                  ))
                }
              </Box>
            </Box>
            <Box className="chartBox t2ChartBox">
              <Box>
                <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h6'>Legal Fields</Typography>
                <ApexChart questionLength={questions?.QuestionsList?.length} val={newLegalFieldArray} />
              </Box>
              <Box>
                <Typography className='Avenir_Black' sx={{ color: "#fff" }} variant='h6'>Skills</Typography>
                <ApexChart questionLength={questions?.QuestionsList?.length} val={newSkillArray} />
              </Box>
            </Box>
          </Box>

          <Box mb={2} className="displayMarksInfoBox">
            <Box mb={8} className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
              <Box className="toolHeaderbox">
                <Typography className='toolHeaderText' variant='h5' mb={2}>
                  <span className='Vl'>VLiGTA </span>
                  <span className='Rl'>®</span>
                  <span className='R_l'>Skill Archetypes</span>
                  <span className='Rm_T'>App Skill Evaluator</span>
                </Typography>
              </Box>
            </Box>
            <Box>{getEventSummary()}</Box>
          </Box>


          <Box mb={3} className="displayMarksInfoBox">

            <Box mb={8} className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
              <Box className="toolHeaderbox">
                <Typography className='toolHeaderText' variant='h5' mb={2}>
                  <span className='Vl'>VLiGTA </span>
                  <span className='Rl'>®</span>
                  <span className='R_l'>Career Recommendations</span>
                  <span className='Rm_T'>App Skill Evaluator</span>
                </Typography>
              </Box>
            </Box>

            <Box sx={{ width: "100%" }}>
              {getCareerRecommendations(0)}
              {getCareerRecommendations(1)}
              {getCareerRecommendations(2)}
              {getCareerRecommendations(3)}
              {getCareerRecommendations(4)}
            </Box>
          </Box>

          <Typography mb={1} className='crtNtext'>
            We hope the VLiGTA Skill Evaluator could help. Please feel free to give your feedback on the VLiGTA Skill Evaluator here.  </Typography>
          <Typography>Regards</Typography>
          <Typography>The VLiGTA App Team.</Typography>

          <Box className="CopyrightBox">
            <Typography>Copyright © Indic Pacific Legal Research LLP.</Typography>
            <Typography>All rights reserved. This test and any related materials, including instructions, scoring guides, and report templates, are protected by copyright law. No part of the test or materials may be reproduced, distributed, or transmitted in any form or by any means, including photocopying, recording, or electronic methods, without the express written permission of the copyright owner.</Typography>
            <Typography>The results of this test are confidential and are intended solely for the use of the individual or organization administering the test. However, test-takers are permitted to share their test results on social media platforms for personal or non-commercial purposes, provided that they do not modify or alter the results in any way.</Typography>
            <Typography>By using this test, you agree to be bound by these terms and conditions of use of the VLiGTA® App at <a href="https://vligta.app/terms-conditions" target='blank'> vligta.app/terms-conditions </a>
              and of Indic Pacific Legal Research LLP at <a href="https://indicpacific.com/guidelines" target='blank'> indicpacific.com/guidelines </a>.
              Any violation of these terms may result in legal action.</Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  )
}
