import { Typography, Button } from "@mui/material";
import "./style.css";

export const BlueBtn = ({
  buttonText,
  width,
  onClick,
  disabled,
  id,
  btnColor,
}) => {
  return (
    <Button
      id={id}
      onClick={onClick}
      disabled={disabled}
      sx={{
        width: width,
        backgroundColor: btnColor || "#FFE19D",
        fontWeight: "600",
        color: "#000",
      }}
      variant="contained"
    >
      {buttonText}
    </Button>
  );
};
export const RedBtn = ({ buttonText, onClick }) => {
  return (
    <Button
      variant="outlined"
      sx={{
        border: "#FF2458 1px solid",
        color: "#FF2458",
      }}
      onClick={onClick}
    >
      <Typography>{buttonText}</Typography>
    </Button>
  );
};
