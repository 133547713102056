import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import { Auth0Provider } from "@auth0/auth0-react";
import "./style.css";

ReactDOM.createRoot(document.querySelector("#root")).render(
  <Provider store={store}>
    <Auth0Provider
      domain="dev-wtc8s3e80v5t1jcb.us.auth0.com"
      clientId="6rK8Y8lhLxIRhEsaFiBqO2lDgDdKgOPB"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </Provider>
);
