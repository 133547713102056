import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Box, Typography } from '@mui/material';
import { CircularProgressbar } from "react-circular-progressbar";
import Clogo from "../../../Assets/Images/vligtaApp.png";
import { downloadPDF } from "../../../components/PDFdownloader";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import "./style.css";

import Footer from '../../../components/Footer';

export default function Tool3Certificate({ selectedQuestionBankT3, currentTool3Marks, crtUserName, keySkills }) {
  const navigate = useNavigate();


  useEffect(() => {
    if (!crtUserName) {
      navigate("/user/profile")
    }
  })
  return (
    <>
      <Box id="certificatePageT2" className="CrtContainer T3cMainContainer">
        <Box onClick={() => downloadPDF("certificatePageT2")} className="PrintBtn">
          <SimCardDownloadIcon sx={{ color: "#fff" }} />
          <Typography>as PDF</Typography>
        </Box>
        <Box className="t3cInnerBox">
          <img onClick={() => navigate("/user/profile")} className='Clogo' src={Clogo} />
          <Box className="t2CcontaintBox">
            <Box className="toolHeaderUpbox" sx={{ width: "100%" }}>
              <Box className="toolHeaderbox toolHeaderboxT2">
                <Typography className='toolHeaderText' variant='h5' mb={2}>
                  <span className='Vl'>VLiGTA </span>
                  <span className='Rl'>®</span>
                  <span className='R_l'>Test Results</span>
                  <span className='Rm_T'>Exercise</span>
                </Typography>
              </Box>
            </Box>
            <Box mb={5} sx={{ width: "100%" }}>
              <Typography className='crtUserName' sx={{ textAlign: "start" }}>{crtUserName}, thanks for trying this Exercise. Here are the results.</Typography>
            </Box>
            <Box className="percentageBox">
              <Box className="qBDetailsTextBox">
                <Typography className='blueText'>Programme Name: <span className='sLabelValue'>{selectedQuestionBankT3?.CourseName}</span></Typography>
                <Typography className='blueText'>Programme ID: <span className='sLabelValue'>{selectedQuestionBankT3?.CourseId}</span></Typography>
                <Typography className='blueText'>Efficiency Score:
                  <span className='sLabelValue'>{currentTool3Marks?.TestScore / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}%</span>
                </Typography>
                <Typography mb={1} className='blueText'>
                  Key Skills:
                  <Box className="skillOuterBox">
                    {
                      keySkills?.map((el, i) => (
                        <Box key={i} className="skillBox">
                          <span >{el} </span>
                        </Box>
                      ))
                    }
                  </Box>
                </Typography>

              </Box>
              <Box className="tool3PieBox PersentageCircul">
                <CircularProgressbar
                  value={currentTool3Marks?.TestScore / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}
                  text={`${currentTool3Marks?.TestScore / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100 || 0}%`} />
              </Box>
            </Box>
            <Box className="efficiencyBox">
              <Typography className='Avenir_Black'> Efficiency Levels</Typography>

              <Box className="efficInnerBox t3cTabel">
                <Box className="efficInnerItemBox">
                  <Box>
                    <Typography className='efficScoreLabel'>Developing Efficiency approx. 25%</Typography>
                    <Typography className='efficScore'>
                      {currentTool3Marks?.questionIndexMarks?.marks1 / (selectedQuestionBankT3?.QuestionsList?.length * 1) * 100}%
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className='efficScoreLabel'>Standard Efficiency approx. 50%</Typography>
                    <Typography className='efficScore'>
                      {currentTool3Marks?.questionIndexMarks?.marks2 / (selectedQuestionBankT3?.QuestionsList?.length * 2) * 100}%
                    </Typography>
                  </Box>
                </Box>
                <Box className="efficInnerItemBox">
                  <Box>
                    <Typography className='efficScoreLabel'>Advanced Efficiency approx. 75%</Typography>
                    <Typography className='efficScore'>
                      {currentTool3Marks?.questionIndexMarks?.marks3 / (selectedQuestionBankT3?.QuestionsList?.length * 3) * 100}%
                    </Typography>

                  </Box>
                  <Box>
                    <Typography className='efficScoreLabel'>Peak Efficiency approx. 100%</Typography>
                    <Typography className='efficScore'>
                      {currentTool3Marks?.questionIndexMarks?.marks4 / (selectedQuestionBankT3?.QuestionsList?.length * 4) * 100}%
                    </Typography>

                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  )
}
