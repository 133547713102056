import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTool3 } from "../../../store/Tool3Slice";
import { getUserId, fetchUser } from "../../../store/UserSlice";
import { Box, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Axios from "axios";
import "./viewQuestions.css";

//Component
import NavBar from "../../../components/ClientNavBar"
import Footer from '../../../components/Footer';
import { BlueBtn } from '../../../components/Buttons';

export const TestNoteBox = () => {
  return (
    <Box className="testNoteBox">
      <p className='testNote'>Please be aware that once an option is selected and
        you proceed to the next question, revisiting the previous question is not permitted.
        This policy is in place to ensure fairness and prevent compromising the integrity of
        assessments. We appreciate your understanding and cooperation in maintaining the quality
        of our evaluations.</p>
    </Box>
  )
}


export default function Tool3QuestionSet({ setMarks, marks }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const paramsQuestionBankId = searchParams.get("Qid");
  const { data, status } = useSelector((state) => state.tool3);
  const { User, UserId } = useSelector((state) => state.user);
  const userLog = localStorage.getItem("userString");
  if (!userLog) navigate("/")
  const questionBank = data?.data ? data.data[0]?.QuestionsBanks : [];
  let questions = questionBank.length > 0 ? questionBank.find((obj) => obj._id === paramsQuestionBankId) : {};
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [clickedOptions, setClickedOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [checkBox, setCheckBox] = useState(false)
  const [marksIndex, setMarksIndex] = useState({
    marks1: 0,
    marks2: 0,
    marks3: 0,
    marks4: 0
  });
  function calculateMarks(rightPattern, inputPattern) {
    let marks = 0;
    for (let i = 0; i < rightPattern.length; i++) {
      const rightOption = rightPattern[i];
      const inputOption = inputPattern[i];

      if (rightOption === inputOption) {
        marks++;
      }
    }
    setClickedOptions([]);
    return marks;
  }

  const handelMarks = () => {
    const rightPattern = questions?.QuestionsList[currentQuestionIndex].Pattern.split('');
    return calculateMarks(rightPattern, clickedOptions);
  }
  let TotalMarks = []
  const HandleSubmitQuestion = () => {
    setCheckBox(false)
    if (currentQuestionIndex < questions?.QuestionsList?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setClickedOptions([]);
      setSelectedOptionIndex(null);
    }
    const marksData = handelMarks();
    setMarks((provMarks) => provMarks * 1 + marksData * 1)
    TotalMarks.push(marks + marksData)

    let newMarksIndex = { ...marksIndex };

    if (marksData === 1) {
      newMarksIndex.marks1 = (marksIndex?.marks1 || 0) + marksData;
    } else if (marksData === 2) {
      newMarksIndex.marks2 = (marksIndex?.marks2 || 0) + marksData;
    } else if (marksData === 3) {
      newMarksIndex.marks3 = (marksIndex?.marks3 || 0) + marksData;
    } else if (marksData === 4) {
      newMarksIndex.marks4 = (marksIndex?.marks4 || 0) + marksData;
    }
    setMarksIndex(newMarksIndex);


    if (currentQuestionIndex === questions?.QuestionsList?.length - 1) {

      if (userLog) {
        Axios.post(process.env.REACT_APP_BASE_URL + "/user/update/testScore", {
          TestName: "Tool3",
          email: User?.userData?.email,
          TestScore: TotalMarks[0],
          questionIndexMarks: newMarksIndex,
          QuestionBankId: questions._id,
        })
      }
      navigate("/user/profile")
      setTimeout(() => {
        TotalMarks = []
        setMarksIndex({
          marks1: 0,
          marks2: 0,
          marks3: 0,
          marks4: 0
        });
      }, 500);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setClickedOptions([]);
      setSelectedOptionIndex(null);
    }
  };

  const handleOptionClick = (optionIndex) => {
    const alphabet = 'ABCD';
    const selectedOption = alphabet[optionIndex];
    setSelectedOptionIndex(optionIndex);
    setClickedOptions((prevClickedOptions) => [...prevClickedOptions, selectedOption]);
    return optionIndex
  };

  const isOptionSelected = (optionIndex) => {
    return selectedOptionIndex !== null && selectedOptionIndex === optionIndex;
  };



  useEffect(() => {
    if (data.length === 0) dispatch(fetchTool3());
    if (!UserId) {
      dispatch(getUserId());
    }
    if (!User.length && UserId) {
      dispatch(fetchUser({ id: UserId }));
    }
  }, [UserId, User.length, marks, marksIndex])

  return (
    <>
      <NavBar />
      <Box className="viewQuestionContainer">
        <Box className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
          <Box className="toolHeaderbox toolHeaderboxT2">
            <Typography className='toolHeaderText' variant='h5' mb={2}>
              <span className='Vl'>VLiGTA </span>
              <span className='Rl'>®</span>
              <span style={{ width: "407px", bottom: -58, left: -85, textAlign: "center" }} className='R_l'>Rank the solutions from best to worst (1 being the best, 4 being the worst)</span>
              <span className='Rm_T'>Exercise</span>
            </Typography>
          </Box>
        </Box>

        <Box className="questionBox">
          {questions?.QuestionsList && (
            <Box className="questionItem">

              <Typography className='Tool4Question' >
                <span className='questionIndexLabel Avenir_Black'> Question {currentQuestionIndex + 1}</span>
                <br /> {questions.QuestionsList[currentQuestionIndex]?.Question}
              </Typography>

              <Box className="options">
                {questions.QuestionsList[currentQuestionIndex]?.Options.map((option, optionIndex) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: "10px",
                      border: "2px solid",
                      borderColor: isOptionSelected(optionIndex) ? "#F7B827" : "#ffff"

                    }}
                    className={isOptionSelected(optionIndex) ? "selectedOption" : "optionItem"} mb={1.5} key={optionIndex}>
                    <Typography className='TextColor_p '>
                      {
                        optionIndex === 0 ? "A" : optionIndex === 1 ? "B" : optionIndex === 2 ? "C" : optionIndex === 3 ? "D" : null
                      }
                    </Typography>
                    <input
                      className='pointer'
                      type="checkbox"
                      name={`option-${currentQuestionIndex}`}
                      checked={checkBox ? checkBox[optionIndex] : false}
                      onChange={() => {
                        const val = handleOptionClick(optionIndex)
                        setCheckBox(val === optionIndex);
                      }}
                    />
                    <Typography
                      pl={3}
                      sx={{ cursor: "pointer" }}
                      className='TextColor_p '
                    >
                      {option}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            sx={{ display: "none" }}
            className='previousBtn'
            variant="contained"
            disabled={currentQuestionIndex === 0}
            onClick={handlePreviousQuestion}>
            Previous
          </Button>
          <BlueBtn
            onClick={HandleSubmitQuestion}
            buttonText="Submit"
          />
        </Box>
        {TestNoteBox()}
      </Box>
      <Footer />
    </>
  );
}
