import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Chip, styled, TextField, Paper, FormControl, Select, Button, MenuItem, InputLabel } from '@mui/material';
import usePagination from "../../components/Pagination";
import { BlueBtn, RedBtn } from '../../components/Buttons';
import Axios from "axios";
import { Notification } from "../../App"
import "./questionDetails.css";
import { fetchTool3, CreateQuestionBank, UpdateQuestionBank } from "../../store/Tool3Slice"
import { fetchCareerSummery } from "../../store/UserSlice";
import { cutOffMarksArr } from "./Tool1"

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const patterns = [
  "ABCD", "ABDC", "ACBD", "ACDB", "ADBC", "ADCB",
  "BACD", "BADC", "BCAD", "BCDA", "BDAC", "BDCA",
  "CABD", "CADB", "CBAD", "CBDA", "CDAB", "CDBA",
  "DABC", "DACB", "DBAC", "DBCA", "DCAB", "DCBA"
];
const skillCategoryList = ["Learn", "Skill", "Grow", "Excel", "Theorise", "Visualise", "Develop", "Analyse"]

export default function Tool3() {
  const { data, status } = useSelector((state) => state.tool3);
  const { CareerSummery } = useSelector((state) => state.user);
  const dispatch = useDispatch()

  const Tool3 = data?.data ? data.data[0] : "";
  const QuestionBank = data?.data ? data.data[0]?.QuestionsBanks : [];


  const [selectedQuestionBank, setSelectedQuestionBank] = useState('');
  let Questions = QuestionBank.length > 0 ? QuestionBank.find((obj) => obj._id === selectedQuestionBank) : [];
  const [questionBackBox, setQuestionBankBox] = useState(false)
  const [questionBankDeleteBox, setQuestionBankDeleteBox] = useState(false)
  const [currentField, setCurrentField] = useState(0);
  const [categorySelector, setCategorySelector] = useState("");
  const [CutOffMarks, setOffMarks] = useState("")
  const [KeySkills, setKeySkills] = useState([]);
  const [KeySkillsVal, setKeySkillsVal] = useState();
  const [questionBack, setQuestionBank] = useState({})
  const [page, setPage] = useState(1);
  const [isEdit, setIsEdit] = useState([])
  const [questionId, setQuestionId] = useState("")
  const [deleteBox, setDeleteBox] = useState(false)
  const [createQuestionBox, setCreateQuestionBox] = useState(false);
  const [FieldCategory, setFieldCategory] = useState([]);
  const [SkillCategory, setSkillCategory] = useState([]);

  //Create State
  const [createQuestionData, setCreateQuestionData] = useState({
    Question: "",
  });
  const [pattern, setPattern] = useState("")
  const [difficulty, setDifficulty] = useState("")
  const [createOption, setCreateOption] = useState({
    option_A: "",
    option_B: "",
    option_C: "",
    option_D: ""
  })

  //Update State 
  const [updateQuestionData, setUpdateQuestionData] = useState({
    Question: "",
    Difficulty: "",
    Pattern: ""
  });

  const [updateOption, setUpdateOption] = useState({
    option_A: "",
    option_B: "",
    option_C: "",
    option_D: ""
  })



  let trueIndex = -1;
  for (let i = 0; i < isEdit.length; i++) {
    if (isEdit[i] === true) {
      trueIndex = i;
      break;
    }
  }

  //Pagination
  const PER_PAGE = 5;
  const count = Math.ceil(Tool3[0]?.length / PER_PAGE) || 0;
  const _DATA = usePagination(Tool3[0] ?? [], PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };


  //Questions
  const handleCreateQuestions = (event) => {
    const { name, value } = event.target;
    setCreateQuestionData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  //Options
  const handleCreateOptions = (event) => {
    const { name, value } = event.target;
    setCreateOption((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  //Create Question
  const handelQuestionCreate = () => {
    const OptionArr = Object.values(createOption)
    if (
      createQuestionData.Question === "" ||
      createOption.option_A === "" ||
      createOption.option_B === "" ||
      createOption.option_C === "" ||
      createOption.option_D === "" ||
      !pattern
    ) {
      Notification("warning", "Please fill up all the fields!")
      return;
    }
    if (!selectedQuestionBank) return Notification("error", "Question Bank not selected!")

    Axios.post(process.env.REACT_APP_BASE_URL + "/tool3/Question/create", {
      questionBankId: selectedQuestionBank,
      question: createQuestionData.Question,
      difficulty,
      options: OptionArr,
      pattern
    }).then(() => {
      Notification("success", "One question added successfully")
      setIsEdit([])
      setCreateQuestionBox(false)
      handelCloseBox()
      dispatch(fetchTool3())
    }).catch((err) => {
      Notification("error", err?.response?.data?.message || "Something went wrong!")
    })
  }

  //Delete Question
  const closeDelBox = () => setDeleteBox(false);

  const handleDeleteQuestionBox = (id) => {
    setQuestionId(id)
    setDeleteBox(true)
  }
  const deleteQuestion = () => {
    if (questionId) {
      Axios.post(process.env.REACT_APP_BASE_URL + "/tool3/Question/deleteById", { questionBankId: selectedQuestionBank, questionId }).then(() => {
        Notification("success", "One Question deleted successfully!")
        setDeleteBox(false)
        dispatch(fetchTool3())
      }).catch((err) => {
        Notification("error", err?.response?.data?.message || "Something went wrong!")
      })
    }
  }

  const handelCancel = (index) => {
    setUpdateQuestionData({ Question: "", Difficulty: "", Pattern: "" });
    setUpdateOption({ option_A: "", option_B: "", option_C: "", option_D: "" })
  };

  // Edit Question function
  const toggleEdit = (index) => {
    handelCancel(index)
    setIsEdit((prevIsEdit) => {
      const updatedIsEdit = [...prevIsEdit];
      updatedIsEdit[index] = !prevIsEdit[index];
      if (updatedIsEdit[index]) {
        setUpdateQuestionData((prevData) => ({
          ...prevData,
          Question: Questions?.QuestionsList[index].Question || "",
          Difficulty: Questions?.QuestionsList[index].Difficulty || "",
          Pattern: Questions?.QuestionsList[index].Pattern || ""
        }));

        setUpdateOption((prevData) => ({
          ...prevData,
          option_A: Questions?.QuestionsList[index]?.Options[0] || "",
          option_B: Questions?.QuestionsList[index]?.Options[1] || "",
          option_C: Questions?.QuestionsList[index]?.Options[2] || "",
          option_D: Questions?.QuestionsList[index]?.Options[3] || "",
        }));
      }
      return updatedIsEdit;
    });
  };


  const disableFields = (index) => {
    setIsEdit((prevIsEdit) => {
      const updatedIsEdit = [...prevIsEdit];
      updatedIsEdit[index] = false;
      return updatedIsEdit;
    });
  };

  //Update Question
  const handelUpdateQuestion = (event, index) => {
    const { name, value } = event.target;
    setUpdateQuestionData((prevData) => ({
      ...prevData,
      [index]: { ...prevData[index], [name]: value }
    }));
  }


  const handelUpdateOption = (event, index) => {
    const { name, value } = event.target;
    setUpdateOption((prevData) => ({
      ...prevData,
      [index]: { ...prevData[index], [name]: value }
    }));
  }

  const postUpdateQuestion = (e, index, questionId, el) => {
    let question = updateQuestionData[index]?.Question || updateQuestionData?.Question || el?.Question;
    let difficulty = updateQuestionData[index]?.Difficulty || updateQuestionData?.Difficulty || el?.Difficulty;
    let Pattern = updateQuestionData[index]?.Pattern || updateQuestionData?.Pattern || el?.Pattern;

    let option_A = updateOption[index]?.option_A || updateOption?.option_A || el?.Options[0];
    let option_B = updateOption[index]?.option_B || updateOption?.option_B || el?.Options[1];
    let option_C = updateOption[index]?.option_C || updateOption?.option_C || el?.Options[2];
    let option_D = updateOption[index]?.option_D || updateOption?.option_D || el?.Options[3];
  }

  const handelCreateBox = () => {
    setCreateQuestionBox(true)
  }
  //cancel
  const handelCloseBox = () => {
    setCreateQuestionBox(false)
    setCreateQuestionData({ Question: "" })
    setCreateOption({ option_A: "", option_B: "", option_C: "", option_D: "", })
    setPattern("")
    setDifficulty("")
  }

  const updatedFieldClear = () => {
    setUpdateQuestionData({ Question: "", Difficulty: "", Pattern: "" })
    setUpdateOption({ option_A: "", option_B: "", option_C: "", option_D: "", })
  }


  const handelCreateQBank = () => {
    setQuestionBankBox(!questionBackBox)
    setQuestionBank({})
  }
  const handleSelectChange = (event) => setSelectedQuestionBank(event.target.value);

  //Create question Bank
  const handelCreateSkillCatagory = (e) => {
    const { name, value } = e.target;
    if (name === "LegalFieldCategory") {
      if (!FieldCategory.includes(value)) {
        setFieldCategory([...FieldCategory, value]);
      }
    }

    if (name === "SkillCatagory") {
      if (!SkillCategory.includes(value)) {
        setSkillCategory([...SkillCategory, value]);
      }
    }
    else {
      if (!KeySkills.includes(KeySkillsVal)) {
        setKeySkills([...KeySkills, KeySkillsVal]);
      }
      setKeySkillsVal("")
    }
  }
  const handleLegalFieldDelete = (chipToDelete, Q) => () => {
    if (Q === "LegalFieldCategory") {
      setFieldCategory(FieldCategory.filter(item => item !== chipToDelete))

    }
    if (Q === "SkillCatagory") {
      setSkillCategory(SkillCategory.filter(item => item !== chipToDelete))
    }
    if (Q === "KeySkills") {
      setKeySkills(KeySkills.filter(item => item !== chipToDelete))
    }
  }
  const handleQuestionBank = (e) => {
    const { name, value } = e.target;
    setQuestionBank((prevQuestionBank) => ({
      ...prevQuestionBank,
      [name]: value
    }));
  }

  const handleNext = () => {
    if (currentField < 4) {
      setCurrentField((prevField) => prevField + 1);
    }
  };

  const handlePrev = () => {
    if (currentField > 0) {
      setCurrentField((prevField) => prevField - 1);
    }
  };

  const submitQuestionBank = () => {
    const FieldCategorys = { FieldCategory }
    const SkillCategorys = { SkillCategory }
    const CutOffMark = { CutOffMarks }
    const KeySkill = { KeySkills }

    if (
      questionBack.questionBankName === "" ||
      questionBack.courseName === "" ||
      questionBack.courseId === "" ||
      questionBack.qualifyingTestId === "" ||
      FieldCategory === "" ||
      SkillCategory === "" ||
      KeySkills.length === 0

    ) {
      Notification("warning", "Please fill up all the fields!")
      return;
    }
    dispatch(CreateQuestionBank({
      ...questionBack,
      ...CutOffMark,
      ...FieldCategorys,
      ...SkillCategorys,
      ...KeySkill
    }))
    setQuestionBankBox(false)
    setFieldCategory([])
    setQuestionBank([])
    setSkillCategory([])
    setOffMarks([])
    setKeySkills([])
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  //Delete Question Bank
  const deleteQuestionBank = () => {
    if (!selectedQuestionBank) {
      Notification("error", "Question Bank not selected!")
      setQuestionBankDeleteBox(false)
      return
    }
    Axios.post(process.env.REACT_APP_BASE_URL + "/tool3/QuestionBank/delete", {
      questionBankId: selectedQuestionBank,
    }).then(() => {
      Notification("warning", "Question Bank deleted successfully!")
      dispatch(fetchTool3())
      setQuestionBankDeleteBox(false)
    }).catch((err) => {
      Notification("error", err?.response?.data?.message || "Something went wrong!")
    })
  }





  // Update QuestionBank
  const [questionBankEditable, setQuestionBankEditable] = useState(false)
  const [updateQuestionBankData, setUpdateQuestionBankData] = useState({})

  const handelUpdateQuestionBank = (event) => {
    const { name, value } = event.target;
    setUpdateQuestionBankData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleQuestionBankEdit = () => {
    if (!selectedQuestionBank) {
      Notification("error", "Question Bank not selected!")
      setQuestionBankDeleteBox(false)
      return
    } else {
      setQuestionBankEditable(!questionBankEditable)
    }
  }

  const postQuestionBankData = () => {
    const BankId = { questionBankId: selectedQuestionBank }

    console.log({ ...updateQuestionBankData });
    dispatch(UpdateQuestionBank({ ...updateQuestionBankData, ...BankId }))
    setQuestionBankEditable(false)
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }




  useEffect(() => {
    dispatch(fetchTool3())
    if (CareerSummery?.length === 0) {
      dispatch(fetchCareerSummery())
    }
  }, [CareerSummery])

  return (
    <>
      <Box className="QuestionDetailsBox">

        <Box sx={{ display: deleteBox ? "flex" : "none" }} className="deleteConfirmationBox">
          <Box mt={2} className="DeleteMegBox">
            <Typography>Are you sure to delete this question?</Typography>
          </Box>
          <Box mt={3} className="deleteBtnBox">
            <BlueBtn buttonText="Cancel" onClick={closeDelBox} />
            <RedBtn buttonText="Delete" onClick={deleteQuestion} />
          </Box>
        </Box>

        <Box sx={{ display: questionBankDeleteBox ? "flex" : "none" }} className="deleteConfirmationBox">
          <Box mt={2} className="DeleteMegBox">
            <Typography>Are you sure to delete this Question Bank ?</Typography>
          </Box>
          <Box mt={3} className="deleteBtnBox">
            <BlueBtn buttonText="Cancel" onClick={() => setQuestionBankDeleteBox(false)} />
            <RedBtn buttonText="Delete" onClick={deleteQuestionBank} />
          </Box>
        </Box>

        <Box className="ViewQuestion">

          <Box className="selectQuestionBankBox">
            <Typography sx={{ textAlign: "left", color: "#fff" }} variant='h5'>QuestionBank:</Typography>
            <FormControl sx={{ m: 1, minWidth: 220 }}>
              <InputLabel htmlFor="grouped-select">Select</InputLabel>
              <Select
                sx={{ color: "#fff" }}
                defaultValue=""
                id="grouped-select"
                label="Select"
                onChange={handleSelectChange}
              >
                {
                  QuestionBank?.map((el) => (
                    <MenuItem key={el?._id} value={el?._id}>{el?.Name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <Button onClick={() => setQuestionBankDeleteBox(true)} variant="outlined" color="error">Delete</Button>
            <BlueBtn width="100px" buttonText={questionBankEditable ? "Cancel" : "Edit"} onClick={handleQuestionBankEdit} />
            <Button width="100px" variant="contained" disabled={!questionBankEditable} onClick={postQuestionBankData}>Update</Button>
          </Box>

          <Box className="questionBank_Info">
            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Question Bank Title</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.Name}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name='Name'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("Name") ? updateQuestionBankData.Name : Questions?.Name}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Programme Title</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CourseName}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name='CourseName'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty('CourseName') ? updateQuestionBankData.CourseName : Questions?.CourseName}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Programme ID</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CourseId}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="CourseId"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("CourseId") ? updateQuestionBankData.CourseId : Questions?.CourseId}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Qualifying Test ID</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.QualifyingTestId}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name='QualifyingTestId'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("QualifyingTestId") ? updateQuestionBankData.QualifyingTestId : Questions?.QualifyingTestId} />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Law or Policy Category</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.LawOrPolicy}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="LawOrPolicy"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("LawOrPolicy") ? updateQuestionBankData.LawOrPolicy : Questions?.LawOrPolicy}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Cut-off %</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CutOffMarks ? Questions?.CutOffMarks + "%" : null}</span>
              <span style={{ display: questionBankEditable ? "block" : "none" }} className='persentageSing'>%</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="CutOffMarks"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("CutOffMarks") ? updateQuestionBankData.CutOffMarks : Questions?.CutOffMarks}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Programme level</Typography>
              {
                Questions?.SkillCategory?.map(el => (
                  <span>{el},  </span>
                ))
              }
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography sx={{ width: "430px" }} variant='h6'>Legal field category</Typography>
              <Box className="lfBox">
                {
                  Questions?.FieldCategory?.map(el => (
                    <span>{el},  </span>
                  ))
                }
              </Box>
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Programme Mentors</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CourseMentors ? Questions?.CourseMentors : null}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="CourseMentors"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("CourseMentors") ? updateQuestionBankData.CourseMentors : Questions?.CourseMentors}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Appointment URL</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.AppointmentUrl ? Questions?.AppointmentUrl : null}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="AppointmentUrl"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("AppointmentUrl") ? updateQuestionBankData.AppointmentUrl : Questions?.AppointmentUrl}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Notes URL</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.Notes ? Questions?.Notes : null}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="Notes"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("Notes") ? updateQuestionBankData.Notes : Questions?.Notes}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Test Completion Time*</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.TestCompletionTime ? Questions?.TestCompletionTime : null} Months</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="TestCompletionTime"
                type='Number'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("TestCompletionTime") ? updateQuestionBankData.TestCompletionTime : Questions?.TestCompletionTime}
              />
              <p style={{ display: questionBankEditable ? "block" : "none" }} className='inputInstrucation'>* Value should be in minutes</p>

            </Box>

          </Box>



          {/* Create QuestionBank */}
          <Box className="questionBankCreateBtn">
            <BlueBtn buttonText={questionBackBox ? "Cancel" : "Create Question Bank"} onClick={handelCreateQBank} />
          </Box>
          <Box className="createQuestionBankBox" sx={{ display: questionBackBox ? "flex" : "none" }}>
            <Box className="QuestionFieldBox">
              <Typography ml={4} pb={2} variant='h5' sx={{ textAlign: "center" }}>Create QuestionBank</Typography>
              {currentField === 0 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Question Bank Name:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Enter your question bank name'
                      name="Name"
                      value={questionBack?.questionBankName}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }} >LegalField Category:</Typography>
                    <Box className="chipbox">
                      <select
                        className='ActiveDropdown'
                        value={FieldCategory}
                        name='LegalFieldCategory'
                        onChange={(event) => handelCreateSkillCatagory(event)}
                      >
                        {
                          CareerSummery.map((el) => (
                            <option key={el._id} value={el.name}>{el.name}</option>
                          ))
                        }
                      </select>
                      <Paper
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          listStyle: 'none',
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul"
                      >
                        {FieldCategory?.map((data, index) => {
                          return (
                            <ListItem key={index}>
                              <Chip
                                label={data}
                                onDelete={handleLegalFieldDelete(data, "LegalFieldCategory")}
                              />
                            </ListItem>
                          );
                        })}
                      </Paper>
                    </Box>
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Course Name:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Course Name'
                      name="CourseName"
                      value={questionBack?.courseName}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>
              )}
              {currentField === 1 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Course ID:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Course ID'
                      name="CourseId"
                      value={questionBack?.courseId}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Qualifying Test Id:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Qualifying Test Id'
                      name="QualifyingTestId"
                      value={questionBack?.qualifyingTestId}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Law or Policy Area:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Law or Policy Area'
                      name="LawOrPolicy"
                      value={questionBack?.lawOrPolicy}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>
              )}
              {currentField === 2 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Skill catagory:</Typography>
                    <Box className="chipbox">
                      <select
                        className='ActiveDropdown'
                        value={SkillCategory}
                        name='SkillCatagory'
                        onChange={(event) => handelCreateSkillCatagory(event)}
                      >
                        {
                          skillCategoryList.map((el, index) => (
                            <option key={index} value={el}>{el}</option>
                          ))
                        }
                      </select>
                      <Paper
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          listStyle: 'none',
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul"
                      >
                        {SkillCategory?.map((data, index) => {
                          return (
                            <ListItem key={index}>
                              <Chip
                                label={data}
                                onDelete={handleLegalFieldDelete(data, "SkillCatagory")}
                              />
                            </ListItem>
                          );
                        })}
                      </Paper>
                    </Box>

                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Cut Off Mark:</Typography>
                    <select
                      name='CutOffMark'
                      className='ActiveDropdown'
                      value={CutOffMarks}
                      onChange={(e) => setOffMarks(e.target.value)}
                    >
                      {
                        cutOffMarksArr.map((el, index) => (
                          <option key={index} value={el}>{el}%</option>
                        ))
                      }
                    </select>
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Course Mentors :</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Course Mentors'
                      name="CourseMentors"
                      value={questionBack?.CourseMentors}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>
              )}
              {currentField === 3 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Appointment Url :</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Appointment Url'
                      name="AppointmentUrl"
                      value={questionBack?.AppointmentUrl}
                      onChange={handleQuestionBank}
                    />
                  </Box>

                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Notes:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Notes Url'
                      name="Notes"
                      value={questionBack?.Notes}
                      onChange={handleQuestionBank}
                    />
                  </Box>

                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>TestCompletionTime:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Test Completion Time'
                      name="TestCompletionTime"
                      type='Number'
                      value={questionBack?.TestCompletionTime}
                      onChange={handleQuestionBank}
                    />
                    <p className='inputInstrucation'>* Value should be in minutes</p>
                  </Box>
                </>
              )}
              {currentField === 4 && (
                <Box className="QuestionBankInputItem">
                  <Typography sx={{ width: "180px" }}>Key Skills:</Typography>
                  <Box className="chipbox">
                    <TextField style={{ marginBottom: "10px" }} className='QuestionBankName' name='KeySkills' onChange={(e) => setKeySkillsVal(e.target.value)} value={KeySkillsVal} placeholder='Enter KeySkills' />
                    <BlueBtn disabled={!KeySkillsVal} buttonText="Add" onClick={handelCreateSkillCatagory} />
                    <Paper
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                        marginTop: "10px"
                      }}
                      component="ul"
                    >
                      {KeySkills?.map((data, index) => {
                        return (
                          <ListItem key={index}>
                            <Chip
                              label={data}
                              onDelete={handleLegalFieldDelete(data, "KeySkills")}
                            />
                          </ListItem>
                        );
                      })}
                    </Paper>
                  </Box>
                </Box>
              )}

              <Box className="QuestionBankButtonBox">
                <Button onClick={handlePrev} disabled={currentField === 0}>
                  Previous
                </Button>
                <Button onClick={handleNext} disabled={currentField === 4}>
                  Next
                </Button>
                <BlueBtn buttonText="Save" width="150px" disabled={!questionBack ? true : false} onClick={submitQuestionBank} />
              </Box>
            </Box>
            <Box className="cancelQBCB">
              <BlueBtn buttonText={questionBackBox ? "Cancel" : "Create Question Bank"} onClick={handelCreateQBank} />
            </Box>
          </Box>



          <Typography sx={{ textAlign: "center", color: "#fff" }} variant='h5'>Add/ Modify / Delete the Questions and Answers as per requirements.</Typography>
          <Box className="tool3Create">
            <BlueBtn buttonText="Create Question" width="173px" onClick={handelCreateBox} />
          </Box>

          <Box className="EditQuestionBox">

            {/*create question*/}
            <Box sx={{ display: createQuestionBox ? "flex" : "none" }} className="questionCard createBox" >
              <Box className="field">
                <Box className="FieldBox">
                  <Typography sx={{ width: "94px" }}>Question:</Typography>
                  <TextField
                    className='questionField'
                    placeholder='Question'
                    name="Question"
                    value={createQuestionData.Question}
                    onChange={handleCreateQuestions}
                  />
                </Box>
                <Box className="FieldBox">
                  <Typography sx={{ width: "86px" }}>Pattern:</Typography>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    <InputLabel htmlFor="grouped-select">Select</InputLabel>
                    <Select
                      defaultValue=""
                      id="grouped-select"
                      label="Select"
                      value={pattern ? pattern : ""}
                      onChange={(e) => setPattern(e.target.value)}
                    >
                      {
                        patterns.map((el) => (
                          <MenuItem key={el} value={el}>{el}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>

                <Box className="FieldBox">
                  <Typography sx={{ width: "88px" }}>Difficulty:</Typography>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    <InputLabel htmlFor="grouped-select">Select</InputLabel>
                    <Select
                      defaultValue="select"
                      id="grouped-select"
                      label="Select"
                      value={difficulty ? difficulty : ""}
                      onChange={(e) => setDifficulty(e.target.value)}>
                      <MenuItem value="Easy">Easy</MenuItem>
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="Hard">Hard</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box className="FieldBox">
                  <Typography sx={{ width: "80px" }}>Options:</Typography>
                  <Box className="option_Box">
                    <Box className="optionItem">
                      <h5>A:</h5>
                      <TextField className='option Oa' placeholder='Option-A' name="option_A" onChange={handleCreateOptions} value={createOption.option_A} />
                    </Box>
                    <Box className="optionItem">
                      <h5>B:</h5>
                      <TextField className='option option-padding' placeholder='Option-B' name="option_B" onChange={handleCreateOptions} value={createOption.option_B} />
                    </Box>
                    <Box className="optionItem">
                      <h5>C:</h5>
                      <TextField className='option option-padding' placeholder='Option-C' name="option_C" onChange={handleCreateOptions} value={createOption.option_C} />
                    </Box>
                    <Box className="optionItem">
                      <h5>D:</h5>
                      <TextField className='option option-padding' placeholder='Option-D' name="option_D" onChange={handleCreateOptions} value={createOption.option_D} />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="fieldBtn">
                <Box my={1.5} className="Buttons">
                  <RedBtn buttonText="Cancel" onClick={handelCloseBox} />
                </Box>
                <Box my={1.5} className="Buttons">
                  <BlueBtn width="100px" buttonText="Save" onClick={handelQuestionCreate} />
                </Box>
              </Box>
            </Box>



            {/* render all question */}
            {status === "loading" ?
              <Box mt={2}>
                <Typography>Loading...</Typography>
              </Box> : status === "error" ? (
                <Box>
                  <Typography>Error occurred: Internal server error. / Please try again.</Typography>
                </Box>
              ) :
                Questions?.QuestionsList?.map((el, index) => (
                  <Box className="questionCard" key={el?._id}>
                    <Box className="field">
                      <Typography >No: {index + 1}</Typography>
                      <Box className="FieldBox">
                        <Typography sx={{ width: "94px" }}>Question:</Typography>
                        <TextField
                          name="Question"
                          disabled={!isEdit[index]}
                          className='questionField'
                          placeholder='Question'
                          onChange={(event) => handelUpdateQuestion(event, index)}
                          value={updateQuestionData[index] ? updateQuestionData[index].question : el?.Question}
                        />
                      </Box>
                      <Box className="FieldBox">
                        <Typography sx={{ width: "86px" }}>Pattern:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">Select</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='Pattern'
                            disabled={!isEdit[index]}
                            defaultValue={el?.Pattern}
                            id="grouped-select"
                            label="Select"
                            value={updateQuestionData[index] ? updateQuestionData[index].Pattern : el?.Pattern}
                            onChange={(event) => handelUpdateQuestion(event, index)}
                          >
                            {
                              patterns.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                      </Box>

                      <Box className="FieldBox">
                        <Typography sx={{ width: "88px" }}>Difficulty:</Typography>

                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">Select</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='Difficulty'
                            disabled={!isEdit[index]}
                            defaultValue={el?.Difficulty}
                            id="grouped-select"
                            label="Select"
                            value={updateQuestionData[index] ? updateQuestionData[index].Difficulty : el?.Difficulty}
                            onChange={(event) => handelUpdateQuestion(event, index)}
                          >
                            <MenuItem value="Easy">Easy</MenuItem>
                            <MenuItem value="Medium">Medium</MenuItem>
                            <MenuItem value="Hard">Hard</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>

                      <Box className="FieldBox">
                        <Typography sx={{ width: "80px" }}>Options:</Typography>
                        <Box className="option_Box">
                          <Box className="optionItem">
                            <h5>A </h5>
                            <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_A" disabled={!isEdit[index]} className='option Oa' placeholder='Option-A' value={updateOption[index] ? updateOption[index].option_A : el?.Options[0]} />
                          </Box>
                          <Box className="optionItem">
                            <h5>B </h5>
                            <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_B" disabled={!isEdit[index]} className='option option-padding' placeholder='Option-B' value={updateOption[index] ? updateOption[index].option_B : el?.Options[1]} />
                          </Box>
                          <Box className="optionItem">
                            <h5>C </h5>
                            <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_C" disabled={!isEdit[index]} className='option option-padding' placeholder='Option-C' value={updateOption[index] ? updateOption[index].option_C : el?.Options[2]} />
                          </Box>
                          <Box className="optionItem">
                            <h5>D </h5>
                            <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_D" disabled={!isEdit[index]} className='option option-padding' placeholder='Option-D' value={updateOption[index] ? updateOption[index].option_D : el?.Options[3]} />
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="fieldBtn">
                      <Box my={1.5} className="Buttons">
                        <BlueBtn width="100px" buttonText={isEdit[index] ? "Cancel" : "Edit"} onClick={() => {
                          updatedFieldClear()
                          toggleEdit(index)
                          for (let i = 0; i < isEdit.length; i++) {
                            if (i !== index && isEdit[i]) {
                              disableFields(i);
                            }
                          }
                        }} />
                      </Box>
                      <Box my={1.5} className="Buttons">
                        <RedBtn buttonText="Delete" onClick={() => handleDeleteQuestionBox(el?._id)} />
                      </Box>
                      <Box my={1.5} className="Buttons">
                        <BlueBtn
                          width="100px"
                          buttonText="Save"
                          onClick={(e) =>
                            postUpdateQuestion(e, index, el?._id, el)}
                          disabled={index === trueIndex ? false : true}
                        />
                      </Box>
                    </Box>
                  </Box>

                ))
            }
          </Box>
        </Box>
      </Box >
    </>
  )
}
