import React, { useEffect, useState } from 'react';
import { Box, Chip, Paper, styled, Typography, TextField, FormControl, Select, Button, MenuItem, InputLabel } from '@mui/material';
import { BlueBtn, RedBtn } from '../../components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from "../../App"
import { fetchTool1, CreateQuestionBank, UpdateQuestionBank } from "../../store/Tool1Slice";
import { fetchCareerSummery } from "../../store/UserSlice";

import Axios from "axios";
import "./style.css"
import "./questionDetails.css";

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const cutOffMarksArr = ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"];
export const skillCategoryList = ["Learn", "Skill", "Grow", "Excel", "Theorise", "Visualise", "Develop", "Analyse"]

export default function Tool1() {
  const { data, status } = useSelector((state) => state.tool1);
  const { CareerSummery } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const Tool1 = data?.data ? data.data[0]?.QuestionsBanks : [];
  const QuestionBank = data?.data ? data.data[0]?.QuestionsBanks : [];
  const [selectedQuestionBank, setSelectedQuestionBank] = useState('');

  let Questions = QuestionBank.length > 0 ? QuestionBank.find((obj) => obj._id === selectedQuestionBank) : [];
  const [currentField, setCurrentField] = useState(0);
  const [FieldCategory, setFieldCategory] = useState([]);
  const [SkillCategory, setSkillCategory] = useState([]);
  const [KeySkills, setKeySkills] = useState([]);
  const [KeySkillsVal, setKeySkillsVal] = useState();
  const [questionBackBox, setQuestionBankBox] = useState(false)
  const [CutOffMarks, setOffMarks] = useState("")
  const [questionBankDeleteBox, setQuestionBankDeleteBox] = useState(false)
  const [deleteBox, setDeleteBox] = useState(false)
  const [createQuestionBox, setCreateQuestionBox] = useState(false);
  const [isEdit, setIsEdit] = useState([])
  const [questionId, setQuestionId] = useState("")
  const [questionBankEditable, setQuestionBankEditable] = useState(false)

  const [createQuestionData, setCreateQuestionData] = useState({
    Question: "",
    Answer: "",
  });
  const [createOption, setCreateOption] = useState({
    option_A: "",
    option_B: "",
    option_C: "",
    option_D: ""
  })
  const [updateQuestionData, setUpdateQuestionData] = useState({
    Question: "",
    Answer: "",
  });
  const [updateOption, setUpdateOption] = useState({
    option_A: "",
    option_B: "",
    option_C: "",
    option_D: ""
  })
  const [questionBack, setQuestionBank] = useState({})

  let trueIndex = -1;
  for (let i = 0; i < isEdit.length; i++) {
    if (isEdit[i] === true) {
      trueIndex = i;
      break;
    }
  }

  //Create Question
  const handelQuestionCreate = () => {
    const OptionArr = Object.values(createOption)
    if (
      createQuestionData.Question === "" ||
      createQuestionData.Answer === "" ||
      createOption.option_A === "" ||
      createOption.option_B === "" ||
      createOption.option_C === "" ||
      createOption.option_D === ""
    ) {
      Notification("warning", "Please fill up all the fields!")
      return;
    }
    if (!OptionArr.includes(createQuestionData?.Answer)) {
      Notification("warning", "The answer should be include one of those options!")
      return;
    }
    if (!selectedQuestionBank) return Notification("error", "Question Bank not selected!")

    Axios.post(process.env.REACT_APP_BASE_URL + "/tool1/Question/create", {
      questionBankId: selectedQuestionBank,
      question: createQuestionData.Question,
      answer: createQuestionData.Answer,
      options: OptionArr
    }).then(() => {
      Notification("success", "One question added successfully")
      setCreateQuestionData({ Question: "", Answer: "" });
      setCreateOption({ option_A: "", option_B: "", option_C: "", option_D: "", })
      setCreateQuestionBox(false)
      dispatch(fetchTool1())
    }).catch((err) => {
      Notification("error", err?.response?.data?.message || "Something went wrong!")
    })
  }


  //Questions
  const handleCreateQuestions = (event) => {
    const { name, value } = event.target;
    setCreateQuestionData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  //Options
  const handleCreateOptions = (event) => {
    const { name, value } = event.target;
    setCreateOption((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const disableFields = (index) => {
    setIsEdit((prevIsEdit) => {
      const updatedIsEdit = [...prevIsEdit];
      updatedIsEdit[index] = false;
      return updatedIsEdit;
    });
  };

  //Update Question
  const handelUpdateQuestion = (event, index) => {
    const { name, value } = event.target;
    setUpdateQuestionData((prevData) => ({
      ...prevData,
      [index]: { ...prevData[index], [name]: value }
    }));
  }
  const handelUpdateOption = (event, index) => {
    const { name, value } = event.target;
    setUpdateOption((prevData) => ({
      ...prevData,
      [index]: { ...prevData[index], [name]: value }
    }));
  }

  const postUpdateQuestion = (e, index, questionId, el) => {
    let question = updateQuestionData[index]?.Question || updateQuestionData?.Question || el?.Question;
    let answer = updateQuestionData[index]?.Answer || updateQuestionData?.Answer || el?.Answer;
    let option_A = updateOption[index]?.option_A || updateOption?.option_A || el?.Options[0];
    let option_B = updateOption[index]?.option_B || updateOption?.option_B || el?.Options[1];
    let option_C = updateOption[index]?.option_C || updateOption?.option_C || el?.Options[2];
    let option_D = updateOption[index]?.option_D || updateOption?.option_D || el?.Options[3];

    Axios.post(process.env.REACT_APP_BASE_URL + "/tool1/Question/updateById", {
      questionBankId: selectedQuestionBank,
      questionId,
      question,
      answer,
      options: [option_A, option_B, option_C, option_D,]
    }).then(() => {
      Notification("success", "Question update successfully!")
      setIsEdit([])
      handelCancel()
      dispatch(fetchTool1())
    }).catch((err) => {
      console.log(err);
      Notification("error", err)
    })
  }

  //Delete Question
  const closeDelBox = () => setDeleteBox(false);
  const handleDeleteQuestionBox = (id) => {
    setQuestionId(id)
    setDeleteBox(true)
  }

  const deleteQuestion = () => {
    if (!selectedQuestionBank || !questionId) {
      Notification("error", "Question Bank not selected!")
      setDeleteBox(false)
      return
    }
    Axios.post(process.env.REACT_APP_BASE_URL + "/tool1/Question/deleteById", {
      questionBankId: selectedQuestionBank,
      questionId
    }).then(() => {
      Notification("warning", "One Question deleted successfully!")
      setDeleteBox(false)
      dispatch(fetchTool1())
    }).catch((err) => {
      Notification("error", err?.response?.data?.message || "Something went wrong!")
    })

  }


  const handelCancel = (index) => {
    setUpdateQuestionData({ Question: "", Answer: "" });
    setUpdateOption({ option_A: "", option_B: "", option_C: "", option_D: "" })
  };

  // Edit Question
  const toggleEdit = (index) => {
    handelCancel(index)
    setIsEdit((prevIsEdit) => {
      const updatedIsEdit = [...prevIsEdit];
      updatedIsEdit[index] = !prevIsEdit[index];
      if (updatedIsEdit[index]) {
        setUpdateQuestionData((prevData) => ({
          ...prevData,
          Question: Tool1[0][index]?.question || "",
          Answer: Tool1[0][index]?.Answer || "",
        }));
        setUpdateOption((prevData) => ({
          ...prevData,
          option_A: Tool1[0][index]?.Options[0] || "",
          option_B: Tool1[0][index]?.Options[1] || "",
          option_C: Tool1[0][index]?.Options[2] || "",
          option_D: Tool1[0][index]?.Options[3] || "",

        }));
      }
      return updatedIsEdit;
    });
  };

  const handelCreateBox = () => {
    setCreateQuestionBox(true)
  }
  const handelCloseBox = () => {
    setCreateQuestionBox(false)
    setCreateQuestionData({ Question: "", Answer: "" });
    setCreateOption({ option_A: "", option_B: "", option_C: "", option_D: "", })
  }

  const handleSelectChange = (event) => setSelectedQuestionBank(event.target.value);

  ///Question Bank 
  const handelCreateQBank = () => {
    setQuestionBankBox(!questionBackBox)
  }
  //Create questionBank
  const handelCreateSkillCatagory = (e) => {
    const { name, value } = e.target;
    if (name === "FieldCategory") {
      if (!FieldCategory.includes(value)) {
        setFieldCategory([...FieldCategory, value]);
      }
    }

    if (name === "SkillCategory") {
      if (!SkillCategory.includes(value)) {
        setSkillCategory([...SkillCategory, value]);
      }
    }
    else {
      if (!KeySkills.includes(KeySkillsVal)) {
        setKeySkills([...KeySkills, KeySkillsVal]);
      }
      setKeySkillsVal("")
    }

  }
  const handleLegalFieldDelete = (chipToDelete, Q) => () => {
    if (Q === "LegalFieldCategory") {
      setFieldCategory(FieldCategory.filter(item => item !== chipToDelete))

    }
    if (Q === "SkillCatagory") {
      setSkillCategory(SkillCategory.filter(item => item !== chipToDelete))
    }
    if (Q === "KeySkills") {
      setKeySkills(KeySkills.filter(item => item !== chipToDelete))
    }
  }

  const handleQuestionBank = (e) => {
    const { name, value } = e.target;
    setQuestionBank((prevQuestionBank) => ({
      ...prevQuestionBank,
      [name]: value
    }));
  }



  //Neet be fix

  const submitQuestionBank = () => {
    const FieldCategorys = { FieldCategory }
    const SkillCategorys = { SkillCategory }
    const CutOffMark = { CutOffMarks }
    const KeySkill = { KeySkills }

    if (
      questionBack.questionBankName === "" ||
      questionBack.courseName === "" ||
      questionBack.courseId === "" ||
      questionBack.qualifyingTestId === "" ||
      questionBack.lawOrPolicy === "" ||
      FieldCategory === "" ||
      SkillCategory === "" ||
      CutOffMark === "" ||
      KeySkills.length === 0
    ) {
      Notification("warning", "Please fill up all the fields!")
      return;
    }
    dispatch(CreateQuestionBank({
      ...questionBack,
      ...CutOffMark,
      ...FieldCategorys,
      ...SkillCategorys,
      ...KeySkill
    }))
    setQuestionBankBox(false)
    setFieldCategory([])
    setQuestionBank([])
    setSkillCategory([])
    setOffMarks([])
    setKeySkills([])
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }



  const handleNext = () => {
    if (currentField < 4) {
      setCurrentField((prevField) => prevField + 1);
    }
  };

  const handlePrev = () => {
    if (currentField > 0) {
      setCurrentField((prevField) => prevField - 1);
    }
  };

  const deleteQuestionBank = () => {
    if (!selectedQuestionBank) {
      Notification("error", "Question Bank not selected!")
      setQuestionBankDeleteBox(false)
      return
    }
    Axios.post(process.env.REACT_APP_BASE_URL + "/tool1/QuestionBank/delete", {
      questionBankId: selectedQuestionBank,
    }).then(() => {
      Notification("warning", "Question Bank deleted successfully!")
      dispatch(fetchTool1())
      setQuestionBankDeleteBox(false)
    }).catch((err) => {
      Notification("error", err?.response?.data?.message || "Something went wrong!")
    })
  }


  // Update QuestionBank
  const [updateQuestionBankData, setUpdateQuestionBankData] = useState({})

  const handelUpdateQuestionBank = (event) => {
    const { name, value } = event.target;
    setUpdateQuestionBankData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleQuestionBankEdit = () => {
    if (!selectedQuestionBank) {
      Notification("error", "Question Bank not selected!")
      setQuestionBankDeleteBox(false)
      return
    } else {
      setQuestionBankEditable(!questionBankEditable)
    }
  }

  const postQuestionBankData = () => {
    const BankId = { questionBankId: selectedQuestionBank }
    dispatch(UpdateQuestionBank({ ...updateQuestionBankData, ...BankId }))
    setQuestionBankEditable(false)
    setTimeout(() => {
      setUpdateQuestionBankData([])
      window.location.reload();
    }, 1000);
  }




  useEffect(() => {
    if (CareerSummery?.length === 0) {
      dispatch(fetchCareerSummery())
    }
  }, [CareerSummery])

  console.log(Questions);

  return (
    <>
      <Box className="QuestionDetailsBox">

        <Box sx={{ display: deleteBox ? "flex" : "none" }} className="deleteConfirmationBox">
          <Box mt={2} className="DeleteMegBox">
            <Typography>Are you sure to delete this question?</Typography>
          </Box>
          <Box mt={3} className="deleteBtnBox">
            <BlueBtn buttonText="Cancel" onClick={closeDelBox} />
            <RedBtn buttonText="Delete" onClick={deleteQuestion} />
          </Box>
        </Box>

        <Box sx={{ display: questionBankDeleteBox ? "flex" : "none" }} className="deleteConfirmationBox">
          <Box mt={2} className="DeleteMegBox">
            <Typography>Are you sure to delete this Question Bank ?</Typography>
          </Box>
          <Box mt={3} className="deleteBtnBox">
            <BlueBtn buttonText="Cancel" onClick={() => setQuestionBankDeleteBox(false)} />
            <RedBtn buttonText="Delete" onClick={deleteQuestionBank} />
          </Box>
        </Box>

        <Box className="ViewQuestion">


          {/* Question Bank */}
          <Box className="selectQuestionBankBox">
            <Typography sx={{ textAlign: "left", color: "#fff" }} variant='h5'>QuestionBank:</Typography>
            <FormControl sx={{ m: 1, minWidth: 220 }}>
              <InputLabel htmlFor="grouped-select">Select</InputLabel>
              <Select
                sx={{ color: "#fff" }}
                defaultValue=""
                id="grouped-select"
                label="Select"
                onChange={handleSelectChange}
              >
                {
                  QuestionBank?.map((el) => (
                    <MenuItem key={el?._id} value={el?._id}>{el?.Name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <Button onClick={() => setQuestionBankDeleteBox(true)} variant="outlined" color="error">Delete</Button>
            <BlueBtn width="100px" buttonText={questionBankEditable ? "Cancel" : "Edit"} onClick={handleQuestionBankEdit} />
            <Button width="100px" variant="contained" disabled={!questionBankEditable} onClick={postQuestionBankData}>Update</Button>
          </Box>

          <Box className="questionBank_Info">
            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Question Bank Title</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.Name}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name='Name'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("Name") ? updateQuestionBankData.Name : Questions?.Name}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Programme Title</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CourseName}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name='CourseName'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty('CourseName') ? updateQuestionBankData.CourseName : Questions?.CourseName}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Programme ID</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CourseId}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="CourseId"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("CourseId") ? updateQuestionBankData.CourseId : Questions?.CourseId}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Qualifying Test ID</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.QualifyingTestId}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name='QualifyingTestId'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("QualifyingTestId") ? updateQuestionBankData.QualifyingTestId : Questions?.QualifyingTestId} />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Law or Policy Category</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.LawOrPolicy}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="LawOrPolicy"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("LawOrPolicy") ? updateQuestionBankData.LawOrPolicy : Questions?.LawOrPolicy}
              />
            </Box>
            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Cut-off %</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CutOffMarks ? Questions?.CutOffMarks + "%" : null}</span>
              <span style={{ display: questionBankEditable ? "block" : "none" }} className='persentageSing'>%</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="CutOffMarks"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("CutOffMarks") ? updateQuestionBankData.CutOffMarks : Questions?.CutOffMarks}
              />
            </Box>
            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Programme level</Typography>
              {
                Questions?.SkillCategory?.map(el => (
                  <span>{el},  </span>
                ))
              }
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography sx={{ width: "430px" }} variant='h6'>Legal field category</Typography>
              <Box className="lfBox">
                {
                  Questions?.FieldCategory?.map(el => (
                    <span>{el},  </span>
                  ))
                }
              </Box>
            </Box>


            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Programme Mentors</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CourseMentors ? Questions?.CourseMentors : null}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="CourseMentors"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("CourseMentors") ? updateQuestionBankData.CourseMentors : Questions?.CourseMentors}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Appointment URL</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.AppointmentUrl ? Questions?.AppointmentUrl : null}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="AppointmentUrl"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("AppointmentUrl") ? updateQuestionBankData.AppointmentUrl : Questions?.AppointmentUrl}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Notes URL</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.Notes ? Questions?.Notes : null}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="Notes"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("Notes") ? updateQuestionBankData.Notes : Questions?.Notes}
              />
            </Box>
            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Test Completion Time*</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.TestCompletionTime ? Questions?.TestCompletionTime : null} Months</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="TestCompletionTime"
                onChange={handelUpdateQuestionBank}
                type='Number'
                value={updateQuestionBankData.hasOwnProperty("TestCompletionTime") ? updateQuestionBankData.TestCompletionTime : Questions?.TestCompletionTime}
              />
              <p style={{ display: questionBankEditable ? "block" : "none" }} className='inputInstrucation'>* Value should be in minutes</p>
            </Box>

          </Box>






          {/* Create QuestionBank */}
          <Box className="questionBankCreateBtn">
            <BlueBtn buttonText={questionBackBox ? "Cancel" : "Create Question Bank"} onClick={handelCreateQBank} />
          </Box>

          <Box className="createQuestionBankBox" sx={{ display: questionBackBox ? "flex" : "none" }}>
            <Box className="QuestionFieldBox">
              <Typography ml={4} pb={2} variant='h5' sx={{ textAlign: "center" }}>Create QuestionBank</Typography>
              {currentField === 0 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Question Bank Name:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Enter your question bank name'
                      name="Name"
                      value={questionBack?.questionBankName}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }} >LegalField Category:</Typography>

                    <Box className="chipbox">
                      <select
                        className='ActiveDropdown'
                        value={FieldCategory}
                        name='FieldCategory'
                        onChange={(event) => handelCreateSkillCatagory(event)}
                      >
                        {
                          CareerSummery.map((el) => (
                            <option key={el._id} value={el.name}>{el.name}</option>
                          ))
                        }
                      </select>
                      <Paper
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          listStyle: 'none',
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul"
                      >
                        {FieldCategory?.map((data, index) => {
                          return (
                            <ListItem key={index}>
                              <Chip
                                label={data}
                                onDelete={handleLegalFieldDelete(data, "LegalFieldCategory")}
                              />
                            </ListItem>
                          );
                        })}
                      </Paper>
                    </Box>

                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Course Name:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Course Name'
                      name="CourseName"
                      value={questionBack?.courseName}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>
              )}
              {currentField === 1 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Course ID:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Course ID'
                      name="CourseId"
                      value={questionBack?.courseId}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Qualifying Test Id:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Qualifying Test Id'
                      name="QualifyingTestId"
                      value={questionBack?.qualifyingTestId}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Law or Policy Area:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Law or Policy Area'
                      name="LawOrPolicy"
                      value={questionBack?.lawOrPolicy}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>
              )}
              {currentField === 2 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Cut Off Mark:</Typography>
                    <select
                      className='ActiveDropdown'
                      name="CutOffMark"
                      value={CutOffMarks}
                      onChange={(e) => setOffMarks(e.target.value)}
                    >
                      {
                        cutOffMarksArr.map((el, index) => (
                          <option key={index} value={el}>{el}%</option>
                        ))
                      }
                    </select>
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Skill catagory:</Typography>
                    <Box className="chipbox">
                      <select
                        className='ActiveDropdown'
                        value={SkillCategory}
                        name='SkillCategory'
                        onChange={(event) => handelCreateSkillCatagory(event)}
                      >
                        {
                          skillCategoryList.map((el, index) => (
                            <option key={index} value={el}>{el}</option>
                          ))
                        }
                      </select>
                      <Paper
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          listStyle: 'none',
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul"
                      >
                        {SkillCategory?.map((data, index) => {
                          return (
                            <ListItem key={index}>
                              <Chip
                                label={data}
                                onDelete={handleLegalFieldDelete(data, "SkillCatagory")}
                              />
                            </ListItem>
                          );
                        })}
                      </Paper>
                    </Box>
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Course Mentors :</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Course Mentors'
                      name="CourseMentors"
                      value={questionBack?.CourseMentors}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>

              )}
              {currentField === 3 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Appointment Url :</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Appointment Url'
                      name="AppointmentUrl"
                      value={questionBack?.AppointmentUrl}
                      onChange={handleQuestionBank}
                    />
                  </Box>

                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Notes:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Notes Url'
                      name="Notes"
                      value={questionBack?.Notes}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Test Completion Time:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Test Completion Time'
                      name="TestCompletionTime"
                      value={questionBack?.TestCompletionTime}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>
              )}
              {currentField === 4 && (
                <Box className="QuestionBankInputItem">
                  <Typography sx={{ width: "180px" }}>Key Skills:</Typography>
                  <Box className="chipbox">
                    <TextField style={{ marginBottom: "10px" }} className='QuestionBankName' name='KeySkills' onChange={(e) => setKeySkillsVal(e.target.value)} value={KeySkillsVal} placeholder='Enter KeySkills' />
                    <BlueBtn disabled={!KeySkillsVal} buttonText="Add" onClick={handelCreateSkillCatagory} />
                    <Paper
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                        marginTop: "10px"
                      }}
                      component="ul"
                    >
                      {KeySkills?.map((data, index) => {
                        return (
                          <ListItem key={index}>
                            <Chip
                              label={data}
                              onDelete={handleLegalFieldDelete(data, "KeySkills")}
                            />
                          </ListItem>
                        );
                      })}
                    </Paper>
                  </Box>
                </Box>
              )}
              <Box className="QuestionBankButtonBox">
                <Button onClick={handlePrev} disabled={currentField === 0}>
                  Previous
                </Button>
                <Button onClick={handleNext} disabled={currentField === 4}>
                  Next
                </Button>
                <BlueBtn buttonText="Save" width="150px" disabled={!questionBack ? true : false} onClick={submitQuestionBank} />
              </Box>
            </Box>
            <Box className="cancelQBCB">
              <BlueBtn buttonText={questionBackBox ? "Cancel" : "Create Question Bank"} onClick={handelCreateQBank} />
            </Box>
          </Box>






          <Typography mt={5} sx={{ textAlign: "center", color: "#fff" }} variant='h5'>Add/ Modify / Delete the Questions and Answers as per requirements.</Typography>
          <Box className="tool3Create">
            <BlueBtn buttonText="Create Question" width="180px" onClick={handelCreateBox} />
          </Box>

          <Box className="EditQuestionBox">

            {/* create question box */}
            <Box sx={{ display: createQuestionBox ? "flex" : "none" }} className="questionCard createBox" >
              <Box className="field">
                <Box mb={2} className="FieldBox">
                  <Typography sx={{ width: "80px", color: "#fff" }}>Question:</Typography>
                  <TextField
                    className='questionField'
                    placeholder='Question'
                    name="Question"
                    value={createQuestionData.Question}
                    onChange={handleCreateQuestions}
                  />
                </Box>

                <Box className="FieldBox">
                  <Typography sx={{ width: "80px", color: "#fff" }}>Answer:</Typography>
                  <TextField
                    className='answerField'
                    placeholder='Right Answer'
                    name="Answer"
                    value={createQuestionData.Answer}
                    onChange={handleCreateQuestions}
                  />
                </Box>

                <Box className="FieldBox">
                  <Typography sx={{ width: "80px", color: "#fff" }}>Options:</Typography>
                  <Box className="optionBox">
                    <TextField className='option Oa' placeholder='Option-A' name="option_A" onChange={handleCreateOptions} value={createOption.option_A} />
                    <TextField className='option option-padding' placeholder='Option-B' name="option_B" onChange={handleCreateOptions} value={createOption.option_B} />
                    <TextField className='option option-padding' placeholder='Option-C' name="option_C" onChange={handleCreateOptions} value={createOption.option_C} />
                    <TextField className='option option-padding' placeholder='Option-D' name="option_D" onChange={handleCreateOptions} value={createOption.option_D} />
                  </Box>
                </Box>
              </Box>

              <Box className="fieldBtn">
                <Box my={1.5} className="Buttons">
                  <RedBtn buttonText="Cancel" onClick={handelCloseBox} />
                </Box>
                <Box my={1.5} className="Buttons">
                  <BlueBtn width="100px" buttonText="Save" onClick={handelQuestionCreate} />
                </Box>
              </Box>
            </Box>


            {/* render all question */}
            {status === "loading" ?
              <Box mt={2}>
                <Typography sx={{ color: "#fff" }}>Loading...</Typography>
              </Box> : status === "error" ? (
                <Box>
                  <Typography sx={{ color: "#fff" }}>Error occurred: Internal server error. / Please try sometime later.</Typography>
                </Box>
              ) :
                Questions?.QuestionsList?.map((el, index) => (
                  <Box className="questionCard" key={el?._id}>
                    <Box className="field">
                      <Typography>No: {index + 1}</Typography>
                      <Box mb={2} className="FieldBox">
                        <Typography sx={{ width: "80px" }}>Question:</Typography>
                        <TextField
                          name="Question"
                          disabled={!isEdit[index]}
                          className='questionField'
                          placeholder='Question'
                          onChange={(event) => handelUpdateQuestion(event, index)}
                          value={updateQuestionData[index] ? updateQuestionData[index].question : el?.Question}
                        />
                      </Box>

                      <Box className="FieldBox">
                        <Typography sx={{ width: "80px" }}>Answer:</Typography>
                        <TextField
                          name='Answer'
                          disabled={!isEdit[index]}
                          className='answerField'
                          placeholder='Right Answer'
                          onChange={(event) => handelUpdateQuestion(event, index)}
                          value={updateQuestionData[index] ? updateQuestionData[index].Answer : el?.Answer}
                        />
                      </Box>

                      <Box className="FieldBox">
                        <Typography sx={{ width: "80px" }}>Options:</Typography>
                        <Box className="optionBox">
                          <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_A" disabled={!isEdit[index]} className='option Oa' placeholder='Option-A' value={updateOption[index] ? updateOption[index].option_A : el?.Options[0]} />
                          <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_B" disabled={!isEdit[index]} className='option option-padding' placeholder='Option-B' value={updateOption[index] ? updateOption[index].option_B : el?.Options[1]} />
                          <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_C" disabled={!isEdit[index]} className='option option-padding' placeholder='Option-C' value={updateOption[index] ? updateOption[index].option_C : el?.Options[2]} />
                          <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_D" disabled={!isEdit[index]} className='option option-padding' placeholder='Option-D' value={updateOption[index] ? updateOption[index].option_D : el?.Options[3]} />
                        </Box>
                      </Box>
                    </Box>

                    <Box className="fieldBtn">
                      <Box my={1.5} className="Buttons">
                        <BlueBtn width="100px" buttonText={isEdit[index] ? "Cancel" : "Edit"} onClick={() => {
                          toggleEdit(index)
                          for (let i = 0; i < isEdit.length; i++) {
                            if (i !== index && isEdit[i]) {
                              disableFields(i);
                            }
                          }
                        }} />
                      </Box>
                      <Box my={1.5} className="Buttons">
                        <RedBtn buttonText="Delete" onClick={() => handleDeleteQuestionBox(el?._id)} />
                      </Box>
                      <Box my={1.5} className="Buttons">
                        <BlueBtn
                          width="100px"
                          buttonText="Save"
                          onClick={(e) =>
                            postUpdateQuestion(e, index, el?._id, el)}
                          disabled={index === trueIndex ? false : true}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}

          </Box>

        </Box>

      </Box>
    </>
  )
}
