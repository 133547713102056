import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, styled, FormControl, Select, Button, MenuItem, InputLabel } from '@mui/material';
import { BlueBtn, RedBtn } from '../../components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from "../../App"
import { fetchTool2, deleteQuestionById, createQuestion, UpdateQuestionBank } from "../../store/Tool2Slice";
import { QuestionBankCategory } from "./Tool3"
import { fetchCareerSummery } from "../../store/UserSlice";
import Axios from "axios";
import "./style.css"
import "./questionDetails.css";

import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const skillCategoryList = ["Learn", "Skill", "Grow", "Excel", "Theorise", "Visualise", "Develop", "Analyse"]



const LegalFields = [
  "Commercial Law",
  "Civil Procedure Law",
  "Intellectual Property Law",
  "Administrative/Service Law",
  "Law & Public Policy",
  "Criminal Law",
  "International Trade Law",
  "Tax and Finance Law",
  "Intellectual Property Law",
  "Technology Law",
  "Alternative Dispute Resolution",
  "Startup Law",
  "Public Infrastructure Law",
  "Environmental Law",
  "Banking Law",
  "Law & Public Policy",
  "Policy",
  "Administrative/Service Law",
  "Public Health Law",
  "Market and Consumer Law",
  "Litigation Law",
  "Law & Public Policy",
  "Competition Law",
  "Investment Law",
  "Procedure Law"
];

const SkillsData = [
  "Academic",
  "Problem Solver",
  "Investigator",
]


export default function Tool2() {
  const { data, status } = useSelector((state) => state.tool2);
  const { CareerSummery } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const Tool2 = data?.data ? data.data[0]?.QuestionsBanks : [];
  const QuestionBank = data?.data ? data.data[0]?.QuestionsBanks : [];
  const [selectedQuestionBank, setSelectedQuestionBank] = useState('');
  let Questions = QuestionBank.length > 0 ? QuestionBank.find((obj) => obj._id === selectedQuestionBank) : [];


  //create Legal field
  const [LCO0, setLCO0] = useState([]);
  const [LCO1, setLCO1] = useState([]);
  const [LCO2, setLCO2] = useState([]);
  const [LCO3, setLCO3] = useState([]);

  //Update legal field
  const [UPLCO0, setUPLCO0] = useState([]);
  const [UPLCO1, setUPLCO1] = useState([]);
  const [UPLCO2, setUPLCO2] = useState([]);
  const [UPLCO3, setUPLCO3] = useState([]);

  // create skills
  const [SC0, setSC0] = useState([])
  const [SC1, setSC1] = useState([])
  const [SC2, setSC2] = useState([])
  const [SC3, setSC3] = useState([])

  //Update skills
  const [UPSC0, setUPSC0] = useState([])
  const [UPSC1, setUPSC1] = useState([])
  const [UPSC2, setUPSC2] = useState([])
  const [UPSC3, setUPSC3] = useState([])


  const [currentField, setCurrentField] = useState(0);
  const [categorySelector, setCategorySelector] = useState("");
  const [questionBackBox, setQuestionBankBox] = useState(false)
  const [questionBacName, setQuestionBankName] = useState("")
  const [questionBankDeleteBox, setQuestionBankDeleteBox] = useState(false)
  const [LegalFieldCategoryValue, setLegalFieldCategoryValue] = useState([]);
  const [sikillCategoryValue, setSkillCategoryValue] = useState([]);

  // const [delQuestionBox, seDelQuestionBox] = useState(false)
  const [deleteBox, setDeleteBox] = useState(false)
  const [createQuestionBox, setCreateQuestionBox] = useState(false);
  const [isEdit, setIsEdit] = useState([])
  const [questionId, setQuestionId] = useState("")
  const [legalFieldsVal, setLegalFieldsVal] = useState("")
  const [skillVal, setSkillVal] = useState("")

  const [createQuestionData, setCreateQuestionData] = useState({
    Question: "",
  });
  const [createOption, setCreateOption] = useState({
    option_A: "",
    option_B: "",
    option_C: "",
    option_D: ""
  })
  const [updateQuestionData, setUpdateQuestionData] = useState({
    Question: ""
  });
  const [updateOption, setUpdateOption] = useState([])

  const [questionBack, setQuestionBank] = useState({
    questionBankName: "",
    courseName: "",
    courseId: "",
    qualifyingTestId: "",
    lawOrPolicy: "",
    FieldCategory: "",
    SkillCategory: "",
    CourseMentors: "",
  })


  let trueIndex = -1;
  for (let i = 0; i < isEdit.length; i++) {
    if (isEdit[i] === true) {
      trueIndex = i;
      break;
    }
  }

  //Create Question
  const handelQuestionCreate = () => {
    if (
      createQuestionData.Question === "" ||
      createOption.option_A === "" ||
      createOption.option_B === "" ||
      createOption.option_C === "" ||
      createOption.option_D === ""
    ) {
      Notification("warning", "Please fill up all the fields!")
      return;
    }
    if (!selectedQuestionBank) return Notification("error", "Question Bank not selected!")
    if (LCO0.length === 0 || LCO1.length === 0 || LCO2.length === 0 || LCO3.length === 0) {
      Notification("warning", "Legal fields should not be empty!")
      return;
    }
    if (SC0.length === 0 || SC1.length === 0 || SC2.length === 0 || SC3.length === 0) {
      Notification("warning", "Skills should not be empty!")
      return;
    }
    dispatch(createQuestion({
      selectedQuestionBank,
      createQuestionData,
      createOption,
      LCO0,
      LCO1,
      LCO2,
      LCO3,
      SC0,
      SC1,
      SC2,
      SC3,
    })).then(() => {
      Notification("success", "One question added successfully")
      setCreateQuestionData({ Question: "" });
      setCreateOption({ option_A: "", option_B: "", option_C: "", option_D: "", })
      setLCO0([]);
      setLCO1([]);
      setLCO2([]);
      setLCO3([]);
      setSC0([])
      setSC1([])
      setSC2([])
      setSC3([])
      setCreateQuestionBox(false)
      dispatch(fetchTool2())
    }).catch((err) => {
      Notification("error", err?.response?.data?.message || "Something went wrong!")
    })
  }


  //Questions
  const handleCreateQuestions = (event) => {
    const { name, value } = event.target;
    setCreateQuestionData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  //Options
  const handleCreateOptions = (event) => {
    const { name, value } = event.target;
    setCreateOption((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const disableFields = (index) => {
    setIsEdit((prevIsEdit) => {
      const updatedIsEdit = [...prevIsEdit];
      updatedIsEdit[index] = false;
      return updatedIsEdit;
    });
  };

  //Update Question
  const handelUpdateQuestion = (event, index) => {
    const { name, value } = event.target;
    setUpdateQuestionData((prevData) => ({
      ...prevData,
      [index]: { ...prevData[index], [name]: value }
    }));
  }
  const handelUpdateOption = (event, index) => {
    const { name, value } = event.target;
    setUpdateOption((prevData) => ({
      ...prevData,
      [index]: { ...prevData[index], [name]: value }
    }));

  }

  const postUpdateQuestion = (e, index, questionId, el) => {
    let question = updateQuestionData[index]?.Question || updateQuestionData?.Question || el?.Question;
    let option_A = updateOption[index]?.option_A || el?.Options[index]?.optionA?.text;
    let option_B = updateOption[index]?.option_B || el?.Options[index]?.optionB?.text;
    let option_C = updateOption[index]?.option_C || el?.Options[index]?.optionC?.text;
    let option_D = updateOption[index]?.option_D || el?.Options[index]?.optionD?.text;




    return
    Axios.post("http://localhost:5000/api/v1/tool1/Question/updateById", {
      questionBankId: selectedQuestionBank,
      questionId,
      question,
      options: [option_A, option_B, option_C, option_D,]
    }).then(() => {
      Notification("success", "Question update successfully!")
      setIsEdit([])
      handelCancel()
      dispatch(fetchTool2())
    }).catch((err) => {
      console.log(err);
      Notification("error", err)
    })
  }

  //Delete Question
  const closeDelBox = () => setDeleteBox(false);
  const handleDeleteQuestionBox = (id) => {
    setQuestionId(id)
    setDeleteBox(true)
  }

  const deleteQuestion = () => {
    setDeleteBox(false)
    if (!selectedQuestionBank || !questionId) {
      Notification("error", "Question Bank not selected!")
      setDeleteBox(false)
      return
    } else {
      dispatch(deleteQuestionById({ selectedQuestionBank, questionId }))
      setTimeout(() => {
        Notification("success", "One Question deleted successfully!")
        dispatch(fetchTool2())
      }, 1000);
    }
  }


  const handelCancel = (index) => {
    setUpdateQuestionData({ Question: "" });
    setUpdateOption([])
  };

  // Edit Question
  const toggleEdit = (index) => {
    handelCancel(index)
    setIsEdit((prevIsEdit) => {
      const updatedIsEdit = [...prevIsEdit];
      updatedIsEdit[index] = !prevIsEdit[index];

      if (updatedIsEdit[index]) {
        setUpdateQuestionData((prevData) => ({
          ...prevData,
          Question: Tool2[0][index]?.question || ""
        }));
        setUpdateOption((prevData) => ({
          ...prevData,
          option_A: Tool2[0][index]?.Options[0] || "",
          option_B: Tool2[0][index]?.Options[1] || "",
          option_C: Tool2[0][index]?.Options[2] || "",
          option_D: Tool2[0][index]?.Options[3] || "",
        }));
        setUPLCO0(...UPLCO0, Questions?.QuestionsList[index]?.Options[0]?.optionA?.legal_fields)
        setUPLCO1(...UPLCO1, Questions?.QuestionsList[index]?.Options[0]?.optionB?.legal_fields)
        setUPLCO2(...UPLCO2, Questions?.QuestionsList[index]?.Options[0]?.optionC?.legal_fields)
        setUPLCO3(...UPLCO3, Questions?.QuestionsList[index]?.Options[0]?.optionD?.legal_fields)
      }
      return updatedIsEdit;
    });


  };

  const handelCreateBox = () => {
    setCreateQuestionBox(true)
  }
  const handelCloseBox = () => {
    setCreateQuestionBox(false)
    setCreateQuestionData({ Question: "", Answer: "" });
    setCreateOption({ option_A: "", option_B: "", option_C: "", option_D: "", })
  }

  const handleSelectChange = (event) => setSelectedQuestionBank(event.target.value);

  ///Question Bank 
  const handelCreateQBank = () => {
    setQuestionBankBox(!questionBackBox)
    setQuestionBankName("")
  }
  //Create questionBank
  const handelCreateSkillCatagory = (e) => {
    const { name, value } = e.target;
    if (name === "LegalFieldCategory") {
      if (!LegalFieldCategoryValue.includes(value)) {
        setLegalFieldCategoryValue([...LegalFieldCategoryValue, value]);
      }
    }

    if (name === "SkillCatagory") {
      if (!sikillCategoryValue.includes(value)) {
        setSkillCategoryValue([...sikillCategoryValue, value]);
      }
    }

  }
  const handleQuestionBank = (e) => {
    const { name, value } = e.target;
    setQuestionBank((prevQuestionBank) => ({
      ...prevQuestionBank,
      [name]: value
    }));
  }



  const submitQuestionBank = () => {
    if (
      questionBack.questionBankName === "" ||
      questionBack.courseName === "" ||
      questionBack.courseId === "" ||
      questionBack.qualifyingTestId === "" ||
      questionBack.lawOrPolicy === "" ||
      LegalFieldCategoryValue === "" ||
      sikillCategoryValue === "" ||
      LegalFieldCategoryValue === ""
    ) {
      Notification("warning", "Please fill up all the fields!")
      return;
    }

    Axios.post(process.env.REACT_APP_BASE_URL + "/tool2/QuestionBank/create", {
      Name: questionBack.questionBankName,
      CourseName: questionBack.courseName,
      CourseId: questionBack.courseId,
      QualifyingTestId: questionBack.qualifyingTestId,
      LawOrPolicy: questionBack.lawOrPolicy,
      FieldCategory: LegalFieldCategoryValue,
      SkillCategory: sikillCategoryValue,
      questionsList: []
    }).then(() => {
      Notification("success", "QuestionBank created successfully!")
      handelCreateQBank()
      dispatch(fetchTool2())
    }).catch((err) => {
      Notification("error", err?.response?.data?.message || "Something went wrong!")
    })
  }



  const handleNext = () => {
    if (currentField < 3) {
      setCurrentField((prevField) => prevField + 1);
    }
  };

  const handlePrev = () => {
    if (currentField > 0) {
      setCurrentField((prevField) => prevField - 1);
    }
  };

  const deleteQuestionBank = () => {
    if (!selectedQuestionBank) {
      Notification("error", "Question Bank not selected!")
      setQuestionBankDeleteBox(false)
      return
    }
    Axios.post(process.env.REACT_APP_BASE_URL + "/tool2/QuestionBank/delete", {
      questionBankId: selectedQuestionBank,
    }).then(() => {
      Notification("warning", "Question Bank deleted successfully!")
      dispatch(fetchTool2())
      setQuestionBankDeleteBox(false)
    }).catch((err) => {
      Notification("error", err?.response?.data?.message || "Something went wrong!")
    })
  }

  //handel legal fields 
  const handelCreateLegalFields = (event) => {
    const { name, value } = event.target;
    if (name === "option_A") {
      if (!LCO0.includes(value)) {
        setLCO0([...LCO0, value]);
      }
    }
    if (name === "option_B") {
      if (!LCO1.includes(value)) {
        setLCO1([...LCO1, value]);
      }
    }
    if (name === "option_C") {
      if (!LCO2.includes(value)) {
        setLCO2([...LCO2, value]);
      }
    }
    if (name === "option_D") {
      if (!LCO3.includes(value)) {
        setLCO3([...LCO3, value]);
      }
    }
  }
  //delete create_legal fields
  const handleLegalFieldDelete = (chipToDelete, Q) => () => {
    if (Q === "LegalFieldCategory") {
      setLegalFieldCategoryValue(LegalFieldCategoryValue.filter(item => item !== chipToDelete))

    }
    if (Q === "SkillCatagory") {
      setSkillCategoryValue(sikillCategoryValue.filter(item => item !== chipToDelete))
    }
  }

  //handel skills 
  const handelCreateSkills = (event) => {
    const { name, value } = event.target;
    if (name === "option_A") {
      if (!SC0.includes(value)) {
        setSC0([...SC0, value]);
      }
    }
    if (name === "option_B") {
      if (!SC1.includes(value)) {
        setSC1([...SC1, value]);
      }
    }
    if (name === "option_C") {
      if (!SC2.includes(value)) {
        setSC2([...SC2, value]);
      }
    }
    if (name === "option_D") {
      if (!SC3.includes(value)) {
        setSC3([...SC3, value]);
      }
    }
  }
  const handleSkillFieldDelete = (chipToDelete, Q) => () => {
    if (Q === "SCA") {
      setSC0(SC0.filter(item => item !== chipToDelete))
    }
    if (Q === "SCB") {
      setSC1(SC1.filter(item => item !== chipToDelete))
    }
    if (Q === "SCC") {
      setSC2(SC2.filter(item => item !== chipToDelete))
    }
    if (Q === "SCD") {
      setSC3(SC3.filter(item => item !== chipToDelete))
    }
  };

  //delete update_legal fields
  const handelUpdateLegalFields = (event, index) => {
    const { name, value } = event.target;
    if (name === "option_A") {
      if (!UPLCO0.includes(value)) {
        setUPLCO0([...UPLCO0, value]);
      }
    }
    if (name === "option_B") {
      if (!UPLCO1.includes(value)) {
        setUPLCO1([...UPLCO1, value]);
      }
    }
    if (name === "option_C") {
      if (!UPLCO2.includes(value)) {
        setUPLCO2([...UPLCO2, value]);
      }
    }
    if (name === "option_D") {
      if (!UPLCO3.includes(value)) {
        setUPLCO3([...UPLCO3, value]);
      }
    }
  }








  const handleDelete = () => { }

  const handelLegalFields = (event, index) => {
    const { name, value } = event.target;
    setLegalFieldsVal((prevData) => ({
      ...prevData,
      [index]: { ...prevData[index], [name]: value }
    }));
  }

  const handelSkillFields = (event, index) => {
    const { name, value } = event.target;
    setSkillVal((prevData) => ({
      ...prevData,
      [index]: { ...prevData[index], [name]: value }
    }));
  }



  // Update QuestionBank
  const [questionBankEditable, setQuestionBankEditable] = useState(false)
  const [updateQuestionBankData, setUpdateQuestionBankData] = useState({})

  const handelUpdateQuestionBank = (event) => {
    const { name, value } = event.target;
    setUpdateQuestionBankData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleQuestionBankEdit = () => {
    if (!selectedQuestionBank) {
      Notification("error", "Question Bank not selected!")
      setQuestionBankDeleteBox(false)
      return
    } else {
      setQuestionBankEditable(!questionBankEditable)
    }
  }

  const postQuestionBankData = () => {
    const BankId = { questionBankId: selectedQuestionBank }
    dispatch(UpdateQuestionBank({ ...updateQuestionBankData, ...BankId }))
    setQuestionBankEditable(false)
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }




  useEffect(() => {
    dispatch(fetchTool2())
    if (CareerSummery?.length === 0) {
      // dispatch(fetchCareerSummery())
    }
  }, [CareerSummery])

  return (
    <>
      <Box className="QuestionDetailsBox">

        <Box sx={{ display: deleteBox ? "flex" : "none" }} className="deleteConfirmationBox">
          <Box mt={2} className="DeleteMegBox">
            <Typography>Are you sure to delete this question?</Typography>
          </Box>
          <Box mt={3} className="deleteBtnBox">
            <BlueBtn buttonText="Cancel" onClick={closeDelBox} />
            <RedBtn buttonText="Delete" onClick={deleteQuestion} />
          </Box>
        </Box>

        <Box sx={{ display: questionBankDeleteBox ? "flex" : "none" }} className="deleteConfirmationBox">
          <Box mt={2} className="DeleteMegBox">
            <Typography>Are you sure to delete this Question Bank ?</Typography>
          </Box>
          <Box mt={3} className="deleteBtnBox">
            <BlueBtn buttonText="Cancel" onClick={() => setQuestionBankDeleteBox(false)} />
            <RedBtn buttonText="Delete" onClick={deleteQuestionBank} />
          </Box>
        </Box>

        <Box className="ViewQuestion">
          <Box className="selectQuestionBankBox">
            <Typography sx={{ textAlign: "left", color: "#fff" }} variant='h5'>QuestionBank:</Typography>
            <FormControl sx={{ m: 1, minWidth: 220 }}>
              <InputLabel htmlFor="grouped-select">Select</InputLabel>
              <Select
                sx={{ color: "#fff" }}
                defaultValue=""
                id="grouped-select"
                label="Select"
                onChange={handleSelectChange}
              >
                {
                  QuestionBank?.map((el) => (
                    <MenuItem key={el?._id} value={el?._id}>{el?.Name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <Button onClick={() => setQuestionBankDeleteBox(true)} variant="outlined" color="error">Delete</Button>
            <BlueBtn width="100px" buttonText={questionBankEditable ? "Cancel" : "Edit"} onClick={handleQuestionBankEdit} />
            <Button width="100px" variant="contained" disabled={!questionBankEditable} onClick={postQuestionBankData}>Update</Button>
          </Box>

          <Box className="questionBank_Info">

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>QuestionBank Name:</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.Name}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name='Name'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("Name") ? updateQuestionBankData.Name : Questions?.Name}
              />
            </Box>
            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Course Name:</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CourseName}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name='CourseName'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty('CourseName') ? updateQuestionBankData.CourseName : Questions?.CourseName}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Course Id:</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.CourseId}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="CourseId"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("CourseId") ? updateQuestionBankData.CourseId : Questions?.CourseId}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Qualifying Test Id:</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.QualifyingTestId}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name='QualifyingTestId'
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("QualifyingTestId") ? updateQuestionBankData.QualifyingTestId : Questions?.QualifyingTestId} />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Law Or Policy:</Typography>
              <span style={{ display: questionBankEditable ? "none" : "block" }}>{Questions?.LawOrPolicy}</span>
              <TextField
                sx={{ display: questionBankEditable ? "block" : "none" }}
                name="LawOrPolicy"
                onChange={handelUpdateQuestionBank}
                value={updateQuestionBankData.hasOwnProperty("LawOrPolicy") ? updateQuestionBankData.LawOrPolicy : Questions?.LawOrPolicy}
              />
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Legal field catagory: </Typography>
              {
                Questions?.FieldCategory?.map(el => (
                  <span>{el},  </span>
                ))
              }
            </Box>

            <Box className="questionBank_InfoItem">
              <Typography variant='h6'>Skill catagory: </Typography>
              {
                Questions?.SkillCategory?.map(el => (
                  <span>{el},  </span>
                ))
              }
            </Box>
          </Box>


          {/* Create QuestionBank */}
          <Box className="questionBankCreateBtn">
            <BlueBtn buttonText={questionBackBox ? "Cancel" : "Create Question Bank"} onClick={handelCreateQBank} />
          </Box>
          <Box className="createQuestionBankBox" sx={{ display: questionBackBox ? "flex" : "none" }}>
            <Box className="QuestionFieldBox">
              <Typography ml={4} pb={2} variant='h5' sx={{ textAlign: "center" }}>Create QuestionBank</Typography>
              {currentField === 0 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Question Bank Name:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Enter your question bank name'
                      name="questionBankName"
                      value={questionBack?.questionBankName}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }} >LegalField Category:</Typography>
                    <Box className="chipbox">
                      <select
                        style={{ color: "#fff" }}
                        className='ActiveDropdown'
                        value={LegalFieldCategoryValue}
                        name='LegalFieldCategory'
                        onChange={(event) => handelCreateSkillCatagory(event)}
                      >
                        {
                          CareerSummery.map((el) => (
                            <option key={el._id} value={el.name}>{el.name}</option>
                          ))
                        }
                      </select>
                      <Paper
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          listStyle: 'none',
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul"
                      >
                        {LegalFieldCategoryValue?.map((data, index) => {
                          return (
                            <ListItem key={index}>
                              <Chip
                                label={data}
                                onDelete={handleLegalFieldDelete(data, "LegalFieldCategory")}
                              />
                            </ListItem>
                          );
                        })}
                      </Paper>
                    </Box>

                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Course Name:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Course Name'
                      name="courseName"
                      value={questionBack?.courseName}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>
              )}
              {currentField === 1 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Course ID:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Course ID'
                      name="courseId"
                      value={questionBack?.courseId}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Qualifying Test Id:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Qualifying Test Id'
                      name="qualifyingTestId"
                      value={questionBack?.qualifyingTestId}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Law or Policy Area:</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Law or Policy Area'
                      name="lawOrPolicy"
                      value={questionBack?.lawOrPolicy}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>
              )}
              {currentField === 2 && (
                <>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Skill catagory:</Typography>

                    <Box className="chipbox">
                      <select
                        style={{ color: "#fff" }}
                        className='ActiveDropdown'
                        value={sikillCategoryValue}
                        name='SkillCatagory'
                        onChange={(event) => handelCreateSkillCatagory(event)}
                      >
                        {
                          skillCategoryList.map((el, index) => (
                            <option key={index} value={el}>{el}</option>
                          ))
                        }
                      </select>
                      <Paper
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap',
                          listStyle: 'none',
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul"
                      >
                        {sikillCategoryValue?.map((data, index) => {
                          return (
                            <ListItem key={index}>
                              <Chip
                                label={data}
                                onDelete={handleLegalFieldDelete(data, "SkillCatagory")}
                              />
                            </ListItem>
                          );
                        })}
                      </Paper>
                    </Box>
                  </Box>
                  <Box className="QuestionBankInputItem">
                    <Typography sx={{ width: "180px" }}>Course Mentors :</Typography>
                    <TextField
                      className='QuestionBankName'
                      placeholder='Course Mentors'
                      name="CourseMentors "
                      value={questionBack?.CourseMentors}
                      onChange={handleQuestionBank}
                    />
                  </Box>
                </>
              )}

              <Box className="QuestionBankButtonBox">
                <Button onClick={handlePrev} disabled={currentField === 0}>
                  Previous
                </Button>
                <Button onClick={handleNext} disabled={currentField === 2}>
                  Next
                </Button>
                <BlueBtn buttonText="Save" width="150px" disabled={!questionBack ? true : false} onClick={submitQuestionBank} />
              </Box>
            </Box>
            <Box className="cancelQBCB">
              <BlueBtn buttonText={questionBackBox ? "Cancel" : "Create Question Bank"} onClick={handelCreateQBank} />
            </Box>
          </Box>


          <Typography sx={{ textAlign: "center", color: "#fff" }} variant='h5'>Add/ Modify / Delete the Questions and Answers as per requirements.</Typography>
          <Box className="tool3Create">
            <BlueBtn buttonText="Create Question" width="180px" onClick={handelCreateBox} />
          </Box>

          <Box className="EditQuestionBox">




            {/* create question box */}
            <Box sx={{ display: createQuestionBox ? "flex" : "none" }} className="questionCard createBox" >
              <Box className="field">
                <Box mb={2} className="FieldBox">
                  <Typography sx={{ width: "80px" }}>Question:</Typography>
                  <TextField
                    className='questionField'
                    placeholder='Question'
                    name="Question"
                    value={createQuestionData.Question}
                    onChange={handleCreateQuestions}
                  />
                </Box>
                <Box className="FieldBox">
                  <Typography sx={{ width: "80px" }}>Options:</Typography>
                  <Box className="optionContainer">

                    {/* Option A */}
                    <Box className="optionBox">
                      <TextField onChange={handleCreateOptions} name="option_A" className='option Oa' placeholder='Option-A' value={createOption.option_A} />
                      <Box className="optionFields">
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">legal Fields</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='option_A'
                            id="grouped-select"
                            label="legal Fields"
                            value={LCO0}
                            onChange={(event) => handelCreateLegalFields(event, 0)}
                          >
                            {
                              LegalFields.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                        <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {LCO0?.map((data, index) => {
                            return (
                              <ListItem key={index}>
                                <Chip
                                  label={data}
                                  onDelete={handleLegalFieldDelete(data, "COA")}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>

                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">Select skill</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='option_A'
                            id="grouped-select"
                            label="Select skill"
                            value={SC0}
                            onChange={(event) => handelCreateSkills(event)}
                          >
                            {
                              SkillsData.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                        <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {SC0?.map((data, index) => {
                            return (
                              <ListItem key={index}>
                                <Chip
                                  label={data}
                                  onDelete={handleSkillFieldDelete(data, "SCA")}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>

                      </Box>
                    </Box>

                    {/* Option -B */}
                    <Box className="optionBox">
                      <TextField onChange={handleCreateOptions} name="option_B" className='option option-padding' placeholder='Option-B' value={createOption.option_B} />
                      <Box className="optionFields">
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">legal Fields</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='option_B'
                            id="grouped-select"
                            label="legal Fields"
                            value={LCO1}
                            onChange={(event) => handelCreateLegalFields(event, 1)}
                          >
                            {
                              LegalFields.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                        <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {LCO1?.map((data, index) => {
                            return (
                              <ListItem key={index}>
                                <Chip
                                  label={data}
                                  onDelete={handleLegalFieldDelete(data, "COB")}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>

                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">Select skill</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='option_B'
                            id="grouped-select"
                            label="Select skill"
                            value={SC1}
                            onChange={(event) => handelCreateSkills(event)}
                          >
                            {
                              SkillsData.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                        <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {SC1.map((data, index) => {
                            return (
                              <ListItem key={index}>
                                <Chip
                                  label={data}
                                  onDelete={handleSkillFieldDelete(data, "SCB")}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>
                      </Box>
                    </Box>

                    {/* Option -C */}
                    <Box className="optionBox">
                      <TextField onChange={handleCreateOptions} name="option_C" className='option option-padding' placeholder='Option-C' value={createOption.option_C} />
                      <Box className="optionFields">
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">legal Fields</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='option_C'
                            id="grouped-select"
                            label="legal Fields"
                            value={LCO2}
                            onChange={(event) => handelCreateLegalFields(event, 2)}
                          >
                            {
                              LegalFields.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                        <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {LCO2?.map((data, index) => {
                            return (
                              <ListItem key={index}>
                                <Chip
                                  label={data}
                                  onDelete={handleLegalFieldDelete(data, "COC")}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>

                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">Select skill</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='option_C'
                            id="grouped-select"
                            label="Select skill"
                            value={SC2}
                            onChange={(event) => handelCreateSkills(event)}
                          >
                            {
                              SkillsData.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                        <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {SC2.map((data, index) => {
                            return (
                              <ListItem key={index}>
                                <Chip
                                  label={data}
                                  onDelete={handleSkillFieldDelete(data, "SCC")}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>
                      </Box>
                    </Box>
                    {/* Option-D */}
                    <Box className="optionBox">
                      <TextField onChange={handleCreateOptions} name="option_D" className='option option-padding' placeholder='Option-d' value={createOption.option_D} />
                      <Box className="optionFields">
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">legal Fields</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='option_D'
                            id="grouped-select"
                            label="legal Fields"
                            value={LCO3}
                            onChange={(event) => handelCreateLegalFields(event, 3)}
                          >
                            {
                              LegalFields.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                        <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {LCO3?.map((data, index) => {
                            return (
                              <ListItem key={index}>
                                <Chip
                                  label={data}
                                  onDelete={handleLegalFieldDelete(data, "COD")}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>

                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                          <InputLabel htmlFor="grouped-select">Select skill</InputLabel>
                          <Select
                            sx={{ color: "#fff" }}
                            name='option_D'
                            id="grouped-select"
                            label="Select skill"
                            value={SC3}
                            onChange={(event) => handelCreateSkills(event)}
                          >
                            {
                              SkillsData.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>

                        <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                          }}
                          component="ul"
                        >
                          {SC3.map((data, index) => {
                            return (
                              <ListItem key={index}>
                                <Chip
                                  label={data}
                                  onDelete={handleSkillFieldDelete(data, "SCD")}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="fieldBtn">
                <Box my={1.5} className="Buttons">
                  <RedBtn buttonText="Cancel" onClick={handelCloseBox} />
                </Box>
                <Box my={1.5} className="Buttons">
                  <BlueBtn width="100px" buttonText="Save" onClick={handelQuestionCreate} />
                </Box>
              </Box>
            </Box>



            {/* Render questions */}
            {status === "loading" ?
              <Box mt={2}>
                <Typography sx={{ color: "#fff" }}>Loading...</Typography>
              </Box> : status === "error" ? (
                <Box sx={{ color: "#fff" }}>
                  <Typography>Error occurred: Internal server error. / Please try again.</Typography>
                </Box>
              ) :
                Questions?.QuestionsList?.map((el, index) => (
                  <Box className="questionCard" key={el?._id}>
                    <Box className="field">
                      <Typography sx={{ color: "#fff" }} variant=''>No: {index + 1}</Typography>
                      <Box mb={2} className="FieldBox">
                        <Typography sx={{ width: "80px" }}>Question:</Typography>
                        <TextField
                          name="Question"
                          disabled={!isEdit[index]}
                          className='questionField'
                          placeholder='Question'
                          onChange={(event) => handelUpdateQuestion(event, index)}
                          value={updateQuestionData[index] ? updateQuestionData[index].question : el?.Question}
                        />
                      </Box>

                      <Box className="FieldBox">
                        <Typography sx={{ width: "80px" }}>Options:</Typography>
                        <Box className="optionContainer">

                          {/* Option A */}
                          <Box className="optionBox">
                            <TextField
                              onChange={(event) => handelUpdateOption(event, index)}
                              name="option_A" disabled={!isEdit[index]}
                              className='option Oa' placeholder='Option-A'
                              value={updateOption[index] ? updateOption[index].option_A : el?.Options[0].optionA?.text} />

                            <Box className="optionFields">
                              <FormControl sx={{ m: 1, minWidth: 220 }}>
                                <InputLabel htmlFor="grouped-select">legal Fields</InputLabel>
                                <Select
                                  sx={{ color: "#fff" }}
                                  name='optionA'
                                  disabled={!isEdit[index]}
                                  id="grouped-select"
                                  label="legal Fields"
                                  // value={UPLCO0 || ""}
                                  onChange={(event) => handelUpdateLegalFields(event, index)}
                                >
                                  {
                                    LegalFields.map((el, index) => (
                                      <MenuItem key={index} value={el}>{el}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>

                              <Paper
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexWrap: 'wrap',
                                  listStyle: 'none',
                                  p: 0.5,
                                  m: 0,
                                }}
                                component="ul"
                              >
                                {UPLCO0.length > 0 ? (
                                  UPLCO0.map((data, index) => (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                        onDelete={() => handleDelete(data)}
                                      />
                                    </ListItem>
                                  ))
                                ) : (
                                  el?.Options[0]?.optionA?.legal_fields?.map((data, index) => (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                      />
                                    </ListItem>
                                  ))
                                )}
                              </Paper>

                              <FormControl sx={{ m: 1, minWidth: 220 }}>
                                <InputLabel htmlFor="grouped-select">Select skill</InputLabel>
                                <Select
                                  sx={{ color: "#fff" }}
                                  name='legalFields'
                                  disabled={!isEdit[index]}
                                  defaultValue={el?.Options[0].optionA?.skill}
                                  id="grouped-select"
                                  label="Select skill"
                                  value={skillVal.skill}
                                  onChange={(event) => handelSkillFields(event, index)}
                                >
                                  {
                                    SkillsData.map((el) => (
                                      <MenuItem key={el} value={el}>{el}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>

                              <Paper
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexWrap: 'wrap',
                                  listStyle: 'none',
                                  p: 0.5,
                                  m: 0,
                                }}
                                component="ul"
                              >
                                {el?.Options[0].optionA?.skill?.map((data, index) => {
                                  return (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                        onDelete={handleDelete(data)}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </Paper>
                            </Box>
                          </Box>

                          {/* Option -B */}
                          <Box className="optionBox">
                            <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_B" disabled={!isEdit[index]} className='option option-padding' placeholder='Option-B' value={updateOption[index] ? updateOption[index].option_B : el?.Options[0].optionB?.text} />
                            <Box className="optionFields">
                              <FormControl sx={{ m: 1, minWidth: 220 }}>
                                <InputLabel htmlFor="grouped-select">legal Fields</InputLabel>
                                <Select
                                  sx={{ color: "#fff" }}
                                  name='optionB'
                                  disabled={!isEdit[index]}
                                  // defaultValue={el?.Pattern}
                                  id="grouped-select"
                                  label="legal Fields"
                                  // value={UPLCO1}
                                  onChange={(event) => handelUpdateLegalFields(event, index)}
                                >
                                  {
                                    LegalFields.map((el) => (
                                      <MenuItem key={el} value={el}>{el}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>

                              <Paper
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexWrap: 'wrap',
                                  listStyle: 'none',
                                  p: 0.5,
                                  m: 0,
                                }}
                                component="ul"
                              >
                                {UPLCO1.length > 0 ? (
                                  UPLCO1.map((data, index) => (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                        onDelete={() => handleDelete(data)}
                                      />
                                    </ListItem>
                                  ))
                                ) : (
                                  el?.Options[0]?.optionB?.legal_fields?.map((data, index) => (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                      />
                                    </ListItem>
                                  ))
                                )}
                              </Paper>

                              <FormControl sx={{ m: 1, minWidth: 220 }}>
                                <InputLabel htmlFor="grouped-select">Select skill</InputLabel>
                                <Select
                                  sx={{ color: "#fff" }}
                                  name='legalFields'
                                  disabled={!isEdit[index]}
                                  defaultValue={el?.Options[0].optionA?.skill}
                                  id="grouped-select"
                                  label="Select skill"
                                  value={skillVal.skill}
                                  onChange={(event) => handelSkillFields(event, index)}
                                >
                                  {
                                    SkillsData.map((el) => (
                                      <MenuItem key={el} value={el}>{el}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>

                              <Paper
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexWrap: 'wrap',
                                  listStyle: 'none',
                                  p: 0.5,
                                  m: 0,
                                }}
                                component="ul"
                              >
                                {el?.Options[0].optionB?.skill?.map((data, index) => {
                                  return (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                        onDelete={handleDelete(data)}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </Paper>
                            </Box>
                          </Box>

                          {/* Option -C */}
                          <Box className="optionBox">
                            <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_C" disabled={!isEdit[index]} className='option option-padding' placeholder='Option-C' value={updateOption[index] ? updateOption[index].option_C : el?.Options[0].optionC?.text} />
                            <Box className="optionFields">
                              <FormControl sx={{ m: 1, minWidth: 220 }}>
                                <InputLabel htmlFor="grouped-select">legal Fields</InputLabel>
                                <Select
                                  sx={{ color: "#fff" }}
                                  name='optionC'
                                  disabled={!isEdit[index]}
                                  // defaultValue={el?.Pattern}
                                  id="grouped-select"
                                  label="legal Fields"
                                  // value={UPLCO2}
                                  onChange={(event) => handelUpdateLegalFields(event, index)}
                                >
                                  {
                                    LegalFields.map((el) => (
                                      <MenuItem key={el} value={el}>{el}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>

                              <Paper
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexWrap: 'wrap',
                                  listStyle: 'none',
                                  p: 0.5,
                                  m: 0,
                                }}
                                component="ul"
                              >
                                {UPLCO2.length > 0 ? (
                                  UPLCO2.map((data, index) => (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                        onDelete={() => handleDelete(data)}
                                      />
                                    </ListItem>
                                  ))
                                ) : (
                                  el?.Options[0]?.optionC?.legal_fields?.map((data, index) => (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                      />
                                    </ListItem>
                                  ))
                                )}
                              </Paper>

                              <FormControl sx={{ m: 1, minWidth: 220 }}>
                                <InputLabel htmlFor="grouped-select">Select skill</InputLabel>
                                <Select
                                  sx={{ color: "#fff" }}
                                  name='legalFields'
                                  disabled={!isEdit[index]}
                                  defaultValue={el?.Options[0].optionA?.skill}
                                  id="grouped-select"
                                  label="Select skill"
                                  value={skillVal.skill}
                                  onChange={(event) => handelSkillFields(event, index)}
                                >
                                  {
                                    SkillsData.map((el) => (
                                      <MenuItem key={el} value={el}>{el}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>

                              <Paper
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexWrap: 'wrap',
                                  listStyle: 'none',
                                  p: 0.5,
                                  m: 0,
                                }}
                                component="ul"
                              >
                                {el?.Options[0].optionC?.skill?.map((data, index) => {
                                  return (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                        onDelete={handleDelete(data)}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </Paper>
                            </Box>
                          </Box>
                          {/* Option-D */}
                          <Box className="optionBox">
                            <TextField onChange={(event) => handelUpdateOption(event, index)} name="option_D" disabled={!isEdit[index]} className='option option-padding' placeholder='Option-D' value={updateOption[index] ? updateOption[index].option_D : el?.Options[0].optionD?.text} />
                            <Box className="optionFields">
                              <FormControl sx={{ m: 1, minWidth: 220 }}>
                                <InputLabel htmlFor="grouped-select">legal Fields</InputLabel>
                                <Select
                                  sx={{ color: "#fff" }}
                                  name='optionD'
                                  disabled={!isEdit[index]}
                                  // defaultValue={el?.Pattern}
                                  id="grouped-select"
                                  label="legal Fields"
                                  // value={UPLCO3}
                                  onChange={(event) => handelUpdateLegalFields(event, index)}
                                >
                                  {
                                    LegalFields.map((el) => (
                                      <MenuItem key={el} value={el}>{el}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>

                              <Paper
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexWrap: 'wrap',
                                  listStyle: 'none',
                                  p: 0.5,
                                  m: 0,
                                }}
                                component="ul"
                              >
                                {UPLCO3.length > 0 ? (
                                  UPLCO3.map((data, index) => (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                        onDelete={() => handleDelete(data)}
                                      />
                                    </ListItem>
                                  ))
                                ) : (
                                  el?.Options[0]?.optionD?.legal_fields?.map((data, index) => (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                      />
                                    </ListItem>
                                  ))
                                )}
                              </Paper>

                              <FormControl sx={{ m: 1, minWidth: 220 }}>
                                <InputLabel htmlFor="grouped-select">Select skill</InputLabel>
                                <Select
                                  sx={{ color: "#fff" }}
                                  name='legalFields'
                                  disabled={!isEdit[index]}
                                  defaultValue={el?.Options[0].optionA?.skill}
                                  id="grouped-select"
                                  label="Select skill"
                                  value={skillVal.skill}
                                  onChange={(event) => handelSkillFields(event, index)}
                                >
                                  {
                                    SkillsData.map((el) => (
                                      <MenuItem key={el} value={el}>{el}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>

                              <Paper
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexWrap: 'wrap',
                                  listStyle: 'none',
                                  p: 0.5,
                                  m: 0,
                                }}
                                component="ul"
                              >
                                {el?.Options[0].optionD?.skill?.map((data, index) => {
                                  return (
                                    <ListItem key={index}>
                                      <Chip
                                        label={data}
                                        onDelete={handleDelete(data)}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </Paper>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="fieldBtn">
                      <Box my={1.5} className="Buttons">
                        <BlueBtn width="100px" buttonText={isEdit[index] ? "Cancel" : "Edit"} onClick={() => {
                          toggleEdit(index)
                          for (let i = 0; i < isEdit.length; i++) {
                            if (i !== index && isEdit[i]) {
                              disableFields(i);
                            }
                          }
                        }} />
                      </Box>
                      <Box my={1.5} className="Buttons">
                        <RedBtn buttonText="Delete" onClick={() => handleDeleteQuestionBox(el?._id)} />
                      </Box>
                      <Box my={1.5} className="Buttons">
                        <BlueBtn
                          width="100px"
                          buttonText="Save"
                          onClick={(e) =>
                            postUpdateQuestion(e, index, el?._id, el)}
                          disabled={index === trueIndex ? false : true}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}

          </Box>

        </Box>

      </Box>
    </>
  )
}
