import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { updateToolMarks, getUserId, fetchUser } from "../../../store/UserSlice"
import { fetchTool2 } from "../../../store/Tool2Slice";
import { useNavigate } from 'react-router-dom';
import "./style.css"



//Component
import NavBar from "../../../components/ClientNavBar"
import Footer from '../../../components/Footer';
import { BlueBtn } from '../../../components/Buttons';
import { TestNoteBox } from "../Tool3QuestionSet"

export default function Tool2QuestionSet() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLog = localStorage.getItem("userString");
  if (!userLog) navigate("/")

  const { User, UserId } = useSelector((state) => state.user);
  const { data, status } = useSelector((state) => state.tool2);
  const questionBank = data?.data ? data.data[0]?.QuestionsBanks : [];
  let questions = questionBank.length > 0 ? questionBank.find((obj) => obj._id === "64e9c82afb567981c38374ad") : {};

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [optionIndex, setOptionIndex] = useState()
  const [chosenLegalField, setChosenLegalField] = useState([])
  const [chosenSkill, setChosenSkill] = useState([])


  const handleSubmitQuestion = () => {
    if (currentQuestionIndex < questions?.QuestionsList?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }

    const storedDataJSON = localStorage.getItem("tool2LegalField");
    let LegalField = []
    if (storedDataJSON) {
      LegalField = JSON.parse(storedDataJSON);
    }
    // Update LegalField
    chosenLegalField.forEach(value => {
      const existingItem = LegalField.find(item => item.name === value);
      if (existingItem) {
        existingItem.count++;
      } else {
        LegalField.push({ name: value, count: 1 });
      }
    });
    const dataArrayJSON = JSON.stringify(LegalField);
    localStorage.setItem("tool2LegalField", dataArrayJSON);

    // skills
    let skills = []
    const storedDataSkillJSON = localStorage.getItem("tool2Skills");
    if (storedDataSkillJSON) {
      skills = JSON.parse(storedDataSkillJSON);
    }
    chosenSkill.forEach(value => {
      const existingSkillItem = skills.find(item => item.name.toUpperCase().trim() === value.toUpperCase().trim());
      if (existingSkillItem) {
        existingSkillItem.count++;
      } else {
        skills.push({ name: value, count: 1 });
      }
    });

    const dataArraySkillJSON = JSON.stringify(skills);
    localStorage.setItem("tool2Skills", dataArraySkillJSON);




    if (currentQuestionIndex === questions?.QuestionsList?.length - 1) {

      if (userLog) {
        dispatch(updateToolMarks({
          email: User?.userData?.email,
          TestName: "Tool2",
          LegalFieldArr: JSON.parse(localStorage.getItem("tool2LegalField")),
          skillsArr: JSON.parse(localStorage.getItem("tool2Skills"))
        }))
      }

      setTimeout(() => {
        localStorage.removeItem("tool2Skills")
        localStorage.removeItem("tool2LegalField")
        navigate("/user/profile")
      }, 300);
    }



    setOptionIndex("")
  };

  const getOptionValue = (legal_fields, skill) => {
    setChosenLegalField(legal_fields)
    setChosenSkill(skill)
  }


  useEffect(() => {
    if (!UserId) {
      dispatch(getUserId());
    }
    if (!User.length && UserId) {
      dispatch(fetchUser({ id: UserId }));
    }
    if (data.length === 0) dispatch(fetchTool2())
  }, [UserId, User.length])


  return (
    <>
      <NavBar />
      <Box className="Tool2QuestionSetContainer">
        <Box className="toolHeaderUpbox TestPageHeader" sx={{ width: "100%" }}>
          <Box className="toolHeaderbox" sx={{ width: "460px", display: "flex", justifyContent: "center" }}>
            <Typography sx={{ textAlign: "center" }} className='toolHeaderText' variant='h5' mb={2}>
              <span className='Vl'>VLiGTA </span>
              <span style={{ left: "140px" }} className='Rl'>®</span>
              <span style={{ bottom: -64, textAlign: "center" }} className='R_l'>Choose your option based on your relatability</span>
              <span className='Rm_T'>App Skill Evaluator</span>
            </Typography>
          </Box>
        </Box>


        <Box className="questionBox">
          {questions?.QuestionsList && (
            <>
              <Box className="questionItemBox">
                <Typography className='Tool2Question' ><span className='questionIndexLabel Avenir_Black'> Question {currentQuestionIndex + 1}</span> <br />{questions.QuestionsList[currentQuestionIndex]?.Question}</Typography>
              </Box>
              <Box my={2} className="options_box">

                <Box className={optionIndex === "A" ? "options_boxItem OptionTextActive" : "options_boxItem"}>
                  <Typography className='OptionTextNum TextColor_p' >A </Typography>
                  <Typography
                    onClick={(e) => {
                      setOptionIndex('A')
                      getOptionValue(questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionA?.legal_fields, questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionA?.skill)
                    }}
                    className='OptionText tool2Options'> {questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionA?.text}</Typography>
                </Box>
                <Box className={optionIndex === "B" ? "options_boxItem OptionTextActive" : "options_boxItem"}>
                  <Typography className='OptionTextNum TextColor_p' >B </Typography>
                  <Typography
                    onClick={(e) => {
                      setOptionIndex('B')
                      getOptionValue(questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionB?.legal_fields, questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionB?.skill)
                    }}
                    className="OptionText tool2Options"> {questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionB?.text}</Typography>
                </Box>
                <Box className={optionIndex === "C" ? "options_boxItem OptionTextActive" : "options_boxItem"}>
                  <Typography className='OptionTextNum TextColor_p' >C </Typography>
                  <Typography
                    onClick={(e) => {
                      setOptionIndex('C')
                      getOptionValue(questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionC?.legal_fields, questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionC?.skill)
                    }}
                    className="OptionText tool2Options"> {questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionC?.text}</Typography>
                </Box>
                <Box className={optionIndex === "D" ? "options_boxItem OptionTextActive" : "options_boxItem"}>
                  <Typography className='OptionTextNum TextColor_p'>D </Typography>
                  <Typography
                    onClick={(e) => {
                      setOptionIndex('D')
                      getOptionValue(questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionD?.legal_fields, questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionD?.skill)
                    }}
                    className="OptionText tool2Options">  {questions?.QuestionsList[currentQuestionIndex]?.Options[0]?.optionD?.text}</Typography>
                </Box>
              </Box>
            </>
          )}
          <BlueBtn buttonText="Submit" onClick={handleSubmitQuestion} />
        </Box>
        {TestNoteBox()}

      </Box >
      <Footer />
    </>
  )
}

