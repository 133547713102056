import { Box, Typography } from '@mui/material'
import React from 'react'

export default function Tool4() {
  return (
    <>
      <Box className="QuestionDetailsBox">
        <Typography variant='h4' sx={{ textAlign: "center", color: "#fff" }}>Tool-4 on progress</Typography>
      </Box>
    </>
  )
}
