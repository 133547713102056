import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, TextField, keyframes } from '@mui/material'
import { useNavigate } from "react-router-dom"
import "./style.css";
import { updateEventSummery, createEventSummery, deleteEventSummery, fetchAllUser, fetchEventSummery, fetchCareerSummery, updateCareerSummery, createCareerSummery } from "../../store/UserSlice";
import { useSelector, useDispatch } from 'react-redux';
import { BlueBtn, RedBtn } from '../../components/Buttons';
import { Notification } from "../../App"



export default function Summery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AdminLog = localStorage.getItem("login session");
  if (!AdminLog) navigate("/administrator/login");
  const [eventDeleteBox, setEventDeleteBox] = useState(false);
  const [createEventBox, setCreateEventBox] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState();
  const [createEventVal, setCreateEventVal] = useState({ skill: "", skillVal: "" });
  const { EventSummery, CareerSummery } = useSelector((state) => state.user);
  const [eventEdit, setEventEdit] = useState([]);
  const [updateEventData, setUpdateEventData] = useState({ eventValue: "" });

  const [updateAcademic, setUpdateAcademic] = useState({});
  const [updateInvestigator, setUpdateInvestigator] = useState({});
  const [updateProblemSolver, setUpdateProblemSolver] = useState({});

  const [createCareerBox, setCareerBox] = useState(false);
  const [legalField, setLegalField] = useState("");


  useEffect(() => {
    if (EventSummery?.length === 0) {
      dispatch(fetchEventSummery())
      dispatch(fetchCareerSummery())
    }
  }, [EventSummery])

  const initialTextAreaValues = EventSummery?.map((el) => ({
    [el.name]: { text1: "", text2: "", text3: "" }
  }));
  const [textAreaValues, setTextAreaValues] = useState(initialTextAreaValues);


  const handleTextAreaChange = (event, index, fieldName, eventname) => {
    const { value } = event.target;
    setTextAreaValues((prevValues) =>
      prevValues.map((item) => {
        if (item.hasOwnProperty(eventname)) {
          const updatedItem = { ...item };
          updatedItem[eventname] = {
            ...updatedItem[eventname],
            [fieldName]: value,
          };
          return updatedItem;
        }
        return item;
      })
    );
  };

  const postCareerSummery = () => {
    const convertedObject = {};
    textAreaValues.forEach((item, index) => {
      convertedObject[index] = item;
    });
    dispatch(createCareerSummery({
      name: legalField,
      roles: convertedObject
    }))

    Notification("success", "Legal field created successfully!");
    setTimeout(() => {
      // window.location.reload();
    }, 1000);
  }

  const toggleEdit = (index) => {
    setEventEdit((prevEventEdit) => {
      if (prevEventEdit.includes(index)) {
        return prevEventEdit.filter((item) => item !== index);
      } else {
        return [...prevEventEdit, index];
      }
    });
  };


  //Create Event summery
  const handelCreateEvent = (e) => {
    const { name, value } = e.target;
    setCreateEventVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const CreateEvent = () => {
    if (createEventVal.skill !== "" && createEventVal.skillVal !== "") {
      dispatch(createEventSummery({
        name: createEventVal.skill,
        values: createEventVal.skillVal
      }))
      Notification("success", "Event summery update successfully!");
      setTimeout(() => {
        // window.location.reload();
      }, 1000);
    } else {
      Notification("warning", "Please fill all the values!");
    }
  }

  //Update Event summery
  const handelUpdateEvent = (event, index) => {
    const { name, value } = event.target;
    setUpdateEventData((prevData) => ({
      ...prevData,
      [index]: { ...prevData[index], [name]: value }
    }));
  }
  const updateEvent = (el, updateEventData, index) => {
    const id = el?._id;
    const values = updateEventData[index]?.eventValue;
    if (values) {
      if (values !== el.values) {
        if (id && values) {
          dispatch(updateEventSummery({ id, values }))
          Notification("success", "Event summery update successfully!")
        }
      }
    }
    setEventEdit([])
  }

  //Delete Event summery
  const DeleteEventPopUp = (el) => {
    setDeleteEventId(el?._id);
    setEventDeleteBox(true)
  }
  const handelDeleteEventSummery = () => {
    Notification("error", "Event summery delete successfully!");
    setEventDeleteBox(false);
    dispatch(deleteEventSummery({ id: deleteEventId }))
    setTimeout(() => {
      dispatch(fetchEventSummery())
    }, 400);
  }


  //update career summery
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (e.target.id === "Academic") {
      setUpdateAcademic((prevData) => ({
        ...prevData,
        [index]: { ...prevData[index], [name]: value }
      }))
    }
    if (e.target.id === "Investigator") {
      setUpdateInvestigator((prevData) => ({
        ...prevData,
        [index]: { ...prevData[index], [name]: value }
      }))
    }

    if (e.target.id === "ProblemSolver") {
      setUpdateProblemSolver((prevData) => ({
        ...prevData,
        [index]: { ...prevData[index], [name]: value }
      }))
    }
  }

  const HandelUpdateCareerSummery = (el, index) => {
    const roleId = el?._id

    if (Object.keys(updateAcademic).length != 0) {
      const careerVal = {
        text1: updateAcademic[index].AcademicText1 ? updateAcademic[index].AcademicText1 : el?.roles?.Academic?.text1,
        text2: updateAcademic[index].AcademicText2 ? updateAcademic[index].AcademicText2 : el?.roles?.Academic?.text2,
        text3: updateAcademic[index].AcademicText3 ? updateAcademic[index].AcademicText3 : el?.roles?.Academic?.text3
      }
      dispatch(updateCareerSummery({
        roleId,
        skillName: "Academic",
        careerVal
      }))
      Notification("success", "Career summery update successfully!")
      setUpdateAcademic({})
    }

    if (Object.keys(updateInvestigator).length != 0) {
      const careerVal = {
        text1: updateInvestigator[index].InvestigatorText1 ? updateInvestigator[index].InvestigatorText1 : el?.roles?.Investigator?.text1,
        text2: updateInvestigator[index].InvestigatorText2 ? updateInvestigator[index].InvestigatorText2 : el?.roles?.Investigator?.text2,
        text3: updateInvestigator[index].InvestigatorText3 ? updateInvestigator[index].InvestigatorText3 : el?.roles?.Investigator?.text3
      }
      dispatch(updateCareerSummery({
        roleId,
        skillName: "Investigator",
        careerVal
      }))
      Notification("success", "Career summery update successfully!")
      setUpdateInvestigator({})
    }

    if (Object.keys(updateProblemSolver).length != 0) {
      const careerVal = {
        text1: updateProblemSolver[index].ProblemSolverText1 ? updateProblemSolver[index].ProblemSolverText1 : el?.roles?.ProblemSolver?.text1,
        text2: updateProblemSolver[index].ProblemSolverText2 ? updateProblemSolver[index].ProblemSolverText2 : el?.roles?.ProblemSolver?.text2,
        text3: updateProblemSolver[index].ProblemSolverText3 ? updateProblemSolver[index].ProblemSolverText3 : el?.roles?.ProblemSolver?.text3
      }
      dispatch(updateCareerSummery({
        roleId,
        skillName: "ProblemSolver",
        careerVal
      }))
      Notification("success", "Career summery update successfully!")
      setUpdateProblemSolver({})
      // window.location.reload();
    }

  }


  return (
    <>
      <Box className="summeryContainer">
        <Box className="summeryHeaderBox">
          <Box className="toolHeaderUpbox">
            <Box className="toolHeaderbox toolHeaderboxT2">
              <Typography className='toolHeaderText' variant='h5' mb={2}>
                <span className='Vl'>VLiGTA </span>
                <span className='Rl'>®</span>
                <span className='Rm_T'>App Skill Evaluator</span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: eventDeleteBox ? "flex" : "none" }} className="deleteConfirmationBox">
          <Box mt={2} className="DeleteMegBox">
            <Typography>Are you sure to delete this Event summery?</Typography>
          </Box>
          <Box mt={3} className="deleteBtnBox">
            <BlueBtn onClick={() => setEventDeleteBox(false)} buttonText="Cancel" />
            <RedBtn onClick={() => handelDeleteEventSummery()} buttonText="Delete" />
          </Box>
        </Box>

        <Box className="CareerSummeryBox">
          <Typography className='summeryHeader'>Career Recommendations</Typography>
          <BlueBtn onClick={() => setCareerBox(!createCareerBox)} buttonText={createCareerBox ? "Cancel" : "Create"} />
          <Box sx={{ display: createCareerBox ? "flex" : "none" }} className="CreateCareerSummery">,
            <textarea onChange={(e) => setLegalField(e?.target?.value)} placeholder='Enter Legal field' />
            {
              EventSummery.map((el, index) => (
                <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography className='summeryLabel'>{el?.name}</Typography>
                  <textarea placeholder='text 1'
                    value={textAreaValues[index]?.text1}
                    onChange={(e) => handleTextAreaChange(e, index, "text1", el?.name)}
                  />
                  <textarea placeholder='text 2'
                    value={textAreaValues[index]?.text2}
                    onChange={(e) => handleTextAreaChange(e, index, "text2", el?.name)}
                  />
                  <textarea placeholder='text 3'
                    value={textAreaValues[index]?.text3}
                    onChange={(e) => handleTextAreaChange(e, index, "text3", el?.name)}
                  />
                </Box>
              ))
            }
            < BlueBtn onClick={postCareerSummery} buttonText="Create" />
          </Box>
          {
            CareerSummery?.map((el, index) => (
              <Box key={el._id} className="careerSummeryItem">
                <Typography className='summeryLabel' variant='h6'>Academic</Typography>

                <textarea name='AcademicText1' className='CareerInput' id="Academic" onChange={(e) => handleChange(e, index)} value={updateAcademic[index] ? updateAcademic[index].AcademicText1 : el?.roles?.Academic?.text1} />
                <textarea name='AcademicText2' className='CareerInput' id="Academic" onChange={(e) => handleChange(e, index)} value={updateAcademic[index] ? updateAcademic[index].AcademicText2 : el?.roles?.Academic?.text2} /><br />
                <textarea name='AcademicText3' className='CareerInput' id="Academic" onChange={(e) => handleChange(e, index)} value={updateAcademic[index] ? updateAcademic[index].AcademicText3 : el?.roles?.Academic?.text3} />

                <Typography className='summeryLabel' mt={2} variant='h6'>Investigator:</Typography>
                <textarea name='InvestigatorText1' className='CareerInput' id="Investigator" onChange={(e) => handleChange(e, index)} value={updateInvestigator[index] ? updateInvestigator[index].InvestigatorText1 : el?.roles?.Investigator?.text1} />
                <textarea name='InvestigatorText2' className='CareerInput' id="Investigator" onChange={(e) => handleChange(e, index)} value={updateInvestigator[index] ? updateInvestigator[index].InvestigatorText2 : el?.roles?.Investigator?.text2} /><br />
                <textarea name='InvestigatorText3' className='CareerInput' id="Investigator" onChange={(e) => handleChange(e, index)} value={updateInvestigator[index] ? updateInvestigator[index].InvestigatorText3 : el?.roles?.Investigator?.text3} />

                <Typography className='summeryLabel' mt={2} variant='h6' >Problem Solver: </Typography>
                <textarea name='ProblemSolverText1' className='CareerInput' id='ProblemSolver' onChange={(e) => handleChange(e, index)} value={updateProblemSolver[index] ? updateProblemSolver[index]?.ProblemSolverText1 : el?.roles?.ProblemSolver?.text1} />
                <textarea name='ProblemSolverText2' className='CareerInput' id='ProblemSolver' onChange={(e) => handleChange(e, index)} value={updateProblemSolver[index] ? updateProblemSolver[index]?.ProblemSolverText2 : el?.roles?.ProblemSolver?.text2} /><br />
                <textarea name='ProblemSolverText3' className='CareerInput' id='ProblemSolver' onChange={(e) => handleChange(e, index)} value={updateProblemSolver[index] ? updateProblemSolver[index]?.ProblemSolverText3 : el?.roles?.ProblemSolver?.text3} /><br />

                <BlueBtn buttonText="Update" onClick={() => HandelUpdateCareerSummery(el, index)} />
              </Box>
            ))
          }
        </Box>

        <Box className="EventSummeryBox">
          <Typography className='summeryLabel summeryHeader'>Skill Archetypes</Typography>

          <BlueBtn buttonText={createEventBox ? "Cancel" : "Create"} onClick={() => setCreateEventBox(!createEventBox)} />
          <Box sx={{ display: createEventBox ? "block" : "none" }} className="eventSummeryItem">
            <TextField sx={{ marginBottom: "10px" }} name="skill" placeholder='Skill name' onChange={(e) => handelCreateEvent(e)} value={createEventVal?.skill} />
            <textarea
              name='skillVal'
              placeholder='Skill summery'
              onChange={(e) => handelCreateEvent(e)}
              value={createEventVal?.skillVal}
              className='eventSummeryTextBox'
            />
            <Box className="EventSummeryBtnBox">
              <BlueBtn onClick={CreateEvent} buttonText="Save" />
            </Box>
          </Box>
          {
            EventSummery?.map((el, index) => (
              <Box key={el._id} className="eventSummeryItem">
                <Typography className='summeryLabel' variant='h6'>{el?.name}</Typography>
                <textarea
                  disabled={!eventEdit.includes(index)}
                  name='eventValue'
                  onChange={e => handelUpdateEvent(e, index)}
                  value={updateEventData[index] ? updateEventData[index]?.eventValue : el?.values}
                  className='eventSummeryTextBox' />

                <Box className="EventSummeryBtnBox">
                  <BlueBtn onClick={() => toggleEdit(index)} buttonText={!eventEdit.includes(index) ? "Edit" : "Cancel"} />
                  <BlueBtn disabled={!eventEdit.includes(index)} onClick={() => updateEvent(el, updateEventData, index)} buttonText="Save" />
                  <RedBtn onClick={() => DeleteEventPopUp(el)} buttonText="Delete" />
                </Box>
              </Box>
            ))
          }
        </Box>
      </Box >
    </>
  )
}
