import Axios from "axios";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const { STATUSES } = require("./Tool3Slice");

const Tool1Slice = createSlice({
  name: "Tool2",
  initialState: {
    data: [],
    status: STATUSES.LOADING,
  },
  reducers: {
    get(state, action) {
      return state.data;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTool2.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchTool2.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchTool2.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { get } = Tool1Slice.actions;
export default Tool1Slice.reducer;

export const fetchTool2 = createAsyncThunk("get/tool2", async () => {
  const res = await fetch(process.env.REACT_APP_BASE_URL + "/tool2");
  const data = await res.json();
  return data;
});

export const deleteQuestionById = createAsyncThunk(
  "delete/tool2Question",
  async ({ selectedQuestionBank, questionId }) => {
    await Axios.post(
      process.env.REACT_APP_BASE_URL + "/tool2/Question/deleteById",
      {
        questionBankId: selectedQuestionBank,
        questionId,
      }
    );
  }
);

export const createQuestion = createAsyncThunk(
  "create/tool2Question",
  async ({
    selectedQuestionBank,
    createQuestionData,
    createOption,
    LCO0,
    LCO1,
    LCO2,
    LCO3,
    SC0,
    SC1,
    SC2,
    SC3,
  }) => {
    await Axios.post(
      process.env.REACT_APP_BASE_URL + "/tool2/Question/create",
      {
        questionBankId: selectedQuestionBank,
        question: createQuestionData.Question,
        options: [
          {
            optionA: {
              text: createOption.option_A,
              legal_fields: LCO0,
              skill: SC0,
            },
            optionB: {
              text: createOption.option_B,
              legal_fields: LCO1,
              skill: SC1,
            },
            optionC: {
              text: createOption.option_C,
              legal_fields: LCO2,
              skill: SC2,
            },
            optionD: {
              text: createOption.option_D,
              legal_fields: LCO3,
              skill: SC3,
            },
          },
        ],
      }
    );
  }
);
export const UpdateT2Instruction = createAsyncThunk(
  "post/instruction",
  async ({ data, id }) => {
    await Axios.post(
      process.env.REACT_APP_BASE_URL + "/tool2/QuestionBank/update",
      {
        toolId: id,
        newInstruction: data,
      }
    );
  }
);

export const UpdateQuestionBank = createAsyncThunk(
  "update/question bank",
  async (data) => {
    try {
      await Axios.post(
        process.env.REACT_APP_BASE_URL + "/tool2/QuestionBank/updateById",
        { ...data }
      );
      Notification("success", "Question bank updated successfully!");
    } catch (error) {
      Notification("error", "Something wrong happened");
      throw error;
    }
  }
);
