import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyOtp, deleteUser } from "../../store/UserSlice"
import "./style.css"

import Clogo from "../../Assets/Images/vligtaPro2.png"
import { BlueBtn } from '../../components/Buttons';
import { Notification } from '../../App';
import { GenerateRandomString } from "../../components/StringGenerator"
import Countdown from "../../components/Timer/timer"

export default function OTPVerify({ resOTP, newUserId, setResOTP, newUser }) {
  const userLog = localStorage.getItem("userString");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [OTPVal, setOTPVal] = useState("");
  if (!newUserId) navigate('/user/signUp')

  function decodeNumber(encodedNumber, secretKey) {
    return encodedNumber / secretKey;
  }
  const DcOTP = decodeNumber(resOTP, 7)

  const handelOTP = () => {
    console.log("inputOTP", OTPVal);
    console.log("dcodeOTP", DcOTP);

    if (DcOTP?.toString() !== OTPVal?.toString()) {
      return Notification("warning", "OTP does not match!");
    } else {
      Notification("success", "You have logged in successfully");
      const userString = GenerateRandomString();
      localStorage.setItem("userString", userString)
      localStorage.setItem("userId", newUserId)
      console.log("comingUser id", newUserId);
      dispatch(verifyOtp({
        userId: newUserId,
        userString
      }))
      navigate("/")
    }
  }

  const handleCountdownComplete = () => {
    if (DcOTP?.toString() === OTPVal?.toString()) {
      return;
    } else {
      setResOTP(null)
      if (newUser === "new") {
        console.log("old user");
        dispatch(deleteUser({ userId: newUserId }))
        localStorage.removeItem("userId")
        localStorage.removeItem("userString")
        navigate("/user/signUp")
      } else {
        console.log("old user");
        localStorage.removeItem("userId")
        localStorage.removeItem("userString")
        navigate("/user/signUp")
      }
    }
  }

  useEffect(() => {
    if (userLog) {
      navigate("/")
    }
  })

  return (
    <>
      <Box className="loginContainer">
        <Box className="loginInnerContainer">
          <Box className="companyLogo">
            <Link to={"/"}>
              <img src={Clogo} />
            </Link>
          </Box>
          <Typography className='loginHeader' variant='h5'>Please check your email (mailbox) and enter the OTP here.</Typography>
          <TextField sx={{ textAlign: "center" }} name='OTP' className='loginInput' placeholder='Enter OTP' value={OTPVal} onChange={(e) => setOTPVal(e.target.value)} />
          <Countdown onComplete={handleCountdownComplete} />
          <BlueBtn buttonText="Submit" onClick={handelOTP} />
        </Box>
      </Box>
    </>
  )
}
