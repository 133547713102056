import Axios from "axios";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const { STATUSES } = require("./Tool3Slice");

const Tool4Slice = createSlice({
  name: "Tool4",
  initialState: {
    data: [],
    status: STATUSES.LOADING,
  },
  reducers: {
    get(state, action) {
      return state.data;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTool4.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchTool4.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchTool4.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { get } = Tool4Slice.actions;
export default Tool4Slice.reducer;

export const fetchTool4 = createAsyncThunk("get/tool4", async () => {
  const res = await fetch(process.env.REACT_APP_BASE_URL + "/tool4");
  const data = await res.json();
  return data;
});

export const UpdateT4Instruction = createAsyncThunk(
  "post/instruction",
  async ({ data, id }) => {
    await Axios.post(
      process.env.REACT_APP_BASE_URL + "/tool4/QuestionBank/update",
      {
        toolId: id,
        newInstruction: data,
      }
    );
  }
);
