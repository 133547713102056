import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Button, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminList, deleteAdmin } from "../../store/UserSlice";
import { useNavigate } from "react-router-dom"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import "./adminProfile.css";
import { BlueBtn, RedBtn } from '../../components/Buttons';

export default function AdminProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const data = localStorage.getItem('login session');
  const user = JSON.parse(data)
  const { AdminList } = useSelector((state) => state.user);
  const Admin = AdminList?.data?.AdminData || []
  const [deleteBox, setDeleteBox] = useState(false)
  const [deleteAdminId, setDeleteAdminId] = useState()


  useEffect(() => {
    if (!AdminList?.data?.AdminData) {
      dispatch(getAdminList())
    }
  })

  const handelClickDelteAdmin = (id) => {
    setDeleteBox(true)
    setDeleteAdminId(id)
  }

  const handelDeleteAdmin = () => {
    console.log(deleteAdminId);
    dispatch(deleteAdmin(deleteAdminId)).then(() => {
      setDeleteBox(false)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
  }

  return (
    <>
      <Box className="ProfileContainer">

        <Box sx={{ display: deleteBox ? "flex" : "none" }} className="deleteConfirmationBox">
          <Box mt={2} className="DeleteMegBox">
            <Typography>Are you sure to delete this question?</Typography>
          </Box>
          <Box mt={3} className="deleteBtnBox">
            <BlueBtn buttonText="Cancel" onClick={() => setDeleteBox(false)} />
            <RedBtn buttonText="Delete" onClick={handelDeleteAdmin} />
          </Box>
        </Box>

        <Typography className='adminProfileHeader'>Admin Profile</Typography>
        <Box my={1}>
          <Typography className='adminInFoText'>{user?.name}</Typography>
        </Box>
        <Box mb={1}>
          <Typography className='adminInFoText'>{user?.role}</Typography>
        </Box>
        <Box>
          <Typography className='adminInFoText'>Admin Status: {user?.Active ? "Active" : "InActive"}</Typography>
        </Box>
        <Box className="AdminListBox">
          <BlueBtn onClick={() => navigate("/administrator/logIn")} buttonText="Add Admin" />
          {Admin &&
            Admin?.map((el) => (
              <Box className="AdminRow">
                <Typography>Name: {el?.name}</Typography>
                <Typography>Email: {el?.email}</Typography>
                <Typography>Role: {el?.role}</Typography>
                {el?.role === "Super Admin" ? "" : <DeleteOutlineIcon onClick={() => handelClickDelteAdmin(el?._id)} sx={{ color: "#fff", cursor: "pointer" }} />}
              </Box>
            ))
          }
        </Box>

      </Box>
    </>
  )
}
