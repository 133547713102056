import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signUpUser, signInUser } from "../../store/UserSlice"
import "./style.css";
import { BlueBtn } from '../../components/Buttons';
import Clogo from "../../Assets/Images/vligtaPro2.png"
import { Notification } from '../../App';


export default function UserSignUP({ setResOTP, setNewUserId, setNewUser }) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [userInfo, setuserInfo] = useState({})
  const [email, setEmail] = useState()
  const [logPage, setLogPage] = useState(true)

  const handeluserInfo = (e) => {
    const { name, value } = e.target;
    setuserInfo(prev => ({
      ...prev, [name]: value
    }))
  }

  const handelSignUp = () => {
    dispatch(signUpUser(userInfo)).then((response) => {
      if (response.payload === "The user already exists with this email id!") {
        Notification("warning", response.payload + " Please sign In.")
        return;
      } else {
        setResOTP(response?.payload?.userData?.hasOTP)
        setNewUserId(response?.payload?.userData?.newUser?._id)
        setNewUser("new")
        navigate("/user/signUp/otp-verify")
      }
    })
  }


  const handelSignIn = () => {
    dispatch(signInUser({ email })).then((response) => {
      if (response.payload === "User not found!") {
        Notification("warning", response.payload + " User not found!")
        return;
      } else {
        setResOTP(response?.payload?.userData?.hasOTP)
        setNewUserId(response?.payload?.userData?.user?._id)
        setNewUser("old")
        navigate("/user/signUp/otp-verify")
      }
    })
  }


  return (
    <>
      <Box className="loginContainer">
        <Box className="loginInnerContainer">
          <Box className="companyLogo">
            <Link to={"/"}>
              <img src={Clogo} />
            </Link>
          </Box>
          {
            logPage ?
              <>
                <Typography className='loginHeader' variant='h3'>User SignIn</Typography>
                <Typography className='loginLabelText'>Welcome to  VLiGTA® Pro. We're excited to have you on board. </Typography>
                <TextField name="email" className='loginInput' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                <Typography className='loginLabelText'>By signing In, you agree to our Terms of Service and Privacy Policy.</Typography>
                <BlueBtn disabled={!email} buttonText="Sign In" onClick={handelSignIn} />
              </> :
              <>
                <Typography className='loginHeader' variant='h3'>User SignUp</Typography>
                <Typography className='loginLabelText'>Welcome to  VLiGTA® Pro. We're excited to have you on board. </Typography>
                <TextField name='name' className='loginInput' placeholder='Name' value={userInfo.name} onChange={handeluserInfo} />
                <TextField name="email" className='loginInput' placeholder='Email' value={userInfo.email} onChange={handeluserInfo} />
                <Typography className='loginLabelText'>By signing up, you agree to our Terms of Service and Privacy Policy.</Typography>
                <BlueBtn disabled={!userInfo.name || !userInfo.email} buttonText="Sign Up" onClick={handelSignUp} />
              </>
          }
          <Typography mt={2} className='loginLabelText AlreadyAc' onClick={() => setLogPage(!logPage)}>
            {logPage ? "Don't have a account ?" : "Already have an account ?"}
          </Typography>
        </Box>
      </Box>
    </>
  )
}
